import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditLogService } from '@riterz.io/riterz-core';
import { ModalService } from '@navus/ui/modal/modal.service';

@Component({
  selector: 'rtz-audit-log-modal',
  templateUrl: './audit-log-modal.component.html'
})
export class AuditLogModalComponent implements OnInit {
  @Input() objectType: string;
  @Input() objectId: string;

  auditLog: any[] = [];
  pagination: { page?: number, perPage: number, total?: number } = {
    perPage: 30
  };
  sort: string = 'created_at desc';
  searchTerm: string = '';

  loading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
    private auditLogService: AuditLogService,
  ) { }

  ngOnInit() {
    this.getAuditLog();
  }

  getAuditLog(append: boolean = false, showLoading: boolean = true) {
    if (append && this.pagination.total && this.auditLog.length >= this.pagination.total) {
      return;
    }
    this.pagination.page = append ? this.pagination.page + 1 : 1;
    const params: any = {
      page: this.pagination.page,
      per_page: this.pagination.perPage,
    };
    if (this.searchTerm !== '') {
      params.search_term = this.searchTerm;
    }
    if (this.sort && this.sort !== '') {
      params.order_by = this.sort.split(' ')[0];
      params.order_direction = this.sort.split(' ')[1];
    }

    this.auditLogService
      .getAuditLogs(this.objectType, this.objectId, params)
      .subscribe(
        (response: { data: any[], meta: any }) => {
          if (append) {
            this.auditLog = this.auditLog.concat(response.data);
          } else {
            this.auditLog = response.data;
            this.pagination.total = response.meta.total;
          }
          this.loading = false;
        },
        (error) => {
          this.modalService.error({ errors: error });
          this.loading = false;
        }
      )
  }

  close() {
    this.activeModal.dismiss();
  }

}
