import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable()
export class RecaptchaService {

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService
  ) { }

  getRecaptcha(action: string) {
    return this.recaptchaV3Service.execute(action);
  }

  verifyRecaptcha(token: string, action: string) {
    const params = {
      'g-recaptcha-response': token,
      'action': action
    };
    return this.apiService.post(`recaptcha`, params);
  }

}
