<div class="nv-config-layout sidebar-open"><!-- toggle class 'sidebar-open' with nv-config-sidebar -->
    <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/riterz-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <!-- SIDEBAR -->
        <div class="nv-config-sidebar on"><!-- toggle class 'on' -->
            <div class="nv-learning-pane">
                <header>
                    <div class="lp-close"><a href title="Close bar"></a></div>
                    <h2>Help &amp; Learning</h2>
                    <div class="sbwf-port">
                        <input formcontrolname="search_term" placeholder="Search..." type="text">
                        <span class="sbwf-outline"></span>
                    </div><!-- /sbwf-port -->
                </header>
                <div class="lp-body">
                    <div class="lp-body-content">
                        <h4>New to e-materials? Here’s what you need to know:</h4>
                        <h5>STEP 1: Setup Consent</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum libero velit, pharetra id setup consent here.</p>

                        <h5>STEP 2: Add files</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum libero velit, pharetra id convallis nec here.</p>

                        <h5>STEP 3: Process files to presentation materials</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum libero velit, pharetra id convallis nec here.</p>
                        <h5>Here is a quick video showing you those steps in action:</h5>
                        <div class="nv-video-port">
                            <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                            <video width="320" height="240" controls poster="../../assets/video-tutorial-poster.jpg">
                                <source src="../../assets/Currentzis.mp4" type="video/mp4">
                                <!--<source src="movie.ogg" type="video/ogg">-->
                                Your browser does not support the video tag.
                            </video>
                        </div><!-- /.nv-ls-video-port -->
                    </div><!-- /.lp-body-content -->
                </div><!-- /.lp-body -->
                <footer>
                    <a href class="nv-btn filled primary medium">Contact Us</a>
                </footer>
            </div><!-- /.nv-learning-pane -->
        </div><!-- /.nv-config-sidebar -->
        <div class="nv-config-control-bar">
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Events</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <h1>Events</h1>
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-phab">
                    <ul>
                        <li>
                            <a>Materials</a>
                        </li>
                        <li>
                            <a class="active">Consent Settings<strong class="tab-sidenote">Setup first</strong></a>
                        </li>
                    </ul>
                </nav><!-- /.nv-tabs-nav -->
            </div><!-- /.nv-line-tabs -->
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port">
            <section class="nv-section"> <!-- < screen 1 -->
                <div class="nv-port">
                    <div class="nv-row config-plates gap-24">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <h3 class="medium">Set up consent</h3>
                                <div class="nv-note-bar success">
                                    <p>Consent setup is done, but you can always <a href>edit your settings</a>.</p>
                                </div><!-- /.nv-note-bar -->
                                <div class="nv-switch-accordion-item">
                                    <header>
                                        <div class="sai-left">
                                            <h3>Publish speaker slides<span class="nv-info-spot inline"></span></h3>
                                        </div><!-- /.sai-left -->
                                        <div class="sai-right">
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div><!-- /tsq-checkbox-radio -->
                                        </div><!-- /.sai-right -->
                                    </header>
                                    <div class="nv-switch-accordion-body">
                                        <div class="nv-w-switch">
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div><!-- /tsq-checkbox-radio -->
                                            <p>Allow publishing on website</p>
                                        </div><!-- /.nv-w-switch -->

                                        <div class="nv-w-switch">
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div><!-- /tsq-checkbox-radio -->
                                            <p>Allow publishing on mobile app</p>
                                        </div><!-- /.nv-w-switch -->
                                    </div><!-- /.nv-switch-accordion-body -->
                                </div><!-- /.nv-switch-accordion-item -->

                                <div class="nv-switch-accordion-item">
                                    <header>
                                        <div class="sai-left">
                                            <h3>Use disclaimer<span class="nv-info-spot inline"></span></h3>
                                        </div><!-- /.sai-left -->
                                        <div class="sai-right">
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div><!-- /tsq-checkbox-radio -->
                                        </div><!-- /.sai-right -->
                                    </header>
                                    <div class="nv-switch-accordion-body">
                                        <div class="nv-form-item">
                                            <label>
                                                <textarea rows="5" cols="20" placeholder="Disclaimer">As a registered E-materials Service user of the Advances in Event Name, Date, City, State, you have been granted permission to access a copy of the presentation in the following pages for the purpose of scientific education. This presentation is copyrighted material and must not be copied, reproduced, transferred, distributed, leased, licensed, placed in a storage retrieval system, publicly performed or used in any way, except as specifically permitted in writing by presenter or, as allowed under the terms and conditions under which it was received or as permitted by applicable copyright law or rules of proper citation. Any unauthorized distribution or use of this presentation, a subset of it or graphic taken from the presentation may be a direct infringement of the presenter's rights.</textarea>
                                                <span class="label-title">Disclaimer</span>
                                            </label>
                                        </div><!-- /.nv-form-item -->
                                    </div><!-- /.nv-switch-accordion-body -->
                                </div><!-- /.nv-switch-accordion-item -->

                                <div class="nv-switch-accordion-item">
                                    <header>
                                        <div class="sai-left">
                                            <h3>Use watermark<span class="nv-info-spot inline"></span></h3>
                                        </div><!-- /.sai-left -->
                                        <div class="sai-right">
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div><!-- /tsq-checkbox-radio -->
                                        </div><!-- /.sai-right -->
                                    </header>
                                    <div class="nv-switch-accordion-body">
                                        <div class="nv-upload-block">
                                            <label class="ub-img-port">
                                                <input type="file" name="">
                                                <span class="nv-ub-image"></span><!-- /.nv-ub-image -->
                                            </label>
                                            <div class="ub-adjument">
                                                <p class="ub-control">
                                                    <span><a href>Upload new file</a></span>
                                                    <span><a href>Remove file</a></span>
                                                </p><!-- /.ub-control -->
                                                <div class="info-p small">
                                                    <p>Upload instructions</p>
                                                    <div class="nv-input-info-box">
                                                        <span></span>
                                                        <div class="nv-input-info-content"><div>Upload at least 400x150 pixels wide logo.<br><br>Accepted File Types: JPG or PNG</div></div>
                                                    </div>
                                                </div><!-- /.info-p -->
                                            </div><!-- /.ub-adjument -->
                                        </div><!-- /.nv-upload-block -->
                                    </div><!-- /.nv-switch-accordion-body -->
                                </div><!-- /.nv-switch-accordion-item -->

                                <div class="nv-switch-accordion-item">
                                    <header>
                                        <div class="sai-left">
                                            <h3>Allow Webcasts to be…<span class="nv-info-spot inline"></span></h3>
                                        </div><!-- /.sai-left -->
                                        <div class="sai-right">
                                            <div class="tsq-checkbox-radio check-type-slide">
                                                <label title="activate module">
                                                    <input type="checkbox" name="r2">
                                                    <span class="tsq-check-visual"></span>
                                                </label>
                                            </div><!-- /tsq-checkbox-radio -->
                                        </div><!-- /.sai-right -->
                                    </header>
                                </div><!-- /.nv-switch-accordion-item -->

                                <div class="nv-row gap-24 space-between large-spaced-top">
                                    <div class="nv-column desk-auto v-middle">
                                        <div class="nv-column-content">
                                            <p class="spaceless"><strong>Don't need to set up consent? — <a href>Skip this step and mark it as done</a></strong></p>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <a href class="nv-btn filled primary small">Finish</a>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                            </div><!-- /nv-column-content -->
                        </div><!-- /nv-column -->
                    </div>
                </div>
            </section>
            <section class="nv-section" style="display: none;"> <!-- < screen 4 -->
                <div class="nv-port">
                    <div class="nv-row config-plates gap-24">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-data-room-content-port">
                                    <div class="nv-data-room-content">
                                        <div class="drc-item upload single"><!-- additionl class for large upload -->
                                            <div class="drc-upload">
                                                <div class="drc-upload-port">
                                                    <h4>Drop files here</h4>
                                                    <span>or</span>
                                                    <a href class="nv-btn filled small anchor">Choose a file</a>
                                                </div><!-- /.drc-upload-port -->
                                            </div><!-- /.drc-upload -->
                                        </div><!-- /.drc-item -->
                                    </div><!-- /nv-data-room-content -->
                                </div><!-- /nv-data-room-content-port -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                    <div class="nv-row config-plates gap-24 spaced-top">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-tip">
                                    <p>Tip: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum libero velit, pharetra id setup consent <a href title="Set up Navus voting"><strong>find out more</strong></a>.</p>
                                </div><!-- /.nv-tip -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div><!-- /nv-port -->
            </section>
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
</div><!-- /.nv-config-layout -->
