import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { LoginComponent } from './modals/login/login.component';
import { RegistrationComponent } from './modals/registration/registration.component';
import { AnalyticsService, User, UserService } from 'riterz-core';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppService } from './services/app.service';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { Popover } from 'primeng/popover';
import { VerifiedEmailComponent } from './modals/verified-email/verified-email.component';
import { WelcomeComponent } from './modals/welcome/welcome.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule,
    RouterOutlet,
    ButtonModule,
    AvatarModule,
    Popover,
    ToastModule,
    ConfirmDialogModule,
  ],
  providers: [
    DialogService, 
    AnalyticsService,
    MessageService,
    ConfirmationService
  ],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Riterz';

  currentUser: User|null = null;

  subscriptions: Subscription[] = [];

  isSuperAdmin: boolean = false;
  loadingUser: boolean = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private appService: AppService,
    private dialogService: DialogService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();

    this.analyticsService.init();

    // const theme = localStorage.getItem('settings.theme') || 'theme-light';
    // this.appService.switchTheme(theme);

    this.appService
      .toast
      .subscribe(
        (message: any) => {
          if (message) {
            this.messageService.add(message);
          }
        }
      );
      
    this.appService
      .dialog
      .subscribe(
        (dialog: any) => {
          if (dialog) {
            this.confirmationService.confirm(dialog);
          }
        }
      );

    const queryParamsSubscription = this.route.queryParams
      .subscribe(
        (params) => {
          if (params['refresh_token'] && params['access_token']) {
            // console.log(params);
            this.setRiterzTokens(params['refresh_token'], params['access_token']);
            this.router.navigate([], {
              queryParams: {
                'refresh_token': null,
                'access_token': null,
              },
              queryParamsHandling: 'merge'
            })
          }
          if (params['verify_email']) {
            this.verifyEmail(params['verify_email']);
            this.router.navigate([], {
              queryParams: {
                'verify_email': null,
              },
              queryParamsHandling: 'merge'
            })
          }
          if (params['login_code']) {
            this.loginWithCode(params['login_code']);
            this.router.navigate([], {
              queryParams: {
                'login_code': null,
              },
              queryParamsHandling: 'merge'
            })
          }
        }
      );
    this.subscriptions.push(queryParamsSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCurrentUser() {
    const params = {
      include: 'organizations,roles'
    }

    this.loadingUser = true;
    this.userService
      .getCurrentUser(params)
      .subscribe({
        next: (response) => {
          this.currentUser = response.data;

          if (this.currentUser?.roles) {
            this.isSuperAdmin = !!this.currentUser.roles.find((role) => role.name === 'super-admin');
          } else {
            this.isSuperAdmin = false;
          }

          this.loadingUser = false;
        },
        error: (error) => {
          this.loadingUser = false;
        }
      });
  }

  loginWithCode(code: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.userService
        .loginWithCode(code)
        .subscribe({
          next: (response) => {
            this.getCurrentUser();
          },
          error: (error) => {
            this.appService.showToast({ 
              severity: 'error', 
              summary: `Error`, 
              detail: error.message 
            });
          }
        });
    }
  }

  login() {
    const modalRef = this.dialogService.open(LoginComponent, {
      styleClass: 'rt-auth-modal',
      width: '70rem',
    });

    modalRef.onClose
      .subscribe((response) => {
        if (response?.hasChanges) {
          this.getCurrentUser();
        }   
        if (response?.openRegistration) {
          this.registration();
        }  
      });
  }

  registration() {
    const modalRef = this.dialogService.open(RegistrationComponent, {
      styleClass: 'rt-auth-modal',
      width: '70rem',
    });

    modalRef.onClose
      .subscribe((response) => {
        if (response?.hasChanges) {
          this.getCurrentUser();
        }   
        if (response?.openLogin) {
          this.login();
        }   
      });
  }

  welcome() {
    if (isPlatformBrowser(this.platformId)) {
      const modalRef = this.dialogService.open(WelcomeComponent, {
        styleClass: 'rt-welcome',
        width: '36rem',
        data: {
          verified: true,
          email: 'email@example',
          name: 'John Doe'
        }
      });
    }
  }
  

  logout() {
    this.userService
      .logout()
      .subscribe(() => {
        this.currentUser = null;
        this.router.navigate(['/']);
      });
  }

  setRiterzTokens(refresh_token: string, access_token: string) {
    this.userService.setRiterzTokens(refresh_token, access_token);
    this.getCurrentUser();
  }

  verifyEmail(data: string) {
    if (isPlatformBrowser(this.platformId)) {
      const params = decodeURIComponent(data).split('|');
      const id = params[0];
      const token = params[1];
      this.userService
        .verifyEmail(id, token)
        .subscribe({ 
          next: (response) => {
            this.dialogService.open(VerifiedEmailComponent, {
              header: 'Verify Organisation',
              width: '40rem'
            });
            this.getCurrentUser();
          },
          error: (error) => {
            this.appService.showToast({ 
              severity: 'error', 
              summary: `Error`, 
              detail: error.message 
            });
          }
        });
    }
  }

}
