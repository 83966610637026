<div class="rtz-registration-wrap">
    <div class="rtz-registrtaion-panel">
        <h1>Register for Riterz for free</h1>
        <p>You have been invited to join Organisation in Riterz platform. Please use an option below to continue.</p>
        
        <!-- LOG IN options -->
        <nav class="rtz-registration-options" style="display: none;">
            <ul>
                <li>
                    <a href class="ro-google" title="Continue with Google">Continue with Google</a>
                </li>
                <li>
                    <a href class="ro-fb" title="Continue with Facebook">Continue with Facebook</a>
                </li>
                <li>
                    <a href class="ro-ms" title="Continue with Microsoft">Continue with Microsoft</a>
                </li>
                <li>
                    <a href class="ro-email" title="Continue with Email">Continue with Email</a>
                </li>
            </ul>
        </nav>

        <!-- LOG IN form -->
        <div class="rtz-reg-login">
            <a href title="go back" class="rtz-reg-bck"></a>
            <form action="" class="nv-row gap-16 propagate">
                <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item nv-mandatory">
                            <label>
                                <input type="text" placeholder="First name">
                                <span class="label-title">First name</span>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item nv-mandatory">
                            <label>
                                <input type="text" placeholder="Lastname">
                                <span class="label-title">Lastname</span>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item">
                            <label>
                                <label>
                                    <input type="text" placeholder="Institution / Company / Society">
                                    <span class="label-title">Institution / Company / Society</span>
                                </label>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item nv-select-item nv-mandatory">
                            <label>
                                <select>
                                    <option>Country</option>
                                </select>
                                <span class="label-title">Country</span>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <br>
                        <div class="nv-checkbox-item nv-checked">
                            <label>
                                <span class="nci-check-port">
                                    <input type="checkbox" name="null" class="nv-touched">
                                    <span class="nci-check-visual"></span>
                                </span>
                                <span class="nci-value">I have read and accepted the <a href>terms and conditions</a>.</span>
                            </label>
                        </div>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-auto flow-center">
                    <div class="nv-column-content">
                        <br>
                       <a class="nv-btn medium accent1" title="continue to registration" disabled="">Continue</a>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </form>
            <p class="reg-footnote">This site is protected by reCAPTCHA and the Google’s <a href title="Privacy Policy">Privacy Policy</a> and <a href title="Terms of Service">Terms of Service apply</a>.</p>
        </div><!-- /.rtz-reg-login -->

        <!-- REGISTER form -->
        <div class="rtz-reg-login" style="display: none;">
            <form action="" class="nv-row gap-16 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item nv-mandatory">
                            <label>
                                <input type="email" placeholder="Email">
                                <span class="label-title">Email</span>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item nv-mandatory">
                            <label>
                                <input type="text" placeholder="Password">
                                <span class="label-title">Password</span>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-6 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-form-item nv-mandatory">
                            <label>
                                <label>
                                    <input type="text" placeholder="Confirm password">
                                    <span class="label-title">Confirm password</span>
                                </label>
                            </label>
                        </div><!-- /nv-form-item -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                       <p class="rtz-from-req">Use 8 or more characters with a mix of letters, numbers & symbols</p>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-auto flow-center">
                    <div class="nv-column-content">
                        <a class="nv-btn medium accent1" title="continue to registration" disabled="">Register</a>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <p>Or log in with your existing Riterz account.</p>
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </form>
            <p class="reg-footnote">This site is protected by reCAPTCHA and the Google’s <a href title="Privacy Policy">Privacy Policy</a> and <a href title="Terms of Service">Terms of Service apply</a>.</p>
        </div><!-- /.rtz-reg-login -->

    </div><!-- /.rtz-registrtaion-panel -->

    <!-- MESSAGE -->
    <!-- exclude registrtaion panel when showing message-->
    <div class="rtz-reg-msg" style="display: none;">
        <h1>Invalid token!</h1>
        <p>Please write to Riterz’s support team at<br><a href title="Riterz Support">support@riterz.com</a></p>
    </div><!-- /.rtz-reg-msg -->

    <p class="rtz-foo-dsc">&copy; All rights reserved Riterz AG 2023</p>
</div><!-- /.rtz-registration-wrap -->
