import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rtz-main',
  templateUrl: 'main.component.html'
})

export class MainComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}