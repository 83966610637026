// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  api_url: 'https://staging.e-materials.com/api/',
  file_root: 'https://service.e-materials.com/',
  fasung_url: 'https://fasung.navus-hub.com',
  meeting_url: 'https://conferencing.navus.io/',
  hmr: false,
  versionCheckURL: null,
  muxEnvKey: 'sr0mn382n4o3u94j4abvmlobp',
  trackingEnabled: true,
  trackingLog: true,
  firebaseConfig: {
    apiKey: "AIzaSyCQtPx7f2Onh0vsOhosyuNqurBPbSzvhXQ",
    authDomain: "thematic-land-829.firebaseapp.com",
    projectId: "thematic-land-829",
    storageBucket: "thematic-land-829.appspot.com",
    messagingSenderId: "1069668890652",
    appId: "1:1069668890652:web:9379edb35b531d3ee0c7d9",
    measurementId: "G-QG99VZLEBT"
  },

  // Staging
  mix_pusher_app_key: "531a844d-f1b7-45a3-8f34-346c0c2e053f",
  mix_pusher_host: "navus-0468e803-daa0-45a8-be57-bd5ee10724c3.navus-workers.workers.dev",
  mix_pusher_port: 443,

  // Riterz
  riterz_api_url: 'https://dev.api.riterz.io/api/',
  riterz_client_id: '9883e0d1-7184-4fc0-af2f-f6f777af464f',
  riterz_access_token_name: 'riterz_access_token',
  riterz_refresh_token_name: 'riterz_refresh_token',

  riterz_terms_url: 'https://riterz.com/terms-conditions/',

  navus_api_url: 'https://staging.e-materials.com/api/',
  navus_url: 'https://config.navus-hub.com/',
  navus_access_token_name: 'navus_access_token',

  zitcha_iframe_url: 'https://dev.media.riterz.io/',
  zitcha_access_token_name: 'zitcha_access_token',

  recaptcha_site_key: '6LfAW9gkAAAAANtDnfSBu4w-0uZjuFCgVEsRhGte',
};
