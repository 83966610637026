<h4>Not Goog?</h4>


<div class="nv-app-preview-model">
    <div class="apm-port">
        <div class="apm-top-logo">
            <img src="https://service.e-materials.com/storage/resources/timm/log/logo.png" alt="company name">
        </div><!-- /.apm-top-logo -->
        <div class="apm-content">
            <div class="apm-section edit-on">
                <a href class="nv-section-add-top" title="Add section"></a>
                <a href class="nv-section-add-bottom" title="Add section"></a>
                <div class="nv-section-edit-tool-box">
                    <a href class="edit" title="Edit section"></a>
                    <a href class="delete" title="Delete section"></a>
                </div><!-- /.nv-section-edit-tool-box -->
                <div class="nv-section-sort-tool-box">
                    <a href class="move-up" title="Move down"></a>
                    <a href class="move-down" title="Move up"></a>
                </div><!-- /.nv-section-sort-tool-box -->
                <h3>INTERACTIVE</h3>
                <div class="amp-menu">
                    <ul>
                        <li>
                            <strong class="as-program" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>Program</span>
                        </li>
                        <li>
                            <strong class="as-speakers" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>Speakers</span>
                        </li>
                        <li>
                            <strong class="as-eposters" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>E-Posters</span>
                        </li>
                        <li>
                            <strong class="as-voting" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>Voting</span>
                        </li>
                        <li>
                            <strong class="as-timeline" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>Timeline</span>
                        </li>
                        <li>
                            <strong class="as-timeline" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>Something</span>
                        </li>
                        <li>
                            <strong class="as-timeline" data-icon-pl="&#xe007;" style="background-color: #ccc;"></strong>
                            <span>Lorem ipsum</span>
                        </li>
                    </ul>
                </div><!-- /.amp-menu -->
            </div><!-- /.apm-section -->
        </div><!-- /.apm-content -->
        <div class="apm-footer">

        </div><!-- /.apm-footer -->
    </div><!-- /.apm-port -->
</div><!-- /.nv-app-preview-model -->
