<div class="rtz-config-frame">
    <header class="rtz-cf-header" [class.show-navigation]="showNavigation">
        <div class="rtz-cf-logo-port">
            <figure>
                <a href title="Riterz configurator"><img src="../../assets/riterz-logo.svg" alt="Riterz.com"></a>
            </figure>
        </div><!-- /.rtz-cf-logo-port -->
        <nav class="rtz-cf-header-menu">
            <ul>
                <li><a href title="Dashboard" class="active">Dashboard</a></li>
                <li><a href title="Files">Files</a></li>
                <li><a href title="News">News</a></li>
                <li><a title="Advertising">Advertising</a>
                    <span class="sub-toggler" (click)="toggleSubNavigation('sub1')" [class.on]="showSubNavigation === 'sub1'"></span>
                    <ul>
                        <li><a href title="title">Sub item 1</a></li>
                        <li><a href title="title">Sub item 2</a></li>
                        <li><a href title="title">Sub item 3</a></li>
                        <li><a href title="title">Sub item 4</a></li>
                    </ul>
                </li>
                <li><a href title="Sponsors">Sponsors</a></li>
                <li><a href title="Events">Events</a>
                    <span class="sub-toggler" (click)="toggleSubNavigation('sub2')" [class.on]="showSubNavigation === 'sub2'"></span>
                    <ul>
                        <li>
                            <a href title="Paradigm Shifting New Horizonts">
                                <img src="../../assets/avatar-dummy.jpg" alt="Paradigm image">
                                <span>Paradigm Shifting New Horizonts</span>
                            </a>
                        </li>
                        <li>
                            <a href title="AB Clone 2024">
                                <img src="../../assets/logo-event-esicm.png" alt="AB Clone image">
                                <span>AB Clone 2024</span>
                            </a>
                        </li>
                        <li class="alt"><a href title="View all events">View all events</a></li>
                    </ul>
                </li>
                <li><a href title="Websites">Websites</a></li>
            </ul>
        </nav><!-- /.rtz-cf-header-menu -->
        <div class="rtz-menu-toggler" (click)="showNavigation = !showNavigation"></div>
        <!-- /.rtz-menu-trigger -->
        <div class="rtz-cf-user-settings">
            <a href class="rt-status-pill" title="Free version">free</a>
            <div class="rtz-ui-settings-box org"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure class="rt-halo"><img src="../../assets/riterz-textless-logo.svg" alt="Company Name"></figure>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                        <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                        <li><a href title="Billing" class="rtz-ico-billing">Billing</a></li>
                        <li><a href title="Step by step guide" class="rtz-ico-step-by-step">Step by step guide</a></li>
                        <li><a href title="Organisation Settings" class="rtz-ico-organisation">Organisation Settings</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
            <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure class="rt-halo"><img src="../../assets/avatar-placeholder.png" alt="User Name"></figure>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Profile" class="rtz-ico-profile">Profile</a></li>
                        <li><a href title="Privacy" class="rtz-ico-privacy">Privacy</a></li>
                        <li><a href title="Legal" class="rtz-ico-legal">Legal</a></li>
                        <li><a href title="Sign Out" class="rtz-ico-signout">Sign Out</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
        </div><!-- /.rtz-cf-user-settings -->
    </header><!-- /.rtz-cf-header -->
    <div class="rtz-config-body">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <header class="rtz-config-top-dash">
                    <h1>Home</h1>
                </header><!-- /rtz-config-top-dash -->

                <div class="nv-msg-block info"><!-- < warning / info -->
                    <span class="nv-msg-close"></span>
                    <h5>Welcome to Riterz!</h5>
                    <p>Dive into the demo data to see how Riterz can help you unlock new revenue strams.<br>Remember, you have full control over your experience. If you wish to start with a clean slate, you can easily turn off the demo data feature at any time.</p>
                </div><!-- /.nv-msg-block -->

<!--                <h3>News</h3>-->
<!--                <figure class="rtz-block-banner">-->
<!--                    <span class="rtz-bb-close"></span>-->
<!--                    <a href title="Banner dscr"><img src="https://service.e-materials.com/storage/resources/navus_ag/das/dasboard_banner.jpg" alt="Banner dscr"></a>-->
<!--                </figure>&lt;!&ndash; /.rtz-block-banner &ndash;&gt;-->

                <div class="rtz-card rtz-dashboard-block">
                    <header class="rt-dash-header">
                        <h2>Your Sponsor's most recent activity</h2>
                        <div class="rtz-dash-header-tools">
                            <div class="rt-btn-box gap-24">
                                <a href class="rt-btn normal outline">Delete demo data</a>
                                <a href class="rt-btn normal primary">Invite sponsor</a>
                            </div><!-- /.rtz-btn-box -->
                        </div><!-- /.rt-dash-header-tools -->
                    </header><!-- /.rtz-dash-header -->
                    <div class="rt-dash-cards-box">
                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="../../assets/logo-event-ctd.png" alt="Company name"></figure>
                                    <h3>Coca-Cola</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill demo">Demo</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <div class="rt-split">
                                    <p><a href title="Go to sponsors’s dashboard">Go to sponsors’s dashboard</a></p>
                                    <p><a href title="Preview data">Preview data</a></p>
                                </div>
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <span class="rt-card-initials">DS</span>
                                    <h3>Demo sponsor</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                            </header>
                            <div class="rt-card-invitation">
                                <p><span>Invitation sent to</span><a href="mailto:nickcave@ttemporium.com" title="Nick Cave">nickcave@ttemporium.com</a></p>
                            </div><!-- /.rt-card-invitation -->
                            <div class="rt-dashboard-card-footer">
                                <div class="rt-dc-foo-options">
                                    <p><a (click)="toggleSubNavigation('sub1')" [class.on]="showSubNavigation === 'sub1'" title="Edit Invitation">Edit</a></p>
                                    <ul class="rt-tt-options">
                                        <li><a href title="Resend">Resend</a></li>
                                        <li><a href title="Cancel" class="del">Cancel</a></li>
                                    </ul><!-- /.rt-tt-options -->
                                </div><!-- /.rt-dc-foo-options -->

                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card" style="background-image: url('https://service.e-materials.com/storage/resources/navus_ag/bck/bckg_invite_sponsor.jpg');">
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-start.svg" alt="Invite sponsor"></figure>
                                <p><a href title="Invite sponsor">Invite sponsor</a></p>
                            </div><!-- /.rt-dc-init -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <span class="rt-card-initials">IC</span>
                                    <h3>IT Crowd</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill new">New</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-new.svg" alt="New"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p>This sponsor is new, has no data to show yet.</p>
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <span class="rt-card-initials">IC</span>
                                    <h3>Order 2</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                            </header>
                            <div class="rt-card-invitation">
                                <p><span>Pending order</span><a>IT Crowd</a></p>
                            </div><!-- /.rt-card-invitation -->
                            <div class="rt-dashboard-card-footer">
                                <div class="rt-dc-foo-options">
                                    <p><a (click)="toggleSubNavigation('sub2')" [class.on]="showSubNavigation === 'sub2'" title="Edit Invitation">Edit</a></p>
                                    <ul class="rt-tt-options">
                                        <li><a href title="Resend">Resend</a></li>
                                        <li><a href title="Cancel" class="del">Cancel</a></li>
                                    </ul><!-- /.rt-tt-options -->
                                </div><!-- /.rt-dc-foo-options -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                    <a href class="tw" title="Twitter"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <span class="rt-card-initials">IC</span>
                                    <h3>IT Crowd</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill new">New</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-cart.svg" alt="Order"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Thompson's Tech Emporium</strong>
                                    <span>13 Jan 2024 - 21 Jan 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>EFR 2021 Reception. What's new in Colorectal Cancer Treatment?</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill live">Live</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Registrations</h4>
                                    <div class="rt-dmi-value">
                                        <p>111</p>
                                        <span class="rt-metric-icon registrations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon presentations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">-36%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Registrations</h4>
                                    <div class="rt-dmi-value">
                                        <p>111</p>
                                        <span class="rt-metric-icon registrations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon presentations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">-36%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Thompson's Tech Emporium</strong>
                                    <span>13 Jan 2024 - 21 Jan 2024</span>
                                </p><!-- /rt-card-name-date -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Paradigm Shifting News in Colorectal Cancer - Update 2024</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill upcoming">upcoming</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-upcoming.svg" alt="Upcoming"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <span class="rt-card-time-remaining"><strong>13</strong>Days to go</span>
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Event</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill past">Past</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Order 2</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill ended">Ended</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card" style="background-image: url('https://service.e-materials.com/storage/resources/navus_ag/car/card_bcg_girl.jpg');">
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-start.svg" alt="Create campaign"></figure>
                                <p><a href title="Invite sponsor">Create campaign</a></p>
                            </div><!-- /.rt-dc-init -->
                        </div><!-- /.rt-dashboard-card -->
                    </div><!-- /.rt-dash-cards-box -->
                    <p class="rt-dash-cards-all"><a href title="See list of all sponsors">See list of all sponsors</a></p>
                </div><!-- /.rt-card -->

                <div class="rtz-card rtz-dashboard-block">
                    <header class="rt-dash-header">
                        <h2>Your Sponsor's most recent activity</h2>
                        <div class="rtz-dash-header-tools">
                            <div class="rt-btn-box gap-24">
                                <a href class="rt-btn normal outline" title="Delete demo data">Delete demo data</a>
                                <a href class="rt-btn normal primary" title="Invite sponsor">Invite sponsor</a>
                            </div><!-- /.rtz-btn-box -->
                        </div><!-- /.rt-dash-header-tools -->
                    </header><!-- /.rtz-dash-header -->
                    <div class="rt-dash-cards-box-slide">
                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <span class="rt-card-initials">IC</span>
                                    <h3>IT Crowd</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill new">New</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-cart.svg" alt="Order"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Thompson's Tech Emporium</strong>
                                    <span>13 Jan 2024 - 21 Jan 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>EFR 2021 Reception. What's new in Colorectal Cancer Treatment?</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill live">Live</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Registrations</h4>
                                    <div class="rt-dmi-value">
                                        <p>111</p>
                                        <span class="rt-metric-icon registrations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon presentations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">-36%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Registrations</h4>
                                    <div class="rt-dmi-value">
                                        <p>111</p>
                                        <span class="rt-metric-icon registrations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon presentations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">-36%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Thompson's Tech Emporium</strong>
                                    <span>13 Jan 2024 - 21 Jan 2024</span>
                                </p><!-- /rt-card-name-date -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Paradigm Shifting News in Colorectal Cancer - Update 2024</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill upcoming">upcoming</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-upcoming.svg" alt="Upcoming"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <span class="rt-card-time-remaining"><strong>13</strong>Days to go</span>
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Event</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill past">Past</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Order 2</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill ended">Ended</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->
                    </div><!-- /.rt-dash-cards-box-slide -->
                    <p class="rt-dash-cards-all"><a href title="See list of all sponsors">See list of all sponsors</a></p>
                </div><!-- /.rt-card -->


                <div class="rtz-card rtz-dashboard-block">
                    <header class="rt-dash-header">
                        <h2>Your Sponsor's most recent activity</h2>
                        <div class="rtz-dash-header-tools">
                            <div class="rt-btn-box gap-24">
                                <a href class="rt-btn normal primary" title="Add event">Add event</a>
                            </div><!-- /.rtz-btn-box -->
                        </div><!-- /.rt-dash-header-tools -->
                    </header><!-- /.rtz-dash-header -->
                    <div class="rtz-organizations-search-n-sort">
                        <div class="rtz-organizations-search-port">
                            <label class="rtz-organizations-sp-input rtz-mellow">
                                <input type="search" name placeholder="Search">
                            </label><!-- /.rtz-organizations-sp-input -->
                            <div class="rtz-organizations-sp-filter">
                                <a class="filter" href title="Filters">Filters</a>
                                <a href title="Clear">Clear</a>
                            </div><!-- /.rtz-organizations-sp-filter -->
                        </div><!-- /.rtz-organizations-search-port -->
                        <div class="rtz-organizations-search-sort rtz-mellow">
                            <select name="sortex">
                                <option>Sort by</option>
                                <option>Date</option>
                                <option>Popularity</option>
                                <option>Alphabet</option>
                            </select>
                        </div><!-- /.rtz-organizations-search-sort -->
                        <div class="rtz-organizations-search-filters">
                            <p>
                                <span>Cat 1</span>
                                <a href title="delete"></a>
                            </p>
                            <p>
                                <span>Dolor sit</span>
                                <a href title="delete"></a>
                            </p>
                            <p>
                                <span>Extremely loooong filter set right here</span>
                                <a href title="delete"></a>
                            </p>
                        </div><!-- /.rtz-organizations-search-filters -->
                    </div><!-- /.rtz-organizations-search-n-sort -->
                    <div class="rt-dash-cards-box">
                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <span class="rt-card-initials">IC</span>
                                    <h3>IT Crowd</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill new">New</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-cart.svg" alt="Order"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Thompson's Tech Emporium</strong>
                                    <span>13 Jan 2024 - 21 Jan 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>EFR 2021 Reception. What's new in Colorectal Cancer Treatment?</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill live">Live</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Registrations</h4>
                                    <div class="rt-dmi-value">
                                        <p>111</p>
                                        <span class="rt-metric-icon registrations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon presentations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">-36%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Registrations</h4>
                                    <div class="rt-dmi-value">
                                        <p>111</p>
                                        <span class="rt-metric-icon registrations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon presentations"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">-36%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Thompson's Tech Emporium</strong>
                                    <span>13 Jan 2024 - 21 Jan 2024</span>
                                </p><!-- /rt-card-name-date -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Paradigm Shifting News in Colorectal Cancer - Update 2024</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill upcoming">upcoming</p>
                            </header>
                            <div class="rt-dc-init">
                                <figure><img src="../assets/placeholder-upcoming.svg" alt="Upcoming"></figure>
                            </div><!-- /.rt-dc-init -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <span class="rt-card-time-remaining"><strong>13</strong>Days to go</span>
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Event</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill past">Past</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->

                        <div class="rt-dashboard-card">
                            <header>
                                <div class="rt-dashboard-card-face">
                                    <figure><img src="https://picsum.photos/200" alt="Company name"></figure>
                                    <h3>Order 2</h3>
                                </div><!-- /.rt-dashboard-card-face -->
                                <p class="rt-state-pill ended">Ended</p>
                            </header>
                            <div class="rt-dashboard-metrics-pane">
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Clicks</h4>
                                    <div class="rt-dmi-value">
                                        <p>448</p>
                                        <span class="rt-metric-icon clicks"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="fall">-18%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                                <div class="rt-dashboard-metric-item">
                                    <h4>Impressions</h4>
                                    <div class="rt-dmi-value">
                                        <p>789</p>
                                        <span class="rt-metric-icon impressions"></span>
                                    </div><!-- /.rt-dmi-value -->
                                    <p class="rise">+43%</p>
                                </div><!-- /.rt-dashboard-metric-item -->
                            </div><!-- /.rt-dashboard-metrics-pane -->
                            <div class="rt-dashboard-card-footer">
                                <p class="rt-card-name-date">
                                    <strong>Conference</strong>
                                    <span>31 Jan 2024 - 12 Feb 2024</span>
                                </p><!-- /rt-card-name-date -->
                                <div class="rt-social-set">
                                    <a href class="li" title="LinkedIn"></a>
                                    <a href class="fb" title="FaceBook"></a>
                                </div><!-- /.rt-social-set -->
                            </div><!-- /.rt-dashboard-card-footer -->
                        </div><!-- /.rt-dashboard-card -->
                    </div><!-- /.rt-dash-cards-box -->
                    <p class="rt-dash-cards-all"><a href title="Go to Events page">Go to Events page</a></p>
                </div><!-- /.rt-card -->


                <div class="rtz-tutor-cards">
                    <div class="rtz-tutor-item rtz-card">
                        <header>
                            <h2>Riterz for your Sponsors</h2>
                            <p>Your sponsors are offered the opportunity to position their brand under the trusted umbrella of your organisation.</p>
                            <p><a href title="See more ads examples!">See more ads examples!</a></p>
                        </header>
                        <div class="rtz-ti-video">

                        </div><!-- /.rtz-ti-video -->
                    </div><!-- /.rtz-tutor-item -->

                    <div class="rtz-tutor-item rtz-card">
                        <header>
                            <h2>Measure ROI and analyse the impact</h2>
                            <p>Riterz consolidates all reporting under one centralised platform enabling you to conveniently track your sponsor's campaign.</p>
                            <p><a href title="See examples of data tracking!">See examples of data tracking!</a></p>
                        </header>
                        <div class="rtz-ti-video">

                        </div><!-- /.rtz-ti-video -->
                    </div><!-- /.rtz-tutor-item -->

                    <div class="rtz-tutor-item rtz-card">
                        <header>
                            <h2>Your database secure, and yours. Always.</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos facilis nobis temporibus!</p>
                            <p><a href title="See more for best practices!">See more for best practices!</a></p>
                        </header>
                        <div class="rtz-ti-video">

                        </div><!-- /.rtz-ti-video -->
                    </div><!-- /.rtz-tutor-item -->
                </div><!-- /.rtz-tutor-cards -->


                <div class="rtz-txt-cards">
                    <div class="rtz-txt-card rtz-card">
                        <h4>Need a guidance through our platform?</h4>
                        <p>Discover the power of Riterz with our easy to follow tutorial. Whether you're a beginner or an experienced user, this step-by-step guide will walk you through every feature and functionality, helping you harness the full potential of our software.</p>
                        <p class="rtz-tc-more"><a href><strong>Check our step-by-step guide</strong></a></p>
                    </div><!-- /.rtz-txt-card -->
                    <div class="rtz-txt-card rtz-card">
                        <h4>Read our blog on everything Events and Organisational revenue</h4>
                        <p>Welcome to Riterz blog, your go-to resource for the latest insights, trends, and best practices in the world of event organising and sponsorship management. Stay informed with our expert articles, tips, and industry updates.</p>
                        <p class="rtz-tc-more"><a href><strong>Go to Riterz blog</strong></a></p>
                    </div><!-- /.rtz-txt-card -->
                    <div class="rtz-txt-card rtz-card">
                        <h4>Read our blog on everything Events and Organisational revenue</h4>
                        <p>Welcome to Riterz blog, your go-to resource for the latest insights, trends, and best practices in the world of event organising and sponsorship management. Stay informed with our expert articles, tips, and industry updates.</p>
                        <p class="rtz-tc-more"><a href><strong>Go to Riterz blog</strong></a></p>
                    </div><!-- /.rtz-txt-card -->
                    <div class="rtz-txt-card rtz-card">
                        <h4>Read our blog on everything Events and Organisational revenue</h4>
                        <p>Welcome to Riterz blog, your go-to resource for the latest insights, trends, and best practices in the world of event organising and sponsorship management. Stay informed with our expert articles, tips, and industry updates.</p>
                        <p class="rtz-tc-more"><a href><strong>Go to Riterz blog</strong></a></p>
                    </div><!-- /.rtz-txt-card -->
                </div><!-- /.rtz-txt-cards -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->
</div><!-- /.rtz-config-frame -->
