import { Component } from '@angular/core';

@Component({
    selector: 'app-wizard-setup-revision-page',
    templateUrl: './app-wizard-setup-revision.component.html'
})
export class AppWizardSetupRevisionComponent {

    constructor() { }

}
