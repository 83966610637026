<div class="rtz-ui-demo-wrap">
    <aside>
        <ul>
            <li><a href="ui-docs/docs-colors">Color Scheme</a></li>
            <li><a href="ui-docs/docs-typography">Typography</a></li>
            <li><a href="ui-docs/docs-icons">Icons</a></li>
            <li><a href>Headers (Content)</a></li>
            <li><a class="active" href="ui-docs/docs-buttons">Buttons</a></li>
            <li><a href>Toasts</a></li>
        </ul>
    </aside>
    <main>
        <div class="nv-section">
            <div class="nv-port">
                <div class="nv-row gap-24 large-spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <h2>Buttons</h2>
                            <div class="rt-buttons-box-demo">
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn large primary">Large Primary</button>
                                    <button type="button" class="rt-btn normal primary">Normal Primary</button>
                                    <button type="button" class="rt-btn small primary">Small Primary</button>
                                    <button type="button" class="rt-btn tiny primary">Tiny Primary</button>
                                    <button type="button" class="rt-btn normal primary" disabled>Primary Disabled</button>
                                </div><!-- /.rt-buttons-column -->
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn large secondary">Large Secondary</button>
                                    <button type="button" class="rt-btn normal secondary">Normal Secondary</button>
                                    <button type="button" class="rt-btn small secondary">Small Secondary</button>
                                    <button type="button" class="rt-btn tiny secondary">Tiny Secondary</button>
                                </div><!-- /.rt-buttons-column -->
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn large outline">Large Outline</button>
                                    <button type="button" class="rt-btn normal outline">Normal Outline</button>
                                    <button type="button" class="rt-btn small outline">Small Outline</button>
                                    <button type="button" class="rt-btn tiny outline">Tiny Outline</button>
                                    <button type="button" class="rt-btn normal outline" disabled>Outline Disabled</button>
                                </div><!-- /.rt-buttons-column -->
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn large error">Large Error</button>
                                    <button type="button" class="rt-btn normal error">Normal Error</button>
                                    <button type="button" class="rt-btn small error">Small Error</button>
                                    <button type="button" class="rt-btn tiny error">Tiny Error</button>
                                </div><!-- /.rt-buttons-column -->
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn large success">Large Success</button>
                                    <button type="button" class="rt-btn normal success">Normal Success</button>
                                    <button type="button" class="rt-btn small success">Small Success</button>
                                    <button type="button" class="rt-btn tiny success">Tiny Success</button>
                                </div><!-- /.rt-buttons-column -->
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn large txt">Large Text</button>
                                    <button type="button" class="rt-btn normal txt">Normal Text</button>
                                    <button type="button" class="rt-btn small txt">Small Text</button>
                                    <button type="button" class="rt-btn tiny txt">Tiny Text</button>
                                </div><!-- /.rt-buttons-column -->
                                <div class="rt-buttons-column">
                                    <button type="button" class="rt-btn normal primary" data-icon-nv="&#xe017;">Primary Icon</button>
                                    <button type="button" class="rt-btn normal secondary" data-icon-nv="&#xe02d;">Secondary Icon</button>
                                </div><!-- /.rt-buttons-column -->
                            </div><!-- /.rt-buttons-box-demo -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-port -->
        </div><!-- /.nv-section -->
    </main>
</div><!-- /.rtz-ui-demo-wrap -->
