import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResourceService {

  constructor(
      private apiService: ApiService
  ) { }

  getOrganizationResources(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/resources`, params);
  }

  updateOrganizationResource(organizationId: number, resourceId: number, tags: string[]) {
    return this.apiService.put(`organizations/${organizationId}/resources/${resourceId}`, { tags });
  }

  deleteOrganizationResource(organizationId: number, resourceId: number) {
    return this.apiService.delete(`organizations/${organizationId}/resources/${resourceId}`);
  }

  getOrganizationFolders(organizationId: number) {
    return this.apiService.get(`organizations/${organizationId}/tags`);
  }

  deleteOrganizationFolder(organizationId: number, tagName: string) {
    return this.apiService.delete(`organizations/${organizationId}/tags/${tagName}`);
  }

  createOrganizationFolder(organizationId: number, tag: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/tags/${tag}`, params);
  }

  createResourceUploader(organizationId: number, params: any = {}) {
    if (!localStorage.getItem(environment.navus_access_token_name)) { return; }
    return new FileUploader({
      ...params,
      maxFileSize: 100 * 1024 * 1024,
      url: `${environment.api_url}organizations/${organizationId}/resources`,
      authToken: 'Bearer ' + localStorage.getItem(environment.navus_access_token_name)
    });
  }

}
