<div class="rt-audience-audit">
    <header class="aa-header">
        <figure class="aa-logo"><a href title="Riterz.com"><img src="https://service.e-materials.com/storage/resources/navus_ag/rit/riterz_logo_aa.svg" alt="Riterz"></a></figure>
        <h1>Media Network Report</h1>
    </header><!-- /.aa-header -->

    <div class="gray">
        <div class="aa-gutter">
            <section class="aa-preamble">
                <div class="aa-company-face">
                    <figure><img src="https://img.logoipsum.com/285.svg" alt="Company name"></figure>
                    <h3>Riterz</h3>
                    <p>www.riterz.com</p>
                </div><!-- /.aa-company-face -->
                <div class="aa-summary">
                    <h2>Business Summary</h2>
                    <p>Riterz is an innovative platform designed to transform how organizations monetize their media channels and manage their sponsorship bookings. By providing a unified, easy-to-use platform, Riterz caters to organizations looking to enhance their media channel monetization strategies and streamline the sponsorship booking process. The platform is suitable for various organizations aiming to amplify their media presence, engage their audience more effectively, and track the results of their campaigns in real time.</p>
                </div><!-- /.aa-summary -->
            </section><!-- /.aa-preamble -->
        </div><!-- /.aa-gutter -->
    </div><!-- /.gray -->
    <div class="aa-gutter">
        <h2><strong>Channel:</strong> Website</h2>
        <div class="aa-row">
            <div class="aa-column desk-12">
                <div class="aa-card">
                    <div class="aa-metrics-group flow-left">
                        <div class="aa-metric">
                            <h5>BANNERS</h5>
                            <p class="aa-active">Active</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>ANALYTICS</h5>
                            <p class="aa-not-active">Not Active</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>LINK</h5>
                            <p><a href="https://www.giroditalia.it/" title="https://www.giroditalia.it">https://www.giroditalia.it</a></p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->

        <h4>HTML Summary</h4>
        <div class="aa-card">
            <div class="aa-txt">
                <p>Checked the website code for a Google Tag Manager ID and Google Analytics ID. The Analytics ID was found in a global site tag for Google Analytics (gtag.js) script tag. However, there was no direct mention of a Google Tag Manager ID within the script tags. The website displays banner advertisements referenced as 'rcsad_Position1' and other related elements. There are multiple sponsors and partners listed under 'top sponsor', 'statistiche', 'official partners', 'official suppliers', and 'official licensees' sections in the footer. The listed sponsors exceeded the 10 limited count and industries could not be determined from extracted data. Potential sponsors not listed might include those from the sports apparel, financial services, food & beverage, and transportation sectors.</p>
            </div><!-- /.aa-txt -->
        </div><!-- /.aa-card -->

        <div class="aa-row">
            <div class="aa-column desk-6 tab-12">
                <h4>Sponsors/Partners</h4>
                <div class="aa-card">
                    <div class="aa-txt">
                        <p>Enel,Mediolanum, Made in Italy, Eataly, TRENITALIA, Continental, TUDOR, VELUX, Castelli, NamedSport, Autostrade, Astoria, Sara, Clivet, ENIT, Danone Hi PRO, Novi, PATA, Toyota, GLS, K-way, Robe di Kappa, Valsir, LAURETANA, RAI, RTL 102.5, OGGI, Visit Malta, Zondacrypto</p>
                    </div><!-- /.aa-txt -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-6 tab-12">
                <h4>Potential Sponsors</h4>
                <div class="aa-card">
                    <div class="aa-txt">
                        <p>Nike SB, Adidas Skateboarding, Vans, SkateDeluxe, Route One, Palace Skateboards, Supreme, Thrasher Magazine, Element Skateboards, Spitfire Wheels</p>
                    </div><!-- /.aa-txt -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->

        <h2><strong>Channel:</strong> Organic Social Media</h2>
        <div class="aa-social-tag">
            <figure><img src="https://service.e-materials.com/storage/resources/navus_ag/aa_/aa_linkedin.png" alt="Linkedin"></figure>
            <h3><strong>LinkedIn</strong></h3>
            <p class="active">Active</p>
        </div><!-- /.aa-social-tag -->
        <div class="aa-row">
            <div class="aa-column desk-2 lap-3 phab-12">
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>FOLLOWERS</h5>
                            <p>109</p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-10 lap-9 phab-12">
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>COMPANY</h5>
                            <p>Skateboard GB</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>INDUSTRY</h5>
                            <p>Sports</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>EMPLOYEES</h5>
                            <p>34</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>LINK</h5>
                            <p><a href="https://www.linkedin.com/company/giroditalia/" title="https://www.linkedin.com/company/giroditalia/">https://www.linkedin.com/company/giroditalia</a></p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->
        <h3>Posts</h3>
        <div class="aa-row">
            <div class="aa-column desk-6 lap-12">
                <h4>Post Frequency</h4>
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>AVG PER DAY</h5>
                            <p>0.20</p>
                        </div><!-- /.aa-metric -->

                        <div class="aa-metric-border"></div>

                        <div class="aa-metric">
                            <h5>90 DAY</h5>
                            <p>21</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>30 DAY</h5>
                            <p>12</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>30 DAY CHANGE</h5>
                            <p class="green">+71%</p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-6 lap-12">
                <h4>Post Engagement</h4>
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>90 DAY</h5>
                            <p>1.81%</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>30 DAY</h5>
                            <p>1.39%</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>30 DAY CHANGE</h5>
                            <p class="red">-23%</p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-6 tab-12">
                <h4>Hashtags</h4>
                <div class="aa-card">
                    <div class="aa-txt">
                        <p>#JoinTheFlipSide, #DropInWithSamsung, #Skateboarding, #SkateboardGB, #CruiserAward, #SkateboardLesson, #paris2024, #coaching, #CampaignExperieneAwards, #GiveAnX, #RegisterToVote, #RoadToParis2024, #OlympicSkateboarding, #community, #skateboardgb, #tramlinespot</p>
                    </div><!-- /.aa-txt -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-6 tab-12">
                <h4>Mentions</h4>
                <div class="aa-card">
                    <div class="aa-txt">
                        <p>
                            <strong>People:</strong><br>George O'Neill, Ashley Mercer, Lilly Strachan, Sam Beckett, Paul-luc Ronchetti, Geoff Else, Osian George, Andy Macdonald<br><br>
                            <strong>Companies:</strong> Sky Kids, Samsung Electronics UK, Skate Nottingham, Flip Park Notts, Team GB, International Olympic Committee – IOC, UK Sport, World Skate, Paris 2024 - Comité d'organisation des Jeux Olympiques et Paralympiques de 2024, Iris, Camp Rubicon, Nike Skateboarding, Casper Brooker, Betongpark Ltd, Bournbrook DIY, Birmingham Skate Spaces, Shred the North, Sport England, Crowdfunder UK, WHEELSCAPE LTD, Maverick Skateparks, Bristol Skatepark Collective, Hove Lagoon locals, Herion Skateboards
                        </p>
                    </div><!-- /.aa-txt -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-12">
                <h4>LinkedIn Content Summary</h4>
                <div class="aa-card">
                    <div class="aa-txt">
                        <p>Skateboard GB's posts cover events, partnerships with Samsung UK, competitions, coaching courses, and community engagement initiatives focused on skateboarding culture and achievements. The content successfully merges skateboarding's excitement and lifestyle with educational and community-building efforts, often highlighting collaboration with Samsung Electronics UK for various programs and events, alongside spotlighting athletes' accomplishments and contributions to the skateboarding community.</p>
                    </div><!-- /.aa-txt -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->

        <div class="aa-space"></div>

        <div class="aa-social-tag">
            <figure><img src="https://service.e-materials.com/storage/resources/navus_ag/aa_/aa_facebook.png" alt="Facebook"></figure>
            <h3><strong>Facebook</strong></h3>
            <p class="active">Active</p>
        </div><!-- /.aa-social-tag -->
        <div class="aa-row">
            <div class="aa-column desk-2 lap-3 phab-12">
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>FOLLOWERS</h5>
                            <p>8,819</p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-10 lap-9 phab-12">
                <div class="aa-card">
                    <div class="aa-metrics-group flow-left">
                        <div class="aa-metric">
                            <h5>COMPANY</h5>
                            <p>Skateboard GB</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>LINK</h5>
                            <p><a href="https://www.facebook.com/SkateboardGB/" title="https://www.facebook.com/SkateboardGB/">https://www.facebook.com/SkateboardGB</a></p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->
        <h3>Posts</h3>
        <div class="aa-row">
            <div class="aa-column desk-6 lap-12">
                <h4>Post Frequency</h4>
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>AVG PER DAY</h5>
                            <p>0.20</p>
                        </div><!-- /.aa-metric -->

                        <div class="aa-metric-border"></div>

                        <div class="aa-metric">
                            <h5>90 DAY</h5>
                            <p>21</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>30 DAY</h5>
                            <p>12</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>CHANGE</h5>
                            <p class="green">+6%</p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-6 lap-12">
                <h4>Post Engagement</h4>
                <div class="aa-card">
                    <div class="aa-metrics-group">
                        <div class="aa-metric">
                            <h5>90 DAY</h5>
                            <p>1.81%</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>30 DAY</h5>
                            <p>1.39%</p>
                        </div><!-- /.aa-metric -->
                        <div class="aa-metric">
                            <h5>CHANGE</h5>
                            <p class="red">-89%</p>
                        </div><!-- /.aa-metric -->
                    </div><!-- /.aa-metrics-group -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-12">
                <h4>Facebook Content Summary</h4>
                <div class="aa-card">
                    <div class="aa-txt">
                        <p>Skateboard GB's Facebook posts focus on promoting skateboarding events, competitions, coaching courses, and spotlighting community achievements, with an emphasis on youth and inclusivity. There's a mix of event announcements, competition results, and partnership highlights, particularly with technology brands like Samsung, showcasing a blend of skate culture and technological integration in the sport. The content effectively engages the skateboarding community by providing valuable information, celebrating achievements, and encouraging participation.</p>
                    </div><!-- /.aa-txt -->
                </div><!-- /.aa-card -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->

        <div class="aa-space"></div>

        <h2><strong>Channel:</strong> Paid Social Media</h2>
        <h4>PPC Activation Status</h4>
        <div class="aa-card">
           <div class="aa-metrics-group flow-left">
               <div class="aa-metric">
                   <figure><img src="https://service.e-materials.com/storage/resources/navus_ag/aa_/aa_linkedin.png" alt="Linkedin"></figure>
               </div><!-- /.aa-metric -->
               <div class="aa-metric">
                   <h5>STATUS</h5>
                   <p>Not Detected</p>
               </div><!-- /.aa-metric -->
               <div class="aa-metric">
                   <h5>ADS - 365</h5>
                   <p>-</p>
               </div><!-- /.aa-metric -->

               <div class="aa-metric-border"></div>

               <div class="aa-metric">
                   <figure><img src="https://service.e-materials.com/storage/resources/navus_ag/aa_/aa_facebook.png" alt="Facebook"></figure>
               </div><!-- /.aa-metric -->
               <div class="aa-metric">
                   <h5>STATUS</h5>
                   <p>Not Detected</p>
               </div><!-- /.aa-metric -->
               <div class="aa-metric">
                   <h5>ADS Live</h5>
                   <p>-</p>
               </div><!-- /.aa-metric -->
           </div><!-- /.aa-metrics-group -->
        </div><!-- /.aa-card -->

        <div class="aa-space"></div>

        <h2><strong>Monetisation:</strong> Revenue Scenarios</h2>
        <h3>Onsite Web Banners</h3>
        <div class="aa-row">
            <div class="aa-column desk-4 lap-12">
                <div class="aa-resp-table">
                    <table class="aa-table">
                        <tr>
                            <th>Banner Placements Integrated on Website</th>
                        </tr>
                        <tr><td>Banner Spaces</td></tr>
                        <tr><td>2</td></tr>
                        <tr><td>5</td></tr>
                        <tr><td>10</td></tr>
                    </table><!-- /.aa-table -->
                </div><!-- /.aa-resp-table -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-8 lap-12">
                <div class="aa-resp-table">
                    <table class="aa-table">
                        <tr>
                            <th colspan="3">365 Day Revenue after 10% commission</th>
                        </tr>
                        <tr>
                            <td>@ $5 Per Day</td>
                            <td>@ $10 Per Day</td>
                            <td>@ $20 Per Day</td>
                        </tr>
                        <tr>
                            <td>$3,285</td>
                            <td>$8,213</td>
                            <td>$16,425</td>
                        </tr>
                        <tr>
                            <td>$6,570</td>
                            <td>$16,425</td>
                            <td>$32,850</td>
                        </tr>
                        <tr>
                            <td>$13,140</td>
                            <td>$32,850</td>
                            <td>$65,700</td>
                        </tr>
                    </table><!-- /.aa-table -->
                </div><!-- /.aa-resp-table -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->

        <div class="aa-space"></div>

        <div class="aa-social-tag">
            <figure><img src="https://service.e-materials.com/storage/resources/navus_ag/aa_/aa_linkedin.png" alt="Linkedin"></figure>
            <h3><strong>Reselling</strong> LinkedIn PPC</h3>
        </div><!-- /.aa-social-tag -->

        <p>Revenue Potential- Cobranded PPC ads through your social network, charged at 20% commission of advertising spend for 3 sponsors</p>
        <div class="aa-row">
            <div class="aa-column desk-6 lap-12">
                <div class="aa-resp-table">
                    <table class="aa-table">
                        <tr>
                            <th colspan="3">Audience Size + Growth Estimates</th>
                        </tr>
                        <tr>
                            <td>Base Audience</td>
                            <td>Audience Multiplier</td>
                            <td>Impressions (yr)</td>
                        </tr>
                        <tr>
                            <td>3,262</td>
                            <td>2x</td>
                            <td>166,688</td>
                        </tr>
                        <tr>
                            <td>3,262</td>
                            <td>5x</td>
                            <td>416,721</td>
                        </tr>
                        <tr>
                            <td>3,262</td>
                            <td>10x</td>
                            <td>833,441</td>
                        </tr>
                        <tr>
                            <td>3,262</td>
                            <td>20x</td>
                            <td>1,666,882</td>
                        </tr>
                    </table><!-- /.aa-table -->
                </div><!-- /.aa-resp-table -->
            </div><!-- /.aa-column -->
            <div class="aa-column desk-6 lap-12">
                <div class="aa-resp-table">
                    <table class="aa-table">
                        <tr>
                            <th colspan="3">365 Day Revenue @  20% commission*</th>
                        </tr>
                        <tr>
                            <td>20% Capacity</td>
                            <td>50% Capacity</td>
                            <td>100% Capacity</td>
                        </tr>
                        <tr>
                            <td>$700</td>
                            <td>$1,750</td>
                            <td>$3,500</td>
                        </tr>
                        <tr>
                            <td>$1,750</td>
                            <td>$4,376</td>
                            <td>$8,751</td>
                        </tr>
                        <tr>
                            <td>$3,500</td>
                            <td>$8,751</td>
                            <td>$17,502</td>
                        </tr>
                        <tr>
                            <td>$7,001</td>
                            <td>$17,502</td>
                            <td>$35,005</td>
                        </tr>
                    </table><!-- /.aa-table -->
                </div><!-- /.aa-resp-table -->
            </div><!-- /.aa-column -->
        </div><!-- /.aa-row -->
        <div class="aa-split">
            <p><i>Esitmations only, based on CPM $35 and Imrpessions per day of 7% audience size</i></p>
            <p><i>*dollar values in USD</i></p>
        </div><!-- /.aa-split -->


    </div><!-- /.aa-gutter -->
    <footer class="aa-footer">
        <p><a href="http://www.riterz.com" title="www.riterz.com">www.riterz.com</a></p>
    </footer><!-- /.aa-footer -->
</div><!-- /.rt-audience-audit -->

