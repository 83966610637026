<div class="rtz-ui-demo-wrap">
    <aside>
        <ul>
            <li><a class="active" href="ui-docs/docs-colors">Color Scheme</a></li>
            <li><a href="ui-docs/docs-typography">Typography</a></li>
            <li><a href="ui-docs/docs-icons">Icons</a></li>
            <li><a href>Headers (Content)</a></li>
            <li><a href="ui-docs/docs-buttons">Buttons</a></li>
            <li><a href>Toasts</a></li>
        </ul>
    </aside>
    <main>
        <div class="nv-section">
            <div class="nv-port">
                <div class="nv-row gap-24 large-spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <h2>Colors</h2>
                            <div class="rt-colors-box">
                                <div class="rt-colors-row">
                                    <p>PRIMARY</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square primary"><span>Primary</span></div>
                                        <div class="rt-color-square primary-dark"><span>Primary Dark</span></div>
                                        <div class="rt-color-square primary-light"><span>Primary Light</span></div>
                                    </div><!-- /.rt-colors-boxes -->
                                </div><!-- /.rt-colors-row -->
                                <div class="rt-colors-row">
                                    <p>secondary</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square secondary"><span>Secondary</span></div>
                                        <div class="rt-color-square secondary-light"><span>Secondary Light</span></div>
                                        <div class="rt-color-square secondary-lighter"><span>Secondary Lighter</span></div>
                                    </div><!-- /.rt-colors-boxes -->
                                </div><!-- /.rt-colors-row -->
                                <div class="rt-colors-row">
                                    <p>tertiary</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square tertiary"><span>Tertiary</span></div>
                                        <div class="rt-color-square tertiary-dark"><span>Tertiary Dark</span></div>
                                        <div class="rt-color-square tertiary-light"><span>Tertiary Light</span></div>
                                    </div><!-- /.rt-colors -->
                                </div><!-- /.rt-colors-row -->
                                <div class="rt-colors-row">
                                    <p>Quaternary</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square quaternary"><span>Quaternary</span></div>
                                        <div class="rt-color-square quaternary-dark"><span>Quaternary Dark</span></div>
                                        <div class="rt-color-square quaternary-light"><span>Quaternary Light</span></div>
                                    </div><!-- /.rt-colors-boxes -->
                                </div><!-- /.rt-colors-row -->
                                <div class="rt-colors-row">
                                    <p>Text</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square txt"><span>Text (Body)</span></div>
                                    </div><!-- /.rt-colors-boxes -->
                                </div><!-- /.rt-colors-row -->
                                <div class="rt-colors-row">
                                    <p>GRAY</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square black"><span>Black</span></div>
                                        <div class="rt-color-square gray-80"><span>Gray 80</span></div>
                                        <div class="rt-color-square gray-60"><span>Gray 60</span></div>
                                        <div class="rt-color-square gray-22"><span>Gray 22</span></div>
                                        <div class="rt-color-square gray-10"><span>Gray 10</span></div>
                                        <div class="rt-color-square gray-5"><span>Gray 5</span></div>
                                    </div><!-- /.rt-colors-boxes -->
                                </div><!-- /.rt-colors-row -->
                                <div class="rt-colors-row">
                                    <p>Alert</p>
                                    <div class="rt-colors-boxes">
                                        <div class="rt-color-square error"><span>Error</span></div>
                                        <div class="rt-color-square error-light"><span>Error Light</span></div>
                                        <div class="rt-color-square success"><span>Success</span></div>
                                        <div class="rt-color-square success-light"><span>Success Light</span></div>
                                        <div class="rt-color-square warning"><span>Warning</span></div>
                                        <div class="rt-color-square warning-light"><span>Warning Light</span></div>
                                    </div><!-- /.rt-colors-boxes -->
                                </div><!-- /.rt-colors-row -->
                            </div><!-- /.rt-colors-box -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-port -->
        </div><!-- /.nv-section -->
    </main>
</div><!-- /.rtz-ui-demo-wrap -->
