<header class="nv-temp-header nv-section website-279" ng-reflect-ng-class="[object Object]"><div class="nv-row space-between"><div class="nv-column desk-initial v-middle"><div class="nv-column-content"><div class="nv-template-logo"><a href="/" routerlink="/" ng-reflect-router-link="/" title="Navus Hybrid Demo Event"><img alt="Navus Hybrid Demo Event" src="https://service.e-materials.com/storage/resources/navus_ag/nav/navus-demo-logo.jpg"><!--bindings={
  "ng-reflect-ng-if": "https://service.e-materials.co"
}--></a></div></div></div><div class="nv-column desk-initial v-stretch" style="display: flex;"><div class="nv-column-content"><div class="nv-template-menu-n-profile">


    <nav class="nv-template-menu"><ul><li class="nav-page-580"><a class="nav-link" ng-reflect-router-link="/580/page/home" ng-reflect-router-link-active="active" href="/580/page/home"><span>Home</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-582"><a class="nav-link active" ng-reflect-router-link="/search" ng-reflect-router-link-active="active" href="/search"><span>On Demand</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-583"><a class="nav-link" ng-reflect-router-link="/program" ng-reflect-router-link-active="active" href="/program"><span>Program</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-581"><a class="nav-link" ng-reflect-router-link="/live-stage" ng-reflect-router-link-active="active" href="/live-stage"><span>Live Stage</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-726"><a class="nav-link" ng-reflect-router-link="/speakers" ng-reflect-router-link-active="active" href="/speakers"><span>Speakers</span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><li class="nav-page-584"><a class="nav-link" ng-reflect-router-link="/sponsors" ng-reflect-router-link-active="active" href="/sponsors"><span>Sponsors </span></a><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}--><!--bindings={}--></li><!--bindings={
  "ng-reflect-ng-for-of": "[object Object],[object Object"
}--></ul></nav>


    <div class="nv-template-notifications on">
        <div class="nv-notifications-toggler">
            <span>4</span>
        </div><!-- /.nv-notifications-toggler -->
        <div class="nv-notifications-pane">
            <div class="nv-notifications-pane-frame">


                <!-- Toast  Notification -->
<!--                <div class="nv-toast-notification">-->
<!--                    <header>-->
<!--                        <h3>Notification title</h3>-->
<!--                        <span class="close"></span>-->
<!--                    </header>-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                    <p><a href class="nv-btn small primary">Action Button</a></p>-->
<!--                </div>&lt;!&ndash; /.nv-toast-notification &ndash;&gt;-->



                <!-- Toast  Notification BADGE -->
<!--                <div class="nv-toast-notification badge">&lt;!&ndash; < new class 'badge' &ndash;&gt;-->
<!--                    <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>-->
<!--                    <header>-->
<!--                        <h3>Badge earned!</h3>-->
<!--                        <span class="close"></span>-->
<!--                    </header>-->
<!--                    <p><strong>Time spent on Main Stage</strong></p>-->
<!--                    <p class="medium"><a href title="View badges">View badges</a></p>-->
<!--                </div>&lt;!&ndash; /.nv-toast-notification &ndash;&gt;-->


                <!-- Original Notifications -->
<!--                <header class="nv-notifications-pane-header">-->
<!--                    <h4>Notifications</h4>-->
<!--                    <a class="nv-notifications-mark-read" title="Mark all as read">Mark all as read</a>-->
<!--                </header>&lt;!&ndash; /.nv-notifications-pane-header &ndash;&gt;-->
<!--                <nav class="nv-notifications-list-alt">-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p><a href class="nv-btn small accent1">Action Button</a></p>-->
<!--                        </li>-->
<!--                        <li class="nv-read">-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Unmark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p><a href class="nv-btn small accent1">Action Button</a></p>-->
<!--                        </li>-->
<!--                        <li class="nv-read">-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Unmark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis iaculis nisl, id bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <span class="nv-mark-as-read">-->
<!--                                <a></a>-->
<!--                                <em>Mark as read</em>-->
<!--                            </span>-->
<!--                            <time>12:30 - 13:15</time>-->
<!--                            <h3>Notification title</h3>-->
<!--                            <p>Bibendum orci finibus id. Lorem ipsum dolor sit amet.</p>-->
<!--                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </nav>&lt;!&ndash; /.nv-notifications-list-alt &ndash;&gt;-->
            </div><!-- /.nv-notifications-pane-frame -->
        </div><!-- /.nv-notifications-pane -->
    </div><!-- /.nv-template-notifications -->















<div class="nv-template-profile">
<div nvoffclick="" class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu"> DK <nav><ul><li><a ng-reflect-router-link="/profile" href="/profile">Profile</a></li><li><a translate="" ng-reflect-translate="">Sign Out</a></li></ul></nav></div><!--bindings={
  "ng-reflect-ng-if": "[object Object]"
}--></div></div></div></div><div class="nv-column desk-none tab-initial v-stretch"><div class="nv-column-content"><div class="nv-touch-menu-trigger"></div></div></div></div></header>

<div class="wrap">


    <div class="rt-wp-tabs">
        <ul>
            <li class="active"><span>Owned</span></li>
            <li><span>Social</span></li>
            <li><span>Events</span></li>
        </ul>
        <div class="rt-wp-tab">
            <div class="rt-wp-tab-images owned">
                <div class="tab-item">
                    <figure><img alt="Email" src="https://riterz.com/wp-content/uploads/2024/04/owned-media-1.png"></figure>
                    <span>Email</span>
                </div>
                <div class="tab-item">
                    <figure><img alt="Event portal" src="https://riterz.com/wp-content/uploads/2024/04/owned-media-2.png"></figure>
                    <span>Event portal</span>
                </div>
                <div class="tab-item">
                    <figure><img alt="Website" src="https://riterz.com/wp-content/uploads/2024/04/owned-media-3.png"></figure>
                    <span>Website</span>
                </div>
            </div><!-- /.rt-wp-tab-images -->
            <div class="rt-wp-tab-images social">
                <div class="tab-item">
                    <figure><img alt="Facebook" src="https://riterz.com/wp-content/uploads/2024/04/social-media-1.png"></figure>
                    <span>Facebook</span>
                </div>
                <div class="tab-item">
                    <figure><img alt="LinkedIn" src="https://riterz.com/wp-content/uploads/2024/04/social-media-2.png"></figure>
                    <span>LinkedIn</span>
                </div>
            </div><!-- /.rt-wp-tab-images -->
            <div class="rt-wp-tab-images events">
                <div class="tab-item">
                    <figure><img alt="Digital Screen" src="https://riterz.com/wp-content/uploads/2024/04/events.png"></figure>
                    <span>Digital Screen</span>
                </div>
            </div><!-- /.rt-wp-tab-images -->
        </div><!-- /.rt-wp-tab -->
    </div><!-- /.rt-wp-tabs -->

    <div class="rt-additional-services">
        <div class="rt-as-responsive-wrap">
            <div class="rt-as-preamble">
                <h3>Channel Activation</h3>
                <p>Activate, prepare and connect your organisation media channels to the Riterz Platform.</p>
            </div><!-- /.rt-as-preamble -->
            <div class="rt-additional-services-cards">
                <div class="rt-as-item">
                    <header>
                        <h4>Web Banner</h4>
                        <p>Custom website integration</p>
                        <h5>$1,500</h5>
                    </header>
                    <div class="rt-as-body">
                        <p><strong>Integration</strong></p>
                        <ul>
                            <li>Google tag manager account setup</li>
                            <li>4 x website banner placements</li>
                            <li>Liaise with web developer for integration management</li>
                            <li>Platform connection</li>
                            <li>Deployment, testing and quality assurance</li>
                        </ul>
                    </div><!-- /.rt-as-body -->
                    <div class="rt-as-btn">
                        <a class="elementor-button elementor-size-md" href="https://payments.riterz.io/b/7sIeXuddKgfu5KE7sx" title="Web Banner - Custom website integration">Select</a>
                    </div>
                </div><!-- /.rt-as-item -->
                <div class="rt-as-item">
                    <header>
                        <h4>LinkedIn</h4>
                        <p>Activation + Audience Expansion</p>
                        <h5>$1,500</h5>
                    </header>
                    <div class="rt-as-body">
                        <p><strong>Page + Accounts</strong></p>
                        <ul>
                            <li>Registration of company LinkedIn page (if required)</li>
                            <li>Development of campaign manager account</li>
                            <li>Registration of campaign manager PPC account</li>
                        </ul>
                        <p><strong>Audience Expansion</strong></p>
                        <ul>
                            <li>Syncing offline databases</li>
                            <li>Lookalike audience buidling</li>
                            <li>Developing new audiences based on demographics</li>
                            <li>Website re-targeting integration</li>
                            <li>Re-targetting audience building</li>
                        </ul>
                    </div><!-- /.rt-as-body -->
                    <div class="rt-as-btn">
                        <a class="elementor-button elementor-size-md" href="https://payments.riterz.io/b/3cseXu6Pm8N27SM4gm" title="LinkedIn - Activation + Audience Expansion">Select</a>
                    </div>
                </div><!-- /.rt-as-item -->
                <div class="rt-as-item">
                    <header>
                        <h4>Meta</h4>
                        <p>Activation + Audience Expansion</p>
                        <h5>$1,500</h5>
                    </header>
                    <div class="rt-as-body">
                        <p><strong>Page + Accounts</strong></p>
                        <ul>
                            <li>Registration of company FB page (if required)</li>
                            <li>Registration of Business Manager</li>
                            <li>Creation of Ad Account (if required)</li>
                        </ul>
                        <p><strong>Audience Expansion</strong></p>
                        <ul>
                            <li>Syncing offline databases</li>
                            <li>Lookalike audience buidling</li>
                            <li>Developing new audiences based on demographics</li>
                            <li>Website re-targeting integration</li>
                            <li>Re-targetting audience building</li>
                        </ul>
                    </div><!-- /.rt-as-body -->
                    <div class="rt-as-btn">
                        <a class="elementor-button elementor-size-md" href="https://payments.riterz.io/b/fZeeXu3Da8N20qk4gn" title="Meta - Activation + Audience Expansion">Select</a>
                    </div>
                </div><!-- /.rt-as-item -->
                <div class="rt-as-item fake"></div>
                <div class="rt-as-item ad-dev">
                    <header>
                        <h4>Ad Development</h4>
                        <p>On demand creative services</p>
                        <h5>$399</h5>
                    </header>
                    <div class="rt-as-body">
                        <p><strong>Ad Creatives</strong></p>
                        <p>Unique and dynamic co-branded ad development to unblock your marketing schedule</p>
                        <ul>
                            <li>Thoughtful and impactful ad concepts that deliver value to both your audience and your sponsor</li>
                            <li>3 x distinct ads developed with A/B versions for each (total 6 ads)</li>
                            <li>Image asset development in coherence with your organisations brand guide</li>
                            <li>3 feedback rounds, your sponsor included</li>
                        </ul>
                    </div><!-- /.rt-as-body -->
                    <div class="rt-as-btn">
                        <a class="elementor-button elementor-size-md" href="https://payments.riterz.io/b/28o5mU0qYgfu3Cw4go" title="Ad Development - On demand creative services">Select</a>
                    </div>
                </div><!-- /.rt-as-item -->
            </div><!-- /.rt-additional-services-cards -->
        </div><!-- /.rt-as-responsive-wrap -->
        <div class="rt-as-item resp">
            <header>
                <h4>Ad Development</h4>
                <p>On demand creative services</p>
                <h5>$399</h5>
            </header>
            <div class="rt-as-body">
                <p><strong>Ad Creatives</strong></p>
                <p>Unique and dynamic co-branded ad development to unblock your marketing schedule</p>
                <ul>
                    <li>Thoughtful and impactful ad concepts that deliver value to both your audience and your sponsor</li>
                    <li>3 x distinct ads developed with A/B versions for each (total 6 ads)</li>
                    <li>Image asset development in coherence with your organisations brand guide</li>
                    <li>3 feedback rounds, your sponsor included</li>
                </ul>
            </div><!-- /.rt-as-body -->
        </div><!-- /.rt-as-item -->
    </div><!-- /.rt-additional-services -->


    <section class="nv-section guttered" style="background-color: #fff;">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">

                        <table class="efr-speakers-table">
                            <tr>
                                <th>Speaker Name</th>
                                <th>Speaker Topic</th>
                            </tr>
                            <tr>
                                <td>Ahmed Ba-Ssalamah</td>
                                <td>Preoperative Detection and characterization of liver lesions and prediction of PHLT</td>
                            </tr>
                            <tr>
                                <td>Reto Bale</td>
                                <td>Can stereotactic radiofrequency replace liver surgery for the treatment of colorectal liver metastases?</td>
                            </tr>
                            <tr>
                                <td>Guido Torzilli </td>
                                <td>Up to what, when and how to resect colorectal liver metastases? Our answer is……</td>
                            </tr>
                            <tr>
                                <td>Klaus Kaczirek</td>
                                <td>Pushing the limits in surgery for mCRC</td>
                            </tr>
                            <tr>
                                <td>Werner Dolak</td>
                                <td>Advancements in endoscopic resection of early rectal cancer</td>
                            </tr>
                            <tr>
                                <td>Antonio Spinelli</td>
                                <td>Reducing stoma rate in rectal cancer surgery: is it safe?</td>
                            </tr>
                            <tr>
                                <td>Rodrigo Perez</td>
                                <td>
                                    1) Is there a place for organ -preservation in early rectal cancer – T2N0?<br>
                                    2) Near-complete responses after CRT in rectal cancer – Who should wait and who should not?
                                </td>
                            </tr>
                            <tr>
                                <td>Walter Brunner</td>
                                <td>Comeback of pull through anastomosis in rectal cancer surgery? When & How?</td>
                            </tr>
                            <tr>
                                <td>Phil Quirke</td>
                                <td>Enhancing bowel cancer outcomes</td>
                            </tr>
                            <tr>
                                <td>Eric Van Cutsem</td>
                                <td>1) Personalisation and individualization of metastatic cancer or your suggestions<br>2) Result of survival analysis in the TASCO1 study</td>
                            </tr>
                            <tr>
                                <td>Hermann Kessler</td>
                                <td>1) Evidence Based Update on CME and Central Vascular Ligation<br>2) Transverse colon cancer: Are there differences depending on location and extent of surgery?……..</td>
                            </tr>
                            <tr>
                                <td>Werner Hohenberger</td>
                                <td>CME: Open surgery still the gold standard ?</td>
                            </tr>
                            <tr>
                                <td>Regina Beets-Tan</td>
                                <td>MR imaging in Rectal Cancer – assessment and prediction of response to neoadjuvant treatment</td>
                            </tr>
                            <tr>
                                <td>Gerard Beets</td>
                                <td>How to increase complete responses and watch&wait in rectal cancer?………..</td>
                            </tr>
                            <tr>
                                <td>Yves Panis</td>
                                <td>1) Management of postoperative rectovaginal fistula<br>2) What is the best approach for colorectal cancer surgery in 2024: open? Laparoscopy? Robot?</td>
                            </tr>
                            <tr>
                                <td>György Lazar</td>
                                <td>Colitis associated colorectal cancer</td>
                            </tr>
                            <tr>
                                <td>Felix Aigner</td>
                                <td>Flourescence angiography and AI inrectal cancer surgery</td>
                            </tr>
                            <tr>
                                <td>Bill Heald</td>
                                <td>Fifty Years RectalCancer</td>
                            </tr>
                            <tr>
                                <td>Gerald Prager</td>
                                <td>New systemic treatment concepts in resectable CRC patients!</td>
                            </tr>
                            <tr>
                                <td>Irene Kührer</td>
                                <td>Liquide Biopses in CRC</td>
                            </tr>
                            <tr>
                                <td>Vicenzo Valentini</td>
                                <td>Artificial Intellegence opportunities for rectal cancer in an era of modern oncology</td>
                            </tr>
                            <tr>
                                <td>Andreas Salat</td>
                                <td>Today´s and future options for anal cancer</td>
                            </tr>
                            <tr>
                                <td>Marek Szczepkowski</td>
                                <td>Transanal techniques to remove neoplastic leasiones in the rectum. Which technique is the best in 2024? State of the art and own experience.</td>
                            </tr>
                            <tr>
                                <td>Parvaiz A.</td>
                                <td>Upfront surgery for rectal cancer: indications & oncological outcomes</td>
                            </tr>
                            <tr>
                                <td>Adam Dziki</td>
                                <td>Optimal interval after neoadjuvant therapy in rectal cancer</td>
                            </tr>
                            <tr>
                                <td>Rossen Madjov</td>
                                <td>Management of Colorectal Liver Metastases</td>
                            </tr>
                        </table>
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /nv-row -->
        </div><!-- /nv-port -->
    </section>


    <!-- Rtierz WP site price tables -->
<!--    <script>-->
<!--        jQuery(document).ready(function($) {-->
<!--            $(".rt-info-trigger").click(function () {-->
<!--                $(".rt-info-trigger").removeClass('on');-->
<!--                $(this).addClass('on');-->
<!--            });-->
<!--            $(".rt-info-close").click(function () {-->
<!--                $(this).parent().parent().prev().removeClass('on');-->
<!--            });-->
<!--        });-->
<!--    </script>-->

<!--    <div class="rt-price-tables-wrap">-->
<!--        <div class="rt-price-table-frame">-->
<!--            <div class="rt-price-table">-->
<!--                <header class="rt-pt">-->
<!--                    <h2>Free</h2>-->
<!--                    <ul>-->
<!--                        <li>2 media channels</li>-->
<!--                        <li>1 Sponsor</li>-->
<!--                        <li>White Glove Customer Service</li>-->
<!--                        <li>Duration 4-12 weeks</li>-->
<!--                        <li class="ad-crdedit-item"><strong>Ad Credit &euro;1,000</strong><br>We match your campaign spent for your sponsor’s campaign</li>-->
<!--                        <li>Audience Activation – We help you upload, extend and segment your audiences</li>-->
<!--                    </ul>-->
<!--                </header>-->
<!--                <div class="rt-pt-body">-->
<!--                    <p class="rt-pt-special">-->
<!--                        Limited Offer-->
<!--                    </p>-->
<!--                    <p class="rt-pt-price">-->
<!--                        <em>&euro;</em><span>0</span>-->
<!--                    </p>-->
<!--                    <span class="rt-info-trigger"></span>-->
<!--                    <div class="rt-info-box">-->
<!--                        <div class="rt-info-box-content">-->
<!--                            <span class="rt-info-close"></span>-->
<!--                            <p>Limited offer. Criteria apply.</p>-->
<!--                            <p>Your decide about the start of the trial.</p>-->
<!--                            <p>No obligation. Cancel at any time.</p>-->
<!--                            <p>The audience activation is optional. We never take your data. All data is managed by yourself.</p>-->
<!--                        </div>&lt;!&ndash; /.rt-info-box-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.rt-info-box &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.rt-pt-body &ndash;&gt;-->
<!--                <div class="rt-pt-btn">-->
<!--                    <a class="elementor-button elementor-size-md" href="/apply-for-free-plan" title="Riterz Ad Credit $1000">Expression of interest</a>-->
<!--                </div>&lt;!&ndash; /.rt-pt-btn &ndash;&gt;-->
<!--            </div>&lt;!&ndash; /.rt-price-table &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.rt-price-table-frame &ndash;&gt;-->
<!--        <div class="rt-price-table-frame">-->
<!--            <div class="rt-price-table">-->
<!--                <header class="rt-pt">-->
<!--                    <h2>Advanced</h2>-->
<!--                    <ul>-->
<!--                        <li>1 external media channel</li>-->
<!--                        <li>1 owned media channel</li>-->
<!--                        <li>5 Sponsors</li>-->
<!--                        <li>Dedicated Customer Success Agent</li>-->
<!--                        <li>Unlimited Admin seats</li>-->
<!--                    </ul>-->
<!--                </header>-->
<!--                <div class="rt-pt-body">-->
<!--                    <p class="rt-pt-price">-->
<!--                        Regular subscription fee of<br><em>&euro;</em><span>250</span><br>per month<sup>*</sup><br><br>-->
<!--                        or 10% variable fee deducted from your sponsors’ external gross media booking, whichever is greater.-->
<!--                    </p>-->
<!--                    <span class="rt-info-trigger"></span>-->
<!--                    <div class="rt-info-box">-->
<!--                        <div class="rt-info-box-content">-->
<!--                            <span class="rt-info-close"></span>-->
<!--                            <p>Once the variable fee exceeds the subscription fee the whole subscription fee will be credited to your account.</p>-->
<!--                            <p>The variable fee does not apply for your own paid campaigns.</p>-->
<!--                        </div>&lt;!&ndash; /.rt-info-box-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.rt-info-box &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.rt-pt-body &ndash;&gt;-->
<!--                <div class="rt-pt-btn">-->
<!--                    <a class="elementor-button elementor-size-md" href="/apply-for-advance-plan" title="Riterz Advance Package">Select</a>-->
<!--                    <p>&euro;250.- if paid annually in advance, &euro;290.- if paid monthly. Cancel at any time</p>-->
<!--                </div>&lt;!&ndash; /.rt-pt-btn &ndash;&gt;-->
<!--            </div>&lt;!&ndash; /.rt-price-table &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.rt-price-table-frame &ndash;&gt;-->
<!--        <div class="rt-price-table-frame">-->
<!--            <div class="rt-price-table">-->
<!--                <header class="rt-pt">-->
<!--                    <h2>Pro</h2>-->
<!--                    <ul>-->
<!--                        <li>3 external media channels</li>-->
<!--                        <li>2 owned media channels</li>-->
<!--                        <li>20 Sponsors</li>-->
<!--                        <li>Free Onboarding</li>-->
<!--                        <li>Unlimited Admin Seats</li>-->
<!--                        <li>Dedicated Customer Success Agent</li>-->
<!--                    </ul>-->
<!--                </header>-->
<!--                <div class="rt-pt-body">-->
<!--                    <p class="rt-pt-price">-->
<!--                        Regular subscription fee of<br><em>&euro;</em><span>450</span><br>per month<sup>*</sup><br><br>-->
<!--                        or 10% variable fee of external gross media booking, whichever is greater.-->
<!--                    </p>-->
<!--                    <span class="rt-info-trigger"></span>-->
<!--                    <div class="rt-info-box">-->
<!--                        <div class="rt-info-box-content">-->
<!--                            <span class="rt-info-close"></span>-->
<!--                            <p>Once the variable fee exceeds the subscription fee the whole subscription fee will be credited to your account.</p>-->
<!--                            <p>The variable fee does not apply for your own paid campaigns.</p>-->
<!--                        </div>&lt;!&ndash; /.rt-info-box-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.rt-info-box &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.rt-pt-body &ndash;&gt;-->
<!--                <div class="rt-pt-btn">-->
<!--                    <a class="elementor-button elementor-size-md" href="/apply-for-pro-plan"  title="Riterz Pro Package">Select</a>-->
<!--                    <p>&euro;450.- if paid annually in advance, &euro;490.- if paid monthly. Cancel at any time</p>-->
<!--                </div>&lt;!&ndash; /.rt-pt-btn &ndash;&gt;-->
<!--            </div>&lt;!&ndash; /.rt-price-table &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.rt-price-table-frame &ndash;&gt;-->
<!--        <div class="rt-price-table-frame">-->
<!--            <div class="rt-price-table">-->
<!--                <header class="rt-pt">-->
<!--                    <h2>Enterprise</h2>-->
<!--                </header>-->
<!--                <div class="rt-pt-body">-->
<!--                    <p>For large scale solutions contact us for a bespoke package.</p>-->
<!--                </div>&lt;!&ndash; /.rt-pt-body &ndash;&gt;-->
<!--                <div class="rt-pt-btn">-->
<!--                    <a class="elementor-button elementor-size-md" href="/apply-for-enterprise-plan" title="Riterz Enterprise Package">Select</a>-->
<!--                </div>&lt;!&ndash; /.rt-pt-btn &ndash;&gt;-->
<!--            </div>&lt;!&ndash; /.rt-price-table &ndash;&gt;-->
<!--        </div>&lt;!&ndash; /.rt-price-table-frame &ndash;&gt;-->
<!--    </div>&lt;!&ndash; /.rt-price-tables-wrap &ndash;&gt;-->
<!--    &lt;!&ndash; /Rtierz WP site price tables &ndash;&gt;-->





    <section class="nv-section guttered" style="background-color: #f9f9f9;">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2 style="text-align: center;">Leaderboard</h2>
                        <div class="nv-scroll-box">
                            <table class="nv-leaderboard">
                                <tr>
                                    <th>RANK</th>
                                    <th>NAME</th>
                                    <th>BADGES EARNED</th>
                                    <th>LAST EARNED ON</th>
                                    <th>Badges</th>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">1.</td>
                                    <td data-cell-label="NAME">dr. Sandra G.M</td>
                                    <td data-cell-label="BADGES EARNED">15</td>
                                    <td data-cell-label="LAST EARNED ON">22.07.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">2.</td>
                                    <td data-cell-label="NAME">Albert W.</td>
                                    <td data-cell-label="BADGES EARNED">12</td>
                                    <td data-cell-label="LAST EARNED ON">20.07.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">3.</td>
                                    <td data-cell-label="NAME">prof. Jonathan Q.</td>
                                    <td data-cell-label="BADGES EARNED">10</td>
                                    <td data-cell-label="LAST EARNED ON">07.08.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-rank" data-cell-label="RANK">4.</td>
                                    <td data-cell-label="NAME">dr. Leonard P.</td>
                                    <td data-cell-label="BADGES EARNED">7</td>
                                    <td data-cell-label="LAST EARNED ON">02.08.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="lb-label" colspan="5">Your rank</td>
                                </tr>
                                <tr class="lb-yr">
                                    <td class="lb-rank" data-cell-label="RANK">103.</td>
                                    <td data-cell-label="NAME">dr. Leonard P.</td>
                                    <td data-cell-label="BADGES EARNED">7</td>
                                    <td data-cell-label="LAST EARNED ON">02.08.2022</td>
                                    <td class="lb-badges" data-cell-label="Badges">
                                        <div class="lb-badges-box"> <!-- < order inverted -->
                                            <img src="../../assets/badge-attendance-bronze.svg" alt="Bronze Badge">
                                            <img src="../../assets/badge-attendance-silver.svg" alt="Silver Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                            <img src="../../assets/badge-attendance-gold.svg" alt="Gold Badge">
                                        </div><!-- /.lb-badges-box -->
                                    </td>
                                </tr>
                            </table><!-- /.nv-leaderboard -->
                        </div>
                        <!-- /.nv-scroll-box -->
                    </div><!-- /nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /nv-row -->
        </div><!-- /nv-port -->
    </section>

    <section class="nv-section guttered" style="background-color: #fff;">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-table-responsive">
                        <table class="nv-quasi-program-table">
                            <tbody>
                            <tr>
                                <th class="day" colspan="6">Wednesday August 31<sup>st</sup></th>
                            </tr>

                            <tr>
                                <th>
                                    Start
                                </th>
                                <th>
                                    End
                                </th>
                                <th>
                                    Grand Klimt Hall 1
                                </th>
                                <th>
                                    Grand Klimt Hall 2
                                </th>
                                <th>
                                    Grand Klimt Hall 3
                                </th>
                                <th>
                                    Park Suite 6
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    09:00
                                </td>
                                <td class="time-span">
                                    11:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86895" title="Workshop: Your Safe Move to the Cloud - How to transform a Windows solution into a full-blown web application.">Workshop: Your Safe Move to the Cloud - How to transform a Windows solution into a full-blown web application.</a></h4>
                                    <p>Klaus Gudat &amp; Stefan Bolte, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86896" title="Workshop: Tips and Tricks for Progress Application Server for OpenEdge">Workshop: Tips and Tricks for Progress Application Server for OpenEdge</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86897" title="Workshop: OpenEdge batch processing in a Docker Container">Workshop: OpenEdge batch processing in a Docker Container</a></h4>
                                    <p>Cameron Wright, <span>Progress Software</span></p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    11:45
                                </td>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86899" title="What's New In ProTop 2022">What's New In ProTop 2022</a></h4>
                                    <p>Tom Bascom, <span>WSS</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="A minimal viable pipeline setup for Docker PASOE image delivery">A minimal viable pipeline setup for Docker PASOE image delivery</a></h4>
                                    <p>Daniel van Doorn, <span>Consultingwerk</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86900" title="Microservices Architecture The importance of doing it right">Microservices Architecture The importance of doing it right</a></h4>
                                    <p>Coenraad Vermaak, <span>Dimensiondata</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86916" title="Web, Mobile, App, Exe, ... all with one Environment">Web, Mobile, App, Exe, ... all with one Environment</a></h4>
                                    <p>Robert Prediger, <span>Web4biz</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td colspan="4">
                                    Lunch Break
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="time-span">
                                    14:15
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86898" title="Non-Uniform Memory Architecture">Non-Uniform Memory Architecture</a></h4>
                                    <p>Mike Furgal, <span>Progress OpenEdge Expert</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86903" title="What is my PASOE doing all day?">What is my PASOE doing all day?</a></h4>
                                    <p>Mike Fechner, <span>Consultingwerk</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86904" title="In pursuit of Big Data it all starts with CDC">In pursuit of Big Data it all starts with CDC</a></h4>
                                    <p>Demetrius Ganesh, <span>>MIP</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86905" title="Index and search for everything on your database">Index and search for everything on your database</a></h4>
                                    <p>Claudiu Socaciu, <span>Yonder</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    14:30
                                </td>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td colspan="4">
                                    Opening General Session
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td class="time-span">
                                    16:30
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Expo-Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:30
                                </td>
                                <td class="time-span">
                                    17:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86906" title="OpenEdge Roadmap">OpenEdge Roadmap</a></h4>
                                    <p>Rob Straight, <span>Progress Software</span></p>
                                    <span class="duration">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86908" title="OpenEdge DB and Continuous Operations">OpenEdge DB and Continuous Operations</a></h4>
                                    <p>Jim Clark, <span>Progress Software</span></p>
                                    <span class="duration">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86909" title="Commercial Session: Reporting against all of your Progress data sources easily and quickly">Commercial Session: Reporting against all of your Progress data sources easily and quickly</a></h4>
                                    <p>Dave Darby, <span>Cyberscience</span></p>
                                    60 minutes
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86925" title="Commercial Session: Build.One - the first end-to-end DEV platform for large-scale enterprise web-applications">Commercial Session: Build.One - the first end-to-end DEV platform for large-scale enterprise web-applications</a></h4>
                                    <p>Mike Liewehr, <span>BuildOne</span></p>
                                    <span class="duration">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    17:45
                                </td>
                                <td class="time-span">
                                    18:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86910" title="Benchmarking Bonanza - Cloud Edition!">Benchmarking Bonanza - Cloud Edition!</a></h4>
                                    <p>Paul Koufalis &amp; Adam Backman, <span>WSS</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86911" title="Generic collections in ABL">Generic collections in ABL</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86912" title="Updates and Improvements to OAuth2 and SAML in PAS for OpenEdge">Updates and Improvements to OAuth2 and SAML in PAS for OpenEdge</a></h4>
                                    <p>Mike Banks, <span>Progress Software</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86913" title="What s App Business Keeping your data out of third-party channels, OMNI Channel and GDPR compliance">What s App Business Keeping your data out of third-party channels, OMNI Channel and GDPR compliance</a></h4>
                                    <p>Chris Koster (and Noreen Koster), <span>MIP</span></p>
                                    <span class="duration">45 minutes</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                     </div><!-- /.nv-table-responsive -->

                        <div class="nv-divider desk-large"></div>


                        <div class="nv-table-responsive">
                        <table class="nv-quasi-program-table">
                            <tbody>
                            <tr>
                                <th class="day" colspan="6">Thursday 1<sup>st</sup> of September</th>
                            </tr>
                            <tr>
                                <th class="time-span">
                                    Start
                                </th>
                                <th class="time-span">
                                    End
                                </th>
                                <th>
                                    Grand Klimt Hall 1
                                </th>
                                <th>
                                    Grand Klimt Hall 2
                                </th>
                                <th>
                                    Grand Klimt Hall 3
                                </th>
                                <th>
                                    Park Suite 6
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    09:00
                                </td>
                                <td class="time-span">
                                    11:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86914" title="Workshop: Structured Error-Handling">Workshop: Structured Error-Handling</a></h4>
                                    <p>Peter Judge &amp; Mike Fechner, <span>Consultingwerk</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86915" title="Workshop: OpenEdge batch processing in a Docker Container">Workshop: OpenEdge batch processing in a Docker Container</a></h4>
                                    <p>Cameron Wright, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86958" title="Workshop: OpenEdge DBA: Advanced Problem Solving">Workshop: OpenEdge DBA: Advanced Problem Solving
                                    <br/>
                                    What Every OpenEdge DBA Needs to Know</a></h4>
                                    <p>Paul Koufalis &amp; Tom Bascom, <span>White Star Software</span></p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    11:45
                                </td>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86919" title="Benefits of Upgrading to OpenEdge 12: A Database Perspective">Benefits of Upgrading to OpenEdge 12: A Database Perspective</a></h4>
                                    <p>Rob Fitzpatrick, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86918" title="1, 2, 3, 4 Counting records in ABL">1, 2, 3, 4 Counting records in ABL</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86917" title="Monitoring Progress Application Server for OpenEdge">Monitoring Progress Application Server for OpenEdge</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86901" title="How to use Classic Corticon and CorticonJS with OpenEdge">How to use Classic Corticon and CorticonJS with OpenEdge</a></h4>
                                    <p>Mike Liewehr, <span>BuildOne</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Lunch Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="time-span">
                                    14:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86924" title="What s new in the ABL">What s new in the ABL</a></h4>
                                    <p>Anil Kotha, Progress Software</p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86927" title="HAR HAR HAR: Tracing and debugging HTTP Client requests">HAR HAR HAR: Tracing and debugging HTTP Client requests</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86926" title="Commercial Session: You Will Be Modernized - Resistance Is Futile">Commercial Session: You Will Be Modernized - Resistance Is Futile</a></h4>
                                    <p>Roland de Pijper, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86907" title="Commercial Session: OpenEdge Modernization">Commercial Session: OpenEdge Modernization</a></h4>
                                    <p>Klaus Erichsen (IAP), Bernd Hellmann (IAP), Oliver Jung (proALPHA), <span>IAP</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    14:45
                                </td>
                                <td class="time-span">
                                    15:45
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86929" title="High Performance Database">High Performance Database</a></h4>
                                    <p>Libor Laubacher, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86948" title="OpenEdge ABL Application Memory Profiling">OpenEdge ABL Application Memory Profiling</a></h4>
                                    <p>Tim Sargent <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86928" title="Commercial Session: LoadMaster for PASOE Load Balancing, and More!">Commercial Session: LoadMaster for PASOE Load Balancing, and More!</a></h4>
                                    <p>Roben Droge, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86930" title="Commercial Session: SmartComponent Library the modernization framework for OpenEdge">Commercial Session: SmartComponent Library the modernization framework for OpenEdge</a></h4>
                                    <p>Mike Fechner, <span>Consultingwerk</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    15:45
                                </td>
                                <td class="time-span">
                                    16:15
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Expo-Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:15
                                </td>
                                <td class="time-span">
                                    17:00
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86920" title="Database Performance and Resilience Tips for 2022">Database Performance and Resilience Tips for 2022</a></h4>
                                    <p>Tom Bascom, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86921" title="VSCode for ABL Applications">VSCode for ABL Applications</a></h4>
                                    <p>Gilles Querret, <span>Riverside</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86922" title="How to install and use node">How to install and use node</a></h4>
                                    <p>Julian Lyndon-Smith, <span>BuildOne</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a href="https://conference.pugchallenge.eu/presentation/86923" title="An ERP is always a work in Progress : A 30-year retrospective on powering Diapason with Progress">An ERP is always a work in Progress : A 30-year retrospective on powering Diapason with Progress</a></h4>
                                    <p>Sylvain De Sousa Maxime Castera, <span>ISIA SAS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    17:15
                                </td>
                                <td class="time-span">
                                    17:45
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="5 Ways to CRASH Your Database">5 Ways to CRASH Your Database</a></h4>
                                    <p>Nectar Daloglou, <span>OmegaServe</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="JIRA Integration Using the OpenEdge HTTP Client">JIRA Integration Using the OpenEdge HTTP Client</a></h4>
                                    <p>Peter Judge, <span>Consultingwerk</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86934" title="Monitoring Progress OpenEdge Resources with Splunk">Monitoring Progress OpenEdge Resources with Splunk</a></h4>
                                    <p>Michal Dzmuran, <span>Freelance</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86931" title="Commercial Session: Peace of Mind for Your OpenEdge Application">Commercial Session: Peace of Mind for Your OpenEdge Application</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                    <span class="time">30 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    17:45
                                </td>
                                <td class="time-span">
                                    18:15
                                </td>
                                <td colspan="4" class="break-time">
                                    <h4>Expo Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    18:15
                                </td>
                                <td class="time-span">
                                    19:00
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86938" title="How to live in a 24 hour world">How to live in a 24 hour world</a></h4>
                                    <p>Adam Backman, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86949" title="Ensuring the success of your Progress App modernization">Ensuring the success of your Progress App modernization</a></h4>
                                    <p>Gabriel Lucaciu, <span>Yonder</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86936" title="ProBro: VSC extension for Progress DB browsing">ProBro: VSC extension for Progress DB browsing</a></h4>
                                    <p>Paulius Kuprevičius, <span>Baltic Amadeus</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86947" title="Commercial Session">Commercial Session</a></h4>
                                    <p>TBD, <span>JK Tech</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            </tbody>
                        </table>
                        </div><!-- /.nv-table-responsive -->

                        <div class="nv-divider desk-large"></div>

                        <div class="nv-table-responsive">
                        <table class="nv-quasi-program-table">
                            <tbody>
                            <tr>
                                <th class="day" colspan="6">Friday, 2<sup>nd</sup> of September</th>
                            </tr>
                            <tr>
                                <th>
                                    Start
                                </th>
                                <th>
                                    End
                                </th>
                                <th>
                                    Grand Klimt Hall 1
                                </th>
                                <th>
                                    Grand Klimt Hall 2
                                </th>
                                <th>
                                    Grand Klimt Hall 3
                                </th>
                                <th>
                                    Park Suite 6
                                </th>
                            </tr>
                            <tr>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    09:00
                                </td>
                                <td class="time-span">
                                    11:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86939" title="Workshop: OpenEdge DBA: Advanced Problem Solving">Workshop: OpenEdge DBA: Advanced Problem Solving
                                    <br/>
                                        What Every OpenEdge DBA Needs to Know</a></h4>
                                    <p>Paul Koufalis &amp; Tom Bascom, <span>White Star Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="Workshop: Supporting Progress Application Server for OpenEdge">Workshop: Supporting Progress Application Server for OpenEdge</a></h4>
                                    <p>Roy Ellis, <span>Progress Software</span></p>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86940" title="Workshop: Structured Error-Handling">Workshop: Structured Error-Handling</a></h4>
                                    <p>Peter Judge &amp; Mike Fechner, <span>Consultingwerk</span></p>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    11:45
                                </td>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86941" title="New Hardware Worse Performance">New Hardware Worse Performance</a></h4>
                                    <p>Nectar Daloglou, <span>OmegaServe</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86942" title="Using the Profiler to Find Performance Problems in Production">Using the Profiler to Find Performance Problems in Production</a></h4>
                                    <p>Tom Bascom, <span>WSS</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86943" title="OpenEdge OOP and Unit testing. The hidden story?">OpenEdge OOP and Unit testing. The hidden story?</a></h4>
                                    <p>Martyn Kemp, <span>Consultingwerk</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86944" title="Bridging the Gap - OpenEdge with MongoDB">Bridging the Gap - OpenEdge with MongoDB</a></h4>
                                    <p>Allar Allas, <span>Inspirators</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    12:30
                                </td>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="break-time" colspan="4">
                                    <h4>Lunch Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    13:30
                                </td>
                                <td class="time-span">
                                    14:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86945" title="Record Format and Before Image Notes">Record Format and Before Image Notes</a></h4>
                                    <p>Gus Bjorklund , <span>The Parmington Foundation</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/87024" title="What's New In OpenEdge RDBMS">What's New In OpenEdge RDBMS</a></h4>
                                    <p>Jim Clark, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86935" title="Is over-engineering a threat in your project?">Is over-engineering a threat in your project?</a></h4>
                                    <p>Mihai Neagoe, <span>Wayfare</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="ProTop Commercial Session">ProTop Commercial Session</a></h4>
                                    <p>Paul Koufalis, <span>WSS</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    14:45
                                </td>
                                <td class="time-span">
                                    15:30
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86952" title="A Modern OpenEdge Management Console">A Modern OpenEdge Management Console</a></h4>
                                    <p>Tushar Dhaka, <span>Progress Software</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86951" title="Git Tipps and Tricks - or how to avoid losing your hair (or the days work)">Git Tipps and Tricks - or how to avoid losing your hair (or the days work)</a></h4>
                                    <p>Gilles Querret, <span>Riverside</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86950" title="Your modernization project is over, what now?">Your modernization project is over, what now?</a></h4>
                                    <p>Coenraad Vermaak, <span>Dimensiondata</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86937" title="Requirements. Can t progress without them.">Requirements. Can t progress without them.</a></h4>
                                    <p>Claudia Deacu , <span>Wayfare</span></p>
                                    <span class="time">45 minutes</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    15:30
                                </td>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td class="break-time" colspan="4">
                                    <h4>Expo-Break</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="time-span">
                                    16:00
                                </td>
                                <td class="time-span">
                                    17:00
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86956" title="The Checkpoint">The Checkpoint</a></h4>
                                    <p>Mike Furgal, <span>Progress OpenEdge Expert</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86955" title="ABL Code and Memory Leaks">ABL Code and Memory Leaks</a></h4>
                                    <p>Mike Fechner, <span>Consultingwerk</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href="https://conference.pugchallenge.eu/presentation/86954" title="OpenEdge 12.5 Kafka producer and consumer">OpenEdge 12.5 Kafka producer and consumer</a></h4>
                                    <p>Mike Banks &amp; Cameron Wright, <span>Progress Software</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                                <td>
                                    <h4><a target="_blank" href title="ISCorp Commercial Session">ISCorp Commercial Session</a></h4>
                                    <p>Mark Holton, <span>ISCorp</span></p>
                                    <span class="time">60 minutes</span>
                                </td>
                            </tr>
                            <tr>
                            </tr>
                            </tbody>
                        </table>
                        </div><!-- /.nv-table-responsive -->


                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /row -->
        </div><!-- /nv-port -->
    </section>

    <section class="nv-section guttered pug-countries">
        <div class="nv-port">
            <div class="nv-row large-spaced gap-30 propagate">
                <div class="nv-column desk-6 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-upload-block">
                            <label class="ub-img-port">
                                <input type="file" name="">
                                <span class="nv-ub-image" style="background-image: url('../../assets/badge-attendance-bronze.svg');"></span><!-- /.nv-ub-image --> <!-- < remove style attribute for initial state -->
                            </label>
                            <div class="ub-adjument">
                                <p class="ub-control">
                                    <span><a href>Upload new file</a></span>
                                    <span><a href>Remove file</a></span>
                                </p><!-- /.ub-control -->
                            </div><!-- /.ub-adjument -->
                        </div><!-- /.nv-upload-block -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /row -->
        </div><!-- /nv-port -->
    </section>

</div><!-- /.wrap -->
