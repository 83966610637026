<div class="nv-documentation nv-page-wrap">
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-10 flow-center large-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>Riterz Button Element</h2>
                        <p><b>Selector: nv-button</b></p>
                        <p><b>Attributes:</b> disabled, loading, icon, size, text, color</p>
                        <ul>
                            <li><b>Attribute size:</b> [ small, medium, large ]</li>
                            <li><b>Attribute color:</b>  [ neutral, primary, primaryDark, primaryLight, secondary, secondaryDark, secondaryLight, tertiary, tertiaryDark, quaternary, alert, warning, success, error ]</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom propagate">
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button color="primary" size="small">Submit</nv-button>
                        <br><br>
                        <nv-button color="primary" size="medium" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button color="primary" size="large">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="secondary">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="secondary" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="secondary">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="primaryDark">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="primaryDark" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="primaryDark">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="secondaryDark">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" icon="&#xe90c;" color="secondaryDark">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="secondaryDark">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="neutral" loading>Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="neutral" icon="&#xe90c;" loading>Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="neutral" loading>Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" disabled>Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" icon="&#xe90c;" disabled>Submit</nv-button>
                        <br><br>
                        <nv-button size="large" disabled>Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="white">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="white" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="white">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="tertiary">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="tertiary" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="tertiary">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="tertiaryDark">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="tertiaryDark" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="tertiaryDark">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="quaternary">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="quaternary" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="quaternary">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="warning">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="warning" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="warning">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="success">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="success" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="success">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="error">Submit</nv-button>
                        <br><br>
                        <nv-button size="medium" color="error" icon="&#xe90c;">Submit</nv-button>
                        <br><br>
                        <nv-button size="large" color="error">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button color="neutral" radius="yes" size="small">Submit</nv-button>
                        <br><br>
                        <nv-button color="neutral" radius="yes" onlyIcon="yes" size="medium" icon="&#xe90c;"></nv-button>
                        <br><br>
                        <nv-button color="neutral" radius="yes" size="large">Submit</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="tertiary" class="txt">Text type</nv-button>
                        <br><br>
                        <nv-button size="medium" color="tertiary" class="txt">Text type</nv-button>
                        <br><br>
                        <nv-button size="large" color="tertiary" class="txt">Text type</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
                <div class="nv-column desk-2 tab-4 phab-6 phone-12">
                    <div class="nv-column-content">
                        <nv-button size="small" color="quaternary" class="txt">Text type</nv-button>
                        <br><br>
                        <nv-button size="medium" color="quaternary" class="txt">Text type</nv-button>
                        <br><br>
                        <nv-button size="large" color="quaternary" class="txt">Text type</nv-button>
                    </div><!-- /.nv-column-content -->
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 flow-center large-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>Riterz Table</h2>
                        <p><b>Default table</b></p>
                    </div>
                </div>
                <div class="nv-column desk-12">
                     <div class="nv-column-content">
                         <div class="rtz-resp-table-wrap">
                             <table class="rtz-table-mp">
                                 <tr>
                                     <th>Table header</th>
                                     <th>Table header</th>
                                     <th>Table header</th>
                                     <th>Table header</th>
                                 </tr>
                                 <tr>
                                     <td>Table Cell</td>
                                     <td><span title="Table cell">Table cell</span></td>
                                     <td><span title="Table cell">Table cell</span></td>
                                     <td><span title="Table cell">Table cell</span></td>
                                 </tr>
                                 <tr>
                                     <td>Table Cell</td>
                                     <td><span title="Table cell">Table cell</span></td>
                                     <td><span title="Table cell">Table cell</span></td>
                                     <td><span title="Table cell">Table cell</span></td>
                                 </tr>
                             </table><!-- /.rtz-table-mp -->
                         </div><!-- /rtz-resp-table-wrap -->
                     </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div>
        </div>
    </div><!-- /nv-section -->
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 flow-center large-spaced-top">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h2>Riterz Input Element/ Riterz Textarea</h2>
                        <p><b>Selector: nv-input</b></p>
                        <ul>
                            <li><p><b>Attributes:</b> [ type, label, info, max, min, title, disabled, readonly, required, formControlName, rows, cols ]</p></li>
                            <li><p><b>Attribute type:</b>[ text, number, email, password, textarea ]</p></li>
                            <li><p><b>Attribute info:</b>[ write information about input field ]</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="nv-row gap-24 spaced-top large-spaced-bottom">
                    <div class="nv-column-content">
                        <form [formGroup]="testForm" class="nv-row gap-30 propagate spaced">
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            label="Default input:"
                                            formControlName="input1" >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="text"
                                            label="Input readOnly:"
                                            formControlName="input2"
                                            readOnly >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="text"
                                            label="Input required:"
                                            formControlName="input3"
                                            required >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="email"
                                            label="Input type email:"
                                            formControlName="input4"
                                            info="Input email with more then one error" >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="number"
                                            label="Input type number:"
                                            formControlName="input5" >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="text"
                                            label="Input with info:"
                                            formControlName="input6"
                                            info="Input with info" >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="textarea"
                                            label="Riterz input label 3:"
                                            formControlName="textarea"
                                            cols="20"
                                            rows="5" >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-6 phab-12">
                                <div class="nv-column-content">
                                    <nv-input
                                            type="text"
                                            label="Input disabled:"
                                            disabled >
                                    </nv-input>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </form>
                    </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Checkbox Element</h2>
                        <p><b>Selector: nv-checkbox</b></p>
                        <p><b>Attributes:</b>[ label, formControlName, name, checked, disabled, value ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                            <p><nv-checkbox label="Checkbox" name="checkbox 1" (change)="checkboxTest($event)"></nv-checkbox></p>
                            <p><nv-checkbox label="Checkbox checked" checked></nv-checkbox></p>
                            <p><nv-checkbox label="Checkbox disabled" disabled></nv-checkbox></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Radio Element</h2>
                        <p><b>Selector: nv-radio</b></p>
                        <p><b>Attributes:</b>[ label, formControlName, name, checked, disabled, value ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <form [formGroup]="radioTest">
                            <nv-radio label="Radio1" [value]="'radio1'" formControlName="radio" (change)="testRadio()"></nv-radio>
                            <nv-radio label="Radio2" value="radio2" formControlName="radio" (change)="testRadio()"></nv-radio>
                            <nv-radio label="Radio3" value="radio3" formControlName="radio" (change)="testRadio()"></nv-radio>

                            <nv-radio label="Radio1" [value]="'radio1'" formControlName="radio2" (change)="testRadio()"></nv-radio>
                            <nv-radio label="Radio2" value="radio2" formControlName="radio2" (change)="testRadio()"></nv-radio>
                            <nv-radio label="Radio3" value="radio3" formControlName="radio2" (change)="testRadio()"></nv-radio>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Tabs Element</h2>
                        <p><b>Selector: nv-tabs > nv-tab</b></p>
                        <p><b>Attributes (nv-tab):</b>[ title ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-tabs>
                            <nv-tab title="Breza"></nv-tab>
                            <nv-tab title="Hrast">
                                <p>No scraps hit the floor win-win-win and we need to start advertising on social media enough to wash your face and please advise soonest nor beef up data-point. It just needs more cowbell regroup eat our own dog food.</p>
                                <p>Powerpoint Bunny golden goose. Performance review market-facing, nor window of opportunity synergestic actionables not a hill to die on. You gotta smoke test your hypothesis crank this out yet customer centric i don't want to drain the whole swamp, i just want to shoot some alligators yet fire up your browser, for game-plan.</p>
                            </nv-tab>
                            <nv-tab title="Bukva">
                                <p>Big picture big boy pants we need to dialog around your choice of work attire. Can I just chime in on that one what's our go to market strategy?, thinking outside the box, nor we need to socialize the comms with the wider stakeholder community loop back. Crank this out. It's a simple lift and shift job the horse is out of the barn we need to have a Come to Jesus meeting with Phil about his attitude overcome key issues to meet key milestones, or we need to dialog around your choice of work attire, for drop-dead date, but prioritize these line items. Minimize backwards overflow products need full resourcing and support from a cross-functional team in order to be built, maintained, and evolved put it on the parking lot. Race without a finish line anti-pattern.</p>
                                <p>Five-year strategic plan strategic high-level 30,000 ft view, but we need to future-proof this, nor that jerk from finance really threw me under the bus window of opportunity, so overcome key issues to meet key milestones.</p>
                            </nv-tab>
                            <nv-tab title="Javor"></nv-tab>
                        </nv-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Modals</h2>
                        <p><b>Opening with modal.service</b></p>
                        <p>modalService.<i>defaultModal</i> ( config )</p>
                        <p>modalService.<i>alert</i> ( config ) </p>
                        <p>modalService.<i>confirm</i> ( config )</p>
                        <p>config: ( title, body, buttons[], size )</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column">
                        <nv-button class="nv-column-content" (click)="openDefaultModal()">Open default modal</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openAlertModal()">Open alert modal</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openAlertModalWithoutTitle()">Open alert modal without title</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openConfirmModal()">Open confirm modal</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openErrorModal()">Open error modal</nv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Toast</h2>
                        <p><b>Opening with toast.service</b></p>
                        <p>toastService.<i>openToastSuccess</i> (message, config)</p>
                        <p>toastService.<i>openToastError</i> (message, config)</p>
                        <p>toastService.<i>openToastWarning</i> (message, config)</p>
                        <p>toastService.<i>openToastInfo</i> (message, config)</p>
                        <p>config: timeOut, disableTimeOut, positionClass, closeButton</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column">
                        <nv-button class="nv-column-content" (click)="openToastSuccess()">Open toast success</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openToastError()">Open toast error</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openToastWarning()">Open toast warning</nv-button>
                        <br>
                        <nv-button class="nv-column-content" (click)="openToastInfo()">Open toast info</nv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz DatePicker</h2>
                        <p><b>Selector: nv-datepicker</b></p>
                        <p><b>Attributes:</b>[ type, label, startView, formControlName, min, max, readOnly, disabled, required ]</p>
                        <p><b>Attribute type:</b>[ both, calendar, timer ]</p>
                        <p><b>Attribute startView:</b>[ month, year, multi-years ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column">
                        <nv-datepicker class="nv-column-content" label="Date/Time picker" ></nv-datepicker>
                        <br>
                        <nv-datepicker class="nv-column-content" label="Date picker" type="calendar" ></nv-datepicker>
                        <br>
                        <nv-datepicker class="nv-column-content" label="Time picker" type="timer" ></nv-datepicker>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Select</h2>
                        <p><b>Selector: nv-select</b></p>
                        <p><b>Attributes:</b>[ options, selectedOptions, value. showSearch, multi, disabled, readOnly, addButtonText, addButtonShowValue, emptyStateText, notFoundText ]</p>
                        <p><b>Outputs:</b>[ addButtonClick, loadData, loadMore, searched ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-select
                                [options]="dropTestNew"
                                label="Default select"
                                showSearch
                                (searched)="dropTestSearch($event)"
                                [addButtonText]
                                addButtonShowValue
                                (addButtonClick)="dropAddTest($event)"
                                [notFoundText]="'Not Found'"
                                [emptyStateText]="'Drop is empty'">
                        </nv-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Text Editor</h2>
                        <p><b>Selector: nv-text-editor</b></p>
                        <p><b>Attributes:</b>[ value, disabled, required, label, formControlName, config ]</p>
                        <p><b>Config:</b>[ placeholder, toolbar[], images(toolbar[], styles[]) ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column">
                        <nv-text-editor
                        required
                        ></nv-text-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Pagination</h2>
                        <p><b>Selector: nv-pagination</b></p>
                        <p><b>Attributes:</b>[type, config, pagesShow(default = 5), showPerPage, info ]</p>
                        <p><b>Config:</b>[ current_page, per_page, total, total_pages ]</p>
                        <p><b>Type:</b>[ default, thorough ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column">
                        <nv-pagination
                        [showPerPage] = "true"
                        class="nv-column-content spaced-bottom"
                        [config]="{
                        per_page: 5,
                        current_page: 2,
                        total: 50,
                        total_pages: 5
                        }"
                        info ></nv-pagination>
                        <br>
                        <nv-pagination
                        class="nv-column-content spaced-bottom"
                        type="thorough"
                        [config]="{
                        per_page: 5,
                        current_page: 1,
                        total: 50,
                        total_pages: 10
                        }"></nv-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Loading</h2>
                        <p><b>Start with loading.service</b></p>
                        <p>loadingService.<i>start</i> (instance)</p>
                        <p>loadingService.<i>stop</i> (instance)</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Progress Bar</h2>
                        <p><b>Selector: nv-progress-bar</b></p>
                        <p><b>Attributes:</b>[ active(boolean) , progress ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-progress-bar
                                [active] = "progressBarShow"
                                [progress]="progress"></nv-progress-bar>
                        <nv-button (click)="startProgressBar()" *ngIf="progress == 0">Start Progress Bar</nv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Tooltip</h2>
                        <p><b>Selector: nv-tooltip</b></p>
                        <p><b>Attributes:</b>[ text, position ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <nv-tooltip
                            text="Riterz tooltip text"
                            position="right">
                        <p>Riterz tooltip INNER</p>
                    </nv-tooltip>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Color Picker</h2>
                        <p><b>Selector: nv-colorpicker</b></p>
                        <p><b>Attributes:</b>[ disabled, required, value, confirmButton, confirmButtonText, cancelButton, cancelButtonText, formControlName, label ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                  <nv-colorpicker
                      label="Color"
                      [(ngModel)]="colorTest"
                      (change)="colorPicker($event)"
                  ></nv-colorpicker>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Carousel</h2>
                        <p><b>Selector: nv-carousel</b></p>
                        <p><b>Attributes:</b>[ content: boolean, image: string <br> navigation: boolean, autoplay: (delay: number), pagination: boolean, loop: boolean, navigation: boolean ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-slides>
                            <nv-slide *ngFor="let slide of carouselSlides" [image]="slide.imageUrl"></nv-slide>
                        </nv-slides>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Switch</h2>
                        <p><b>Selector: nv-switch</b></p>
                        <p><b>Attributes:</b>[ disabled, checked, value, formControlName, name, change(event) ]</p>
                    </div>
                </div>
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <nv-switch></nv-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2 style="border-bottom: solid 2px #1b1c20">Riterz Directives</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Infinite Scroll</h2>
                        <p><b>Selector:</b> [nvInfiniteScroll]</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="nv-section guttered">
        <div class="nv-port">
            <div class="nv-row gap-24 large-spaced-top large-spaced-bottom">
                <div class="nv-column desk-6">
                    <div class="nv-column-content">
                        <h2>Riterz Off Click</h2>
                        <p><b>Selector:</b> [nvOffClick]</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
