import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { CookieService } from 'riterz-core';
import { environment } from '../../environments/environment';

export const loggedInGuard: CanActivateFn = (
  route, 
  state,
  router: Router = inject(Router),
  cookieService: CookieService = inject(CookieService),
) => {
  const canActivate = !!cookieService.get(environment.riterz_access_token_name);

  if (canActivate) {
    return true;
  } else {
    console.log('authGuard: Unauthorized');
    return false;
  }
};
