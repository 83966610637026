import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class OrganizationCampaignAdService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationCampaignAds(organizationId: string, campaignId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/campaigns/${campaignId}/ads`, params);
  }

  getOrganizationCampaignAd(organizationId: string, campaignId: string, adId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/campaigns/${campaignId}/ads/${adId}`, params);
  }

  updateOrganizationCampaignAd(organizationId: string, campaignId: string, adId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/campaigns/${campaignId}/ads/${adId}`, params);
  }

  createOrganizationCampaignAd(organizationId: string, campaignId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/campaigns/${campaignId}/ads`, params);
  }

  deleteOrganizationCampaignAd(organizationId: string, campaignId: string, adId: string) {
    return this.apiService.delete(`organizations/${organizationId}/campaigns/${campaignId}/ads/${adId}`);
  }

}
