export interface Tutorial {
  id: string;
  title: string;
  content: string;
  featured_image_url: string;
  featured_video_url: string;
  link: string;
  tutorial_position_id: string;
  series: string;
  published: boolean;

  seen?: boolean;
}