import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { RiterzCoreConfiguration } from '../riterz-core.configuration';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly configuration: RiterzCoreConfiguration,
        private router: Router,
        private userService: UserService,
    ) { }

    addAuthHeader(request: HttpRequest<any>) {
        const token = localStorage.getItem(this.configuration.accessTokenName);
        if (token) {
            return request.clone({
                setHeaders: {
                    "Authorization": `Bearer ${token}`
                }
            });
        }
        return request;
    }

    private handleResponseError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Only for Riterz API
        // TODO Use env variable to determine if it's Riterz API
        if (request.url.includes('riterz.io')) {
            // Invalid token error and not on login where is expected
            if (error.status === 401 && !request.url.includes('users/login')) {
                return this.userService
                    .refreshToken()
                    .pipe(
                        switchMap(() => {
                            request = this.addAuthHeader(request);
                            return next.handle(request);
                        }),
                        catchError((e) => {
                            if (e.status !== 401) {
                                return this.handleResponseError(e, request, next);
                            } else {
                                return this.userService.logout();
                            }
                        })
                    );
            }
        }

        return throwError(error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(request)
            .pipe(catchError(error => this.handleResponseError(error, request, next)));
    }
}
