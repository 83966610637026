import { Routes } from '@angular/router';
import { organizationResolver } from './resolvers/organization.resolver';
import { reportResolver } from './resolvers/report.resolver';
import { loggedInGuard } from './guards/logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: ({ queryParams }) => {
      if (queryParams['refresh_token'] && queryParams['access_token']) {
        return `/search?refresh_token=${queryParams['refresh_token']}&access_token=${queryParams['access_token']}`;
      } 
      if (queryParams['verify_email']) {
        return `/search?verify_email=${queryParams['verify_email']}`;
      } 
      if (queryParams['login_code']) {
        return `/search?login_code=${queryParams['login_code']}`;
      } 
      return `/search`;
    },
    pathMatch: 'full'
  },
  {
    path: 'auth/login/with-code/:code',
    redirectTo: ({ params }) => {
      if (params['code']) {
        return `/search?login_code=${params['code']}`;
      }
      return `/search`;
    }
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/search/search.component').then(m => m.SearchComponent),
  },
  {
    path: 'search/:type',
    loadComponent: () => import('./pages/search/search.component').then(m => m.SearchComponent)
  },
  {
    path: 'sponsor/:organizationId',
    loadComponent: () => import('./pages/profile-advertiser/profile-advertiser.component').then(m => m.ProfileAdvertiserComponent),
    resolve: {
      organization: organizationResolver
    },
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full'
      },
      {
        path: 'summary',
        loadComponent: () => import('./pages/profile-advertiser/profile-advertiser-summary/profile-advertiser-summary.component').then(m => m.ProfileAdvertiserSummaryComponent)
      },
    ]
  },
  {
    path: 'profile/:organizationId',
    loadComponent: () => import('./pages/profile-network/profile-network.component').then(m => m.ProfileNetworkComponent),
    resolve: {
      organization: organizationResolver
    },
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full'
      },
      {
        path: 'summary',
        loadComponent: () => import('./pages/profile-network/profile-network-summary/profile-network-summary.component').then(m => m.ProfileNetworkSummaryComponent)
      },      
      {
        path: 'reports',
        canActivate: [ loggedInGuard ],
        resolve: {
          report: reportResolver
        },
        children: [
          {
            path: ':channel',
            loadComponent: () => import('./pages/profile-network/profile-network-report/profile-network-report.component').then(m => m.ProfileNetworkReportComponent)
          },          
        ]
      },
      {
        path: 'calculator',
        loadComponent: () => import('./pages/profile-network/profile-network-calculator/profile-network-calculator.component').then(m => m.ProfileNetworkCalculatorComponent),
        canActivate: [ loggedInGuard ],
      },
      {
        path: 'events',
        loadComponent: () => import('./pages/profile-network/profile-network-events/profile-network-events.component').then(m => m.ProfileNetworkEventsComponent),
        canActivate: [ loggedInGuard ],
      },
      {
        path: 'sponsorship',
        loadComponent: () => import('./pages/profile-network/profile-network-sponsorship/profile-network-sponsorship.component').then(m => m.ProfileNetworkSponsorshipComponent),
        canActivate: [ loggedInGuard ],
      },
    ]
  },
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.component').then(m => m.AccountComponent),
    canActivate: [ loggedInGuard ],
  },
  {
    path: 'config/:organizationId',
    loadComponent: () => import('./pages/config/config.component').then(m => m.ConfigComponent),
    canActivate: [ loggedInGuard ],
    resolve: {
      organization: organizationResolver
    },
    children: [
      {
        path: '',
        redirectTo: 'settings',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./pages/config/dashboard/dashboard.component').then(m => m.DashboardComponent)
      },
      {
        path: 'settings',
        loadComponent: () => import('./pages/config/settings/settings.component').then(m => m.SettingsComponent)
      },
      {
        path: 'brochures',
        loadComponent: () => import('./pages/config/brochure-list/brochure-list.component').then(m => m.BrochureListComponent)
      },
      {
        path: 'brochure/:brochureId',
        loadComponent: () => import('./pages/config/brochure-details/brochure-details.component').then(m => m.BrochureDetailsComponent)
      },
      {
        path: 'brochure',
        loadComponent: () => import('./pages/config/brochure-details/brochure-details.component').then(m => m.BrochureDetailsComponent)
      },
      {
        path: 'events',
        loadComponent: () => import('./pages/config/event-list/event-list.component').then(m => m.EventListComponent)
      },
      {
        path: 'event/:eventId',
        loadComponent: () => import('./pages/config/event-details/event-details.component').then(m => m.EventDetailsComponent)
      },
      {
        path: 'event',
        loadComponent: () => import('./pages/config/event-details/event-details.component').then(m => m.EventDetailsComponent)
      },
      {
        path: 'providers',
        loadComponent: () => import('./pages/config/provider-list/provider-list.component').then(m => m.ProviderListComponent)
      },
      {
        path: 'channels',
        loadComponent: () => import('./pages/config/channel-list/channel-list.component').then(m => m.ChannelListComponent)
      },
      {
        path: 'channel/:channelId',
        loadComponent: () => import('./pages/config/channel-details/channel-details.component').then(m => m.ChannelDetailsComponent)
      },
      {
        path: 'channel',
        loadComponent: () => import('./pages/config/channel-details/channel-details.component').then(m => m.ChannelDetailsComponent)
      },
      {
        path: 'offers',
        loadComponent: () => import('./pages/config/offer-list/offer-list.component').then(m => m.OfferListComponent)
      },
      {
        path: 'offer/:offerId',
        loadComponent: () => import('./pages/config/offer-details/offer-details.component').then(m => m.OfferDetailsComponent)
      },
      {
        path: 'offer',
        loadComponent: () => import('./pages/config/offer-details/offer-details.component').then(m => m.OfferDetailsComponent)
      },
      {
        path: 'campaigns',
        loadComponent: () => import('./pages/config/campaign-list/campaign-list.component').then(m => m.CampaignListComponent)
      },
      {
        path: 'campaign/:campaignId',
        loadComponent: () => import('./pages/config/campaign-details/campaign-details.component').then(m => m.CampaignDetailsComponent)
      },
      {
        path: 'campaign',
        loadComponent: () => import('./pages/config/campaign-details/campaign-details.component').then(m => m.CampaignDetailsComponent)
      },
      {
        path: 'users',
        loadComponent: () => import('./pages/config/user-list/user-list.component').then(m => m.UserListComponent)
      },
      {
        path: 'users/:userId',
        loadComponent: () => import('./pages/config/user-details/user-details.component').then(m => m.UserDetailsComponent)
      },
    ]
  },
  {
    path: 'admin',
    loadComponent: () => import('./pages/admin/admin.component').then(m => m.AdminComponent),
    canActivate: [ loggedInGuard ],
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'users',
        loadComponent: () => import('./pages/admin/user-list/user-list.component').then(m => m.AdminUserListComponent)
      },
      {
        path: 'user/:userId',
        loadComponent: () => import('./pages/admin/user-details/user-details.component').then(m => m.AdminUserDetailsComponent)
      },
      {
        path: 'invitations',
        loadComponent: () => import('./pages/admin/invitation-list/invitation-list.component').then(m => m.AdminInvitationListComponent)
      },
      {
        path: 'invitation/:invitationId',
        loadComponent: () => import('./pages/admin/invitation-details/invitation-details.component').then(m => m.AdminInvitationDetailsComponent)
      },
      {
        path: 'organizations',
        loadComponent: () => import('./pages/admin/organization-list/organization-list.component').then(m => m.AdminOrganizationListComponent)
      },
      {
        path: 'organization/:organizationId',
        loadComponent: () => import('./pages/admin/organization-details/organization-details.component').then(m => m.AdminOrganizationDetailsComponent)
      },
    ]
  }

];
