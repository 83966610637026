import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-verified-email',
  standalone: true,
  imports: [
    ButtonModule,
  ],
  templateUrl: './verified-email.component.html',
})
export class VerifiedEmailComponent {

  constructor(
    private dialogRef: DynamicDialogRef,
  ) { }

  close() {
    this.dialogRef.close({ hasChanges: false });
  }
}
