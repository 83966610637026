import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const reportResolver: ResolveFn<any> = (
  route, 
  state,
  router: Router = inject(Router),
) => {
  const organization = route.parent?.data['organization'];
  const existingChannel = route.children[0]?.paramMap?.get('channel');

  if (existingChannel) {
    return;
  }

  if (organization?.data?.socialChannels && Object.keys(organization.data.socialChannels).length > 0) {
    const channels = Object.keys(organization.data.socialChannels);
    let channel = null;

    if (channels.includes('facebook')) {
      channel = 'facebook';
    } else if (channels.includes('instagram')) {
      channel = 'instagram';
    } else if (channels.includes('linkedin')) {
      channel = 'linkedin';
    }

    if (!channel) {
      router.navigate(['/profile/', organization.slug, 'summary']);
    } else {
      router.navigate(['/profile/', organization.slug, 'reports', channel]);
    }

  } else {
    router.navigate(['/profile/', organization.slug, 'summary']);
  }    
};
