import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class InvitationService {

  constructor(
    private apiService: ApiService
  ) { }

  getInvitationByToken(token: string, params: any = {}) {
    return this.apiService.get(`invitations/${token}`, params);
  }

  getInvitations(params: any = {}) {
    return this.apiService.get(`admin/invitations`, params);
  }

  getInvitation(invitationId: string, params: any = {}) {
    return this.apiService.get(`admin/invitations/${invitationId}`, params);
  }

  updateInvitation(invitationId: string, params: any = {}) {
    return this.apiService.put(`admin/invitations/${invitationId}`, params);
  }

  createInvitation(params: any = {}) {
    return this.apiService.post(`admin/invitations`, params);
  }

  deleteInvitation(invitationId: string) {
    return this.apiService.delete(`admin/invitations/${invitationId}`);
  }

  getOrganizationInvitations(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/invitations`, params);
  }

  getOrganizationInvitation(organizationId: string, invitationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/invitations/${invitationId}`, params);
  }

  updateOrganizationInvitation(organizationId: string, invitationId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/invitations/${invitationId}`, params);
  }

  createOrganizationInvitation(organizationId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/invitations`, params);
  }

  deleteOrganizationInvitation(organizationId: string, invitationId: string) {
    return this.apiService.delete(`organizations/${organizationId}/invitations/${invitationId}`);
  }

  resendOrganizationInvitation(organizationId: string, invitationId: string) {
    return this.apiService.put(`organizations/${organizationId}/invitations/${invitationId}/resend`);
  }

}
