import { UserRole } from "./user-role";

export interface Organization {
  id: string;
  type: string;
  name: string;
  contact_info: any;
  analytics?: any;
  status: string;
  is_demo: boolean;
  image_url: string;

  created_at: string;
  updated_at: string;
  deleted_at: string;

  navus_id: number;

  roles: UserRole[];
}