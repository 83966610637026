import { Component } from '@angular/core';

@Component({
    selector: 'app-registration23',
    templateUrl: './registration23.component.html'
})
export class Registration23Component {

    constructor() { }

}
