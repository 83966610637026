<div class="rt-frame">
  <header class="rt-header" #header>
    <div class="rtz-cf-logo-port">
      <figure>
        <a title="Riterz" [routerLink]="['/']">
          <img src="assets/riterz-logo.svg" alt="Riterz"/>
        </a>
      </figure>
    </div>

    <nav class="rtz-cf-header-menu">
      <ul>
        <li>
          <a 
            href="https://forms.riterz.com/t/m5qAM83uigus"
            target="_blank"
            title="Submit a Profile"
          >Submit your Profile</a>
        </li>
      </ul>
    </nav>

    @defer (on viewport(header)) {         
      @if (!loadingUser) {
        <div class="rtz-cf-user-settings">
          @if (currentUser) {
            @if (currentUser.organizations && currentUser.organizations.length > 0) {
              <p-avatar 
                class="rt-clickable"
                [style]="{'background-color': '#F15AB5', 'color': '#FFF'}"
                (click)="opOrganizationsNav.toggle($event)"
                [icon]="'pi pi-building'"
                size="large"
                shape="circle"
              />
              <p-popover #opOrganizationsNav appendTo="body">
                <nav class="rt-nav" style="width: 200px;">
                  <ul>
                    @for (organization of currentUser.organizations; track organization.id) {
                      <li>
                        <a class="rt-clickable" [routerLink]="['/config', organization.id]" (click)="opOrganizationsNav.hide()">
                          {{ organization.name }}
                        </a>
                      </li>
                    }
                  </ul>
                </nav>        
              </p-popover>
            }

            @if (currentUser.image_url) {
              <p-avatar 
                class="rt-clickable"
                (click)="opProfileNav.toggle($event)"
                [image]="currentUser.image_url"
                size="large"
                shape="circle"
              />
            } @else {
              <p-avatar 
                class="rt-clickable"
                [style]="{'background-color': '#200B39', 'color': '#FFF'}"
                (click)="opProfileNav.toggle($event)"
                [image]="currentUser.image_url"
                [label]="currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0)" 
                size="large"
                shape="circle"
              />
            }
            <p-popover 
              #opProfileNav 
              appendTo="body"
            >
              <nav class="rt-nav">
                <ul>
                  @if (isSuperAdmin) {
                    <li>
                      <a [routerLink]="['/admin']" (click)="opProfileNav.hide()">
                        Admin
                      </a>
                    </li>                    
                  }
                  <li>
                    <a [routerLink]="['/account']" (click)="opProfileNav.hide()">
                      Account
                    </a>
                  </li>
                  <li>
                    <a class="rt-clickable" (click)="logout(); opProfileNav.hide()">
                      Logout
                    </a>
                  </li>
                </ul>
              </nav>        
            </p-popover>
          } @else {
            <p-button
              label="Login"
              [text]="true"
              [rounded]="true"
              severity="secondary"
              (click)="login()"
            ></p-button>
            <p-button
              label="Register"
              [rounded]="true"
              severity="primary"
              (click)="registration()"
            ></p-button>
          }
        </div>      
      }
    }

  </header>
  <div class="rt-body">
    <main class="rtz-config-stage">
      <div class="rtz-config-stage-sleeve">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>

<p-toast 
  position="bottom-right"
  showTransitionOptions="200ms"
  showTransformOptions="translateY(100%)"
  hideTransitionOptions="200ms"
  hideTransformOptions="translateY(-100%)"
/>
<p-confirmDialog />