import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TutorialService {

  constructor(
    private apiService: ApiService
  ) { }

  getAdminTutorials(params: any = {}) {
    return this.apiService.get(`admin/tutorials`, params);
  }

  getTutorials(params: any = {}) {
    return this.apiService.get(`tutorials`, params);
  }

  getTutorial(tutorialId: string, params: any = {}) {
    return this.apiService.get(`admin/tutorials/${tutorialId}`, params);
  }

  updateTutorial(tutorialId: string, params: any = {}) {
    return this.apiService.put(`admin/tutorials/${tutorialId}`, params);
  }

  createTutorial(params: any = {}) {
    return this.apiService.post(`admin/tutorials`, params);
  }

  deleteTutorial(tutorialId: string) {
    return this.apiService.delete(`admin/tutorials/${tutorialId}`);
  }

  getTutorialPositions(params: any = {}) {
    return this.apiService.get(`admin/tutorial-positions`, params);
  }

  getTutorialPosition(tutorialPositionId: string, params: any = {}) {
    return this.apiService.get(`admin/tutorial-positions/${tutorialPositionId}`, params);
  }

  updateTutorialPosition(tutorialPositionId: string, params: any = {}) {
    return this.apiService.put(`admin/tutorial-positions/${tutorialPositionId}`, params);
  }

  createTutorialPosition(params: any = {}) {
    return this.apiService.post(`admin/tutorial-positions`, params);
  }

  deleteTutorialPosition(tutorialPositionId: string) {
    return this.apiService.delete(`admin/tutorial-positions/${tutorialPositionId}`);
  }

  seeTutorial(tutorialId: string, params: any = {}) {
    return this.apiService.put(`tutorials/${tutorialId}`, params);
  }

}
