import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class OrganizationCampaignService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationCampaigns(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/campaigns`, params);
  }

  getOrganizationCampaign(organizationId: string, campaignId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/campaigns/${campaignId}`, params);
  }

  updateOrganizationCampaign(organizationId: string, campaignId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/campaigns/${campaignId}`, params);
  }

  createOrganizationCampaign(organizationId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/campaigns`, params);
  }

  deleteOrganizationCampaign(organizationId: string, campaignId: string) {
    return this.apiService.delete(`organizations/${organizationId}/campaigns/${campaignId}`);
  }

}
