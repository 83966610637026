import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { StaticModule } from './static/static.module';
import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from './shared/shared.module';
import { PublicVotingModule } from './public-voting/public-voting.module';
import { ConfigModule } from './config/config.module';
import { NavusModalComponent } from './navus-ui/modal/default-modal/modal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WebsiteModule } from './website/website.module';
import { NavusAlertComponent } from './navus-ui/modal/alert/alert.component';
import { NavusConfirmComponent } from './navus-ui/modal/confirm/confirm.component';
import { NavusToastComponent } from './navus-ui/toast/toast.component';
import { ToastrModule } from 'ngx-toastr';
import { NavusUiModule } from './navus-ui/navus-ui.module';
import { NavusErrorComponent } from './navus-ui/modal/error/error.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpTokenInterceptor } from './navus-core/interceptiors/http-token.interceptor';
import { VersionCheckService } from './navus-core/services/version-check.service';
import { LivestreamingModule } from './livestreaming/livestreaming.module';
import { SpeakerStudioModule } from './speaker-studio/speaker-studio.module';
import { MainModule } from './main/main.module';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    MainModule,
    // ConfigModule,
    // WebsiteModule,
    StaticModule,
    // PublicVotingModule,
    // LivestreamingModule,
    // SpeakerStudioModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NavusUiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    VersionCheckService,
    HttpClientModule,
    NgbActiveModal
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
