import { Component } from '@angular/core';

@Component({
    selector: 'dashboard-page',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
    showSideNav: boolean = true;
    showSubNavigation: string = null;

    constructor() { }

    toggleSubNavigation(name: string) {
        this.showSubNavigation = this.showSubNavigation === name ? null : name;
    }
}
