import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { CookieService } from 'riterz-core';
import { environment } from '../../environments/environment';

export const loggedInGuard: CanActivateFn = (
  route, 
  state,
  router: Router = inject(Router),
  cookieService: CookieService = inject(CookieService),
) => {
  const canActivate = !!cookieService.get(environment.riterz_access_token_name);

  if (canActivate) {
    return true;
  } else {
    // If url has refresh_token and access_token, we assume it's a login redirect and we can return true
    // Not ideal
    if (route.queryParams['refresh_token'] && route.queryParams['access_token']) {
      return true;
    } else {
      router.navigate(['/'], { queryParams: { show_login: true, return_url: state.url } });
      return false;      
    }

  }
};
