import {
  Component,
  ElementRef,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  QueryList,
  SkipSelf,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nv-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NavusInputComponent),
      multi: true
    }
  ]
})

export class NavusInputComponent implements OnInit, ControlValueAccessor {
  @ViewChildren('input') input: QueryList<ElementRef>;

  @Input() value: any = null;
  @Input() autoFocus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() autoComplete: string = 'off';
  @Input() type: 'text' | 'number' | 'email' | 'password' | 'textarea' | 'card' = 'text';
  @Input() rows: string;
  @Input() cols: string;
  @Input() label: string;
  @Input() suffix: string;
  @Input() info: string = null;
  @Input() formControlName: string;
  @Input() errorMessages: any = {};
  @Input() showPassword: boolean = false;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  errors: any[] = [];
  showInfo: boolean = false;
  showErrors: boolean = false;
  hidePassword: boolean = true;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) { }

  ngOnInit(): void {
    if (this.autoFocus !== false) { this.autoFocus = true; }
    if (this.required !== false) { this.required = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    if (this.showPassword !== false && this.type === 'password') { this.showPassword = true; }
    if (!this.label) { this.label = ''; }
    this.displayError();
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (this.value === '') {
      this.errors = [];
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      this.required = formControl.errors.required || false;
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  onChangeValue() {
    if (this.onChange) {
      this.onChange(this.value);
      this.displayError();
    }
  }

  toggleInfo() {
    this.showInfo = !this.showInfo;
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  togglePassword() {
    if (this.hidePassword) {
      this.hidePassword = false;
      this.type = 'text';
    } else {
      this.hidePassword = true;
      this.type = 'password';
    }
  }

  public focus() {
    this.input.first.nativeElement.focus();
  }

}

