<div class="nv-section guttered customer-ss-detail-page">
    <div class="nv-port">
        <div class="nv-row gap-24 nv-row">
            <div class="nv-column desk-12">
                <div class="nv-column-content">
                    <header class="customer-ss-detail-heading">
                        <div class="customer-ss-heading-img" style="background-image: url('https://loremflickr.com/1900/840/website')"></div>
                        <h4>CUSTOMER SUCCESS STORY</h4>
                        <h1>How Navus helped SEHH to get on-demand education 365 days a year</h1>
                    </header><!-- /.customer-ss-heading-main -->
                    <main class="customer-ss-detail-body">
                        <div class="customer-ss-side-meta">
                            <div class="ss-side-meta-box">
                                <div class="smb-logo">
                                    <span style="background-image: url('https://loremflickr.com/400/400/logo')"></span>
                                </div><!-- /.smb-logo -->
                                <div class="smb-txt">
                                    <h5>ORGANIZATION</h5>
                                    <h3>Sociedad Española de Hematología y Hemoterapia (SEHH)</h3>
                                    <p>The Spanish Society of Haematology, SEHH mission is to ensure and contribute to the proper development of hematology and Hemotherapy in Spain, focusing its activities on raising the professional level, teaching and research of its specialists and therefore on continuous improvement quality of care hematologists provide to their patients daily.</p>
                                    <p><a href><strong>www.sehh.es</strong></a>
                                    <br>
                                    <h5>Headquarters</h5>
                                    <h3>Madrid, Spain</h3>
                                    <br>
                                    <h5>Type of Industry</h5>
                                    <h3>Medical</h3>
                                </div><!-- /.smb-txt -->
                            </div><!-- /.ss-side-meta-box -->
                        </div><!-- /.customer-ss-side-meta -->
                        <div class="customer-ss-content">
                            <div class="cssc-preamble">
                                <h3>The Spanish Society of Hematology (SEHH) saw us at a European Blood and Marrow Transplantation Society (EBMT) meeting in 2016 and asked us to propose a solution for their content access problem.</h3>
                            </div><!-- /.cssc-preamble -->
                            <h4 class="emph">Chalange</h4>
                            <div class="cssc-center-img">
                                <img src="https://loremflickr.com/800/500/computer">
                            </div><!-- /.cssc-center-img -->
                            <br>
                            <h4 class="emph">Result</h4>
                            <p class="smallgap">Enable:</p>
                            <ul class="cssc-checked">
                                <li>On-demand education 365 days a year</li>
                                <li>New e-education sponsorship</li>
                            </ul>
                            <div class="cssc-paned-meta">
                                <div class="cpm-cell">
                                    <div class="cpm-cell-frame">
                                        <h4>20</h4>
                                        <p>Meetings per year</p>
                                    </div><!-- /.cpm-cell-frame -->
                                </div><!-- /.cpm-cell -->
                                <div class="cpm-cell">
                                    <div class="cpm-cell-frame">
                                        <h4>2150</h4>
                                        <p>Registered users</p>
                                    </div><!-- /.cpm-cell-frame -->
                                </div><!-- /.cpm-cell -->
                                <div class="cpm-cell">
                                    <div class="cpm-cell-frame">
                                        <h4>1414</h4>
                                        <p>Materials Available</p>
                                    </div><!-- /.cpm-cell-frame -->
                                </div><!-- /.cpm-cell -->
                                <div class="cpm-cell">
                                    <div class="cpm-cell-frame">
                                        <h4>212854</h4>
                                        <p>Materials Accessed</p>
                                    </div><!-- /.cpm-cell-frame -->
                                </div><!-- /.cpm-cell -->
                            </div><!-- /.cssc-paned-meta -->
                            <div class="cssc-quote">
                                <img src="https://navus.io//wp-content/uploads/2020/01/quotations-.svg" aria-hidden="true">
                                <p>The Navus team and its digital platform has moved our congress on par with large international events. We couldn’t be more satisfied. EFR is now also able to provide educational activities throughout the year.</p>
                                <div class="cssc-red-line"></div>
                                <h4>Béla Teleky</h4>
                                <h5>President @ European Federation for coloRectal Cancer</h5>
                            </div><!-- /.cssc-quote -->
                        </div><!-- /.customer-ss-content -->
                    </main><!-- /.customer-ss-detail-body -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /nv-row -->
    </div><!-- /nv-port -->
</div><!-- /nv-section -->
