import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { debounceTime, delay, filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ApproveStatus, ChatUser, Message, Room, RoomType } from '@navus/core/classes/chat';
import { ChatService } from '@navus/core/services/chat.service';
import { ModalService } from '@navus/ui/modal/modal.service';
import { TrackingService } from '@navus/core/services/tracking.service';

@Component({
  selector: 'nv-chat',
  styleUrls: ['./chat.component.scss'],
  templateUrl: './chat.component.html'
})
export class NavusChatComponent implements OnDestroy, OnChanges {
  @Input() roomId: string;
  @Input() currentUserId: number;
  @Input() eventId: number;
  @Input() approval: boolean;
  @Input() replies: boolean;
  @Input() deletion: boolean = true;
  @Input() upVotes: boolean;
  @Input() canReply: boolean;
  @Input() canApprove: boolean;
  @Input() canUpVote: boolean;
  @Input() approveStatus: ApproveStatus;
  @Input() chatRoomType: RoomType;
  @Input() placeholder: string;

  @Input() sortByUpVotesCount: boolean;
  @Input() usecase: 'Q&A' | 'Chat';

  @Output() readonly userClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly chatLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('chatcontent') chatContent: ElementRef;
  @ViewChild('textarea') textarea: ElementRef;
  scrollTop: number = null;

  loadingChat = true;
  loadingMoreMessages = false;
  noMoreMessages = false;

  chatForm: FormGroup;
  messageStream$: Observable<Message[]>[];
  messages: Message[] = [];
  chatRoom$: Observable<{ room: Room, users: ChatUser[] }>;
  repliedTo: { id: string, text: string, creator: number, approved?: boolean };
  active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  retries: number = 0;
  firebaseUserActivated: boolean;

  private readonly cancelPreviousSubscriptions$: Subject<void> = new Subject();
  private readonly cancelPreviousMessagesSubscriptions$: Subject<void> = new Subject();
  private readonly cancelInterval$: Subject<void> = new Subject();
  private autoScrollOn: boolean = true;
  private loadMoreMessages$: Subject<boolean> = new Subject();
  private unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private chatService: ChatService,
    private _sanitizer: DomSanitizer,
    private modalService: ModalService,
    public datepipe: DatePipe,
    public renderer: Renderer2,
    private trackingService: TrackingService,
  ) { }

  initUser(): Observable<boolean> {
    return this.firebaseUserActivated ? of(true) : this.chatService.initUser(this.eventId).pipe(
      tap((userFromFirebase) => (userFromFirebase?.visible || this.usecase === 'Q&A') ? this.active$.next(true) : null),
      map(() => {
        this.firebaseUserActivated = true;
        return this.firebaseUserActivated;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.hasOwnProperty('roomId') && changes.roomId.previousValue !== changes.roomId.currentValue) ||
      (changes.hasOwnProperty('eventId') && changes.eventId.previousValue !== changes.eventId.currentValue) ||
      (changes.hasOwnProperty('currentUserId') && changes.currentUserId.previousValue !== changes.currentUserId.currentValue) ||
      (changes.hasOwnProperty('approval') && changes.approval.previousValue !== changes.approval.currentValue) ||
      (changes.hasOwnProperty('replies') && changes.replies.previousValue !== changes.replies.currentValue) ||
      (changes.hasOwnProperty('canReply') && changes.canReply.previousValue !== changes.canReply.currentValue) ||
      (changes.hasOwnProperty('canApprove') && changes.canApprove.previousValue !== changes.canApprove.currentValue) ||
      (changes.hasOwnProperty('chatRoomType') && changes.chatRoomType.previousValue !== changes.chatRoomType.currentValue) ||
      (changes.hasOwnProperty('approveStatus') && changes.approveStatus.previousValue !== changes.approveStatus.currentValue)) {
      this.initUser().pipe(
        switchMap((_) => this.active$.pipe(filter((active) => active))),
        takeUntil(this.cancelPreviousSubscriptions$),
        takeUntil(this.unsubscribe$),
      ).subscribe((_) => {
        this.initialize();
      });
    }
  }

  ngOnDestroy(): void {
    this.cancelPreviousSubscriptions$.complete();
    this.loadMoreMessages$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initialize() {
    if (this.roomId && this.eventId && this.currentUserId) {
      this.chatLoading.emit(true);
      this.cancelPreviousSubscriptions$.next();
      this.messages = [];
      this.repliedTo = null;
      this.chatRoom$ = null;
      this.messageStream$ = [];
      this.noMoreMessages = false;
      this.loadingChat = true;
      this.retries = 0;
      timer(3000, 10000).pipe(
        startWith(''),
        delay(300),
        tap(() => {
          if (this.retries === 3) {
            this.retries++;
            this.cancelInterval$.next();
          } else if (this.loadingChat) {
            this.retries++;
            console.warn(this.retries === 1 ? 'Loading chat' : ('Retrying to load chat. Retries left:' + (3 - this.retries)));
            this.cancelPreviousSubscriptions$.next();
            if (this.usecase === 'Chat') {
              this.initChat();
            } else {
              this.initRoom().then(() => this.initChat());
            }
          } else {
            this.cancelInterval$.next();
          }
        }),
        takeUntil(this.cancelInterval$),
        takeUntil(this.unsubscribe$)).subscribe();
    } else {
      this.loadingChat = false;
    }
  }

  adjustHeight(event?: Event) {
    if (this.textarea && this.textarea.nativeElement) {
      setTimeout(() => {
        this.renderer.setStyle(this.textarea.nativeElement, 'height', '45px');
        const scrollHeight = (2 + this.textarea.nativeElement.scrollHeight);
        if (scrollHeight < 121) {
          this.renderer.setStyle(this.textarea.nativeElement, 'height', scrollHeight + 'px');
        } else {
          this.renderer.setStyle(this.textarea.nativeElement, 'height', '121px');
          this.renderer.setStyle(this.textarea.nativeElement, 'overflow-y', 'scroll');
        }
      });
    }
  }

  exitChat(event) {
    if (event && event.currentTarget && event.currentTarget.checked === false) {
      this.chatService.setUserVisibility(this.currentUserId, false).then(() => {
        this.active$.next(false);
      });
    }
  }

  activateChat() {
    this.active$.next(true);
  }

  emitClick(userId: number) {
    this.userClicked.emit(userId);
  }

  sendMessageToGroup(selectedRoom: { room: Room, users: ChatUser[] }, form: any) {
    if (form && form.message) {
      const today = new Date();
      const newMessage: Message = {
        createTime: today.getTime(),
        editedAt: today.getTime(),
        createdBy: this.currentUserId,
        index: !isNaN(selectedRoom.room.messageCount) ? (selectedRoom.room.messageCount + 1) : 1,
        text: form.message,
        uid: this.generateUuidv4(),
        deleted: false
      };

      if (this.repliedTo && this.repliedTo.id && this.repliedTo.text) {
        newMessage.relatedMessageText = this.repliedTo.text;
        newMessage.relatedMessageId = this.repliedTo.id;
        newMessage.relatedMessageCreator = this.repliedTo.creator;
      }

      if (this.approval) {
        if (this.canApprove) {
          newMessage.approved = this.repliedTo && this.repliedTo.hasOwnProperty('approved') ? this.repliedTo.approved : false;
        }
      }

      this.chatService.sendMessageToRoom(this.eventId, this.currentUserId, this.roomId, newMessage).then(() => {
        // Tracking
        const entityType = this.roomId.split('_')[0];
        const entityId = this.roomId.split('_')[1] || null;
        if (['block', 'presentation'].includes(entityType)) {
          this.trackingService
            .countTracking(this.eventId, entityType,  entityId, 'MESSAGE', { user_id: this.currentUserId });

          if (this.messages.length === 1) {
            this.trackingService
              .countTracking(this.eventId, entityType,  entityId, 'FIRST_MESSAGE', { user_id: this.currentUserId });            
          }
        } else {
          this.trackingService
            .countTracking(this.eventId, 'users',  entityId, 'MESSAGE', { user_id: this.currentUserId });
        } 


        this.chatForm = this.formBuilder.group({
          'message': [null]
        });
        this.adjustHeight();
        this.repliedTo = null;
      });
    }
  }

  replyToMessage(message: Message) {
    this.repliedTo = { id: message.uid, text: message.text, creator: message.createdBy };
    if (message.hasOwnProperty('approved')) {
      this.repliedTo.approved = message.approved;
    }
  }

  cancelReply() {
    this.repliedTo = null;
  }

  approveMessage(selectedRoomId: string, message: Message, approve: boolean) {
    this.chatService.approveMessage(this.eventId, selectedRoomId, message, approve, this.currentUserId);
  }

  isMessageUpVoted(message: Message): boolean {
    return message.upVotes?.indexOf(this.currentUserId) > -1;
  }

  getMessageUpVotesCount(message: Message): number {
    return message.upVotes?.length;
  }

  upVoteMessage(selectedRoomId: string, message: Message) {
    if (!message.upVotes?.length) {
      message.upVotes = [];
      message.upVotesCount = 0;
    }
    if (message.upVotes?.includes(this.currentUserId)) {
      message.upVotes.splice(message.upVotes.indexOf(this.currentUserId), 1);
      message.upVotesCount -= 1;
    } else {
      message.upVotes.push(this.currentUserId);
      message.upVotesCount += 1;
    }
    this.chatService.updateMessage(this.eventId, selectedRoomId, message);
  }

  deleteMessage(selectedRoomId: string, message: Message) {
    this.modalService.defaultModal({
      title: 'Message Deletion',
      body: `Are you sure you want to delete the message: "${message.text}"?`,
      size: 'small',
      buttons: [
        {
          text: 'Cancel',
          color: 'outline',
          role: 'cancel'
        },
        {
          text: 'Delete',
          color: 'primary',
          handler: () => {
            this.chatService.deleteMessage(this.eventId, selectedRoomId, message);
          }
        }
      ]
    });
  }

  onScroll(event) {
    if (this.chatContent) {
      const scrolled = this.chatContent.nativeElement.scrollTop;
      const height = this.chatContent.nativeElement.scrollHeight - this.chatContent.nativeElement.offsetHeight;

      if (scrolled < height) {
        this.autoScrollOn = false;
      } else {
        this.autoScrollOn = true;
      }

      if (scrolled < 10 && (scrolled < this.scrollTop)) {
        this.loadMoreMessages$.next();
      }
      this.scrollTop = scrolled;
    }
  }

  getUserNameFromGroup(chatRoom: { room: Room, users: ChatUser[] }, userId: number) {
    const foundUser = chatRoom.users.find((user) => user.userId === userId);
    return foundUser ? foundUser.name : 'Unknown';
  }

  getUserInitialsFromGroup(chatRoom: { room: Room, users: ChatUser[] }, userId: number) {
    const foundUser = chatRoom.users.find((user) => user.userId === userId);
    const firstName = (foundUser && foundUser.name) ? (foundUser.name.split(' ').length ? foundUser.name.split(' ')[0] : '') : '';
    const lastName = (foundUser && foundUser.name) ? (foundUser.name.split(' ').length > 1 ? foundUser.name.split(' ')[1] : '') : '';
    return (firstName.length ? firstName[0] : '') + (lastName.length ? lastName[0] : '');
  }

  getUserImageFromGroup(chatRoom: { room: Room, users: ChatUser[] }, userId: number) {
    const foundUser = chatRoom.users.find((user) => user.userId === userId);
    return foundUser ? (foundUser.avatarUrl || '') : '';
  }

  getRoomUsers(users: ChatUser[]) {
    if (users.length < 4) {
      return users.map(user => user.name).join(', ');
    } else {
      return (users.slice(0, 2).map(user => user.name).join(', ')) + ', ' + (users.length - 2) + ' others';
    }
  }

  getChatBuddyName(group: { room: Room, users: ChatUser[] }): ChatUser {
    if (group.room.members.length === 2) {
      return group.users.find(user => user.userId !== this.currentUserId);
    }
  }

  getImage(imageUrl: string) {
    if (imageUrl) {
      return this._sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }
  }

  trackByFn(index, item) {
    return item.uid ? item.uid : index;
  }

  private getAllNewMessagesStream(roomId: string, timestamp: number) {
    return this.chatService
      .getMessagesForRoomByStatus(this.eventId, roomId, this.currentUserId, this.approveStatus, this.canApprove, timestamp, true);
  }

  private getLoadMoreMessagesApiCall(roomId: string, timestamp?: number) {
    let currentTs;
    // This is a regular load more scenario
    if (this.messages?.[0]) {
      if (this.canApprove) {
        currentTs = this.messages[0]?.createTime;
      } else {
        currentTs = this.messages[0]?.editedAt;
      }
    } else {
      currentTs = new Date().getTime();
    }
    // This scenario happens when fetching last 15 messages from the specific timestamp
    if (timestamp) {
      currentTs = timestamp;
    }
    return this.chatService
      .getMessagesForRoomByStatus(this.eventId, roomId, this.currentUserId, this.approveStatus, this.canApprove, currentTs);
  }

  private initRoom() {
    const newRoom: Room = {
      members: [this.currentUserId],
      subscribers: [this.currentUserId],
      uid: this.roomId,
      type: this.chatRoomType || RoomType.PUBLIC_GROUP,
    };
    if (this.usecase !== 'Chat') {
      newRoom.name = this.roomId;
    }

    return this.chatService.startChat(this.eventId, newRoom);
  }

  private initChat() {
    const selectedRoom$ = this.chatService.getRoomById(this.eventId, this.roomId)
      .pipe(takeUntil(this.cancelPreviousSubscriptions$), takeUntil(this.unsubscribe$));
    const chatUsers$ = this.chatService.getAllChatUsers().pipe(takeUntil(this.cancelPreviousSubscriptions$), takeUntil(this.unsubscribe$));

    // Create chat room, so meta stuff can be loaded into HTML (images etc.)
    this.chatRoom$ = chatUsers$.pipe(
      switchMap((chatUsers) => selectedRoom$.pipe(map((myRoom) => ({ myRoom, chatUsers })))),
      map(({ myRoom, chatUsers }) =>
        ({
          room: myRoom,
          users: chatUsers.filter(chatUser => myRoom.members.includes(chatUser.userId))
        })
      ),
      takeUntil(this.cancelPreviousSubscriptions$),
      takeUntil(this.unsubscribe$),
    );

    if (!this.sortByUpVotesCount) {
      const currentTs = new Date().getTime();
      // Subscribe to all new messages coming in from the current timestamp
      const allNewMessages$ = selectedRoom$.pipe(
        take(1),
        switchMap(() => this.roomId ? this.getAllNewMessagesStream(this.roomId, currentTs)
          : of([] as Message[])),
        tap(() => this.chatLoading.emit(false)),
        shareReplay(1),
      );
      // Subscribe to the latest 15 messages from the current timestamp
      const lastMessages$ = selectedRoom$.pipe(
        take(1),
        switchMap(() => this.roomId ? this.getLoadMoreMessagesApiCall(this.roomId, currentTs) : of([] as Message[])),
        tap(() => this.chatLoading.emit(false)),
        shareReplay(1),
      );

      this.messageStream$ = [allNewMessages$, lastMessages$];

      // Each time a user scrolls to the top, loadMoreMessages subject is firing
      // Each time that happens push a subscription to additional 15 messages to the messageStream$
      const loadingMoreMessages$ = selectedRoom$.pipe(
        take(1),
        switchMap((selectedRoom) => this.loadMoreMessages$.pipe(map(() => selectedRoom))),
        debounceTime(300),
        tap((selectedRoom) => {
            if (this.messages?.length >= 15 && (selectedRoom.messageCount > this.messages.length) && !this.noMoreMessages) {
              this.loadingMoreMessages = true;
              this.cancelPreviousMessagesSubscriptions$.next();
              this.messageStream$.push(this.getLoadMoreMessagesApiCall(selectedRoom.uid).pipe(
                delay(500),
                tap(() => this.loadingMoreMessages = false),
                shareReplay(1),
              ));
              this.initMessages(this.messageStream$);
            }
          }
        ),
        takeUntil(this.cancelPreviousSubscriptions$),
        takeUntil(this.unsubscribe$),
      );
      loadingMoreMessages$.subscribe();
    } else {
      // Subscribe to all the messages which have at least one upVote
      const upVotedMessages$ = selectedRoom$.pipe(
        take(1),
        switchMap(() => this.roomId ?
          this.chatService.getMessagesWithUpVotesForRoom(this.eventId, this.roomId, this.currentUserId, this.approveStatus)
          : of([] as Message[])
        ),
        tap(() => this.chatLoading.emit(false)),
      );

      this.messageStream$ = [upVotedMessages$];
    }

    this.initMessages(this.messageStream$);

    this.chatForm = this.formBuilder.group({
      'message': [null]
    });
  }

  private initMessages(messagesStream: Observable<Message[]>[]) {
    combineLatest(messagesStream)
      .pipe(
        takeUntil(this.cancelPreviousMessagesSubscriptions$),
        takeUntil(this.cancelPreviousSubscriptions$),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response[response.length - 1].length) {
          messagesStream.pop();
          this.noMoreMessages = true;
        }
        const messages = [].concat(...response);
        let orderByQuery = this.canApprove ? 'createTime' : 'editedAt';
        if (this.sortByUpVotesCount) {
          orderByQuery = 'upVotesCount';
        }
        this.messages = [...messages]
          .filter((value, index, self) => self.map(x => x.uid).indexOf(value.uid) === index)
          .sort((msg1, msg2) => msg1[orderByQuery] - msg2[orderByQuery]);
        this.loadingChat = false;

        setTimeout(() => {
          if (this.autoScrollOn && this.chatContent) {
            this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight;
          }
        }, 300);
      });
  }

  private generateUuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      // tslint:disable-next-line:no-bitwise
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16),
    );
  }
}


