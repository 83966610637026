import { Component } from '@angular/core';

@Component({
    selector: 'customer-ss-list-page',
    templateUrl: './customer-ss-list.component.html'
})
export class CustomerSsListComponent  {

    constructor() { }

}
