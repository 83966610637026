import { Organization } from "./organization";

export interface Invitation {
  id: string;
  token: string;
  email: string;
  expires_at: string;
  role_id: number;
  organization_id: string;
  created_at: string;
  updated_at: string;

  organization?: Organization;
}