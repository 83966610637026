import { ResolveFn, Router } from '@angular/router';
import { Observable, tap, of } from 'rxjs';
import { inject } from '@angular/core';
import { Organization, OrganizationService } from 'riterz-core';

export const organizationResolver: ResolveFn<Observable<Organization|null>> = (
  route, 
  state,
  organizationService: OrganizationService = inject(OrganizationService),
  router: Router = inject(Router),
) => {
  const organizationId = route.paramMap.get('organizationId');
  const params = {
    include: 'marketplace,country,industry.category,topics,events,events.eventType,posts',
  }

  if (!organizationId) {
    return of(null);
  }

  return organizationService
    .getPublicOrganization(organizationId, params)
    .pipe(
      tap({
        error: (error) => {
          console.log(error);
        }
      })
    );
    
};
