import { Component } from '@angular/core';

@Component({
    selector: 'app-wp-landing-page',
    templateUrl: './wp-landing.component.html'
})
export class WpLandingComponent {
    showSideNav: boolean = true;

    constructor() { }

}
