import { UserRole } from "./user-role";

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  image_url: string;
  institution: string;
  job_title: string;
  specialty: string;
  status: string;

  email_verified_at: string;
  created_at: string;

  roles: UserRole[];
}