import { Directive, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
@Directive({ selector: '[nvOffClick]' })

export class NavusOffClickDirective implements OnDestroy {
  @Output() offClick: EventEmitter<Event> = new EventEmitter<Event>();

  subscription: Subscription;
  documentBodyElement: HTMLElement;
  baseElement: HTMLElement;

  constructor(private el: ElementRef) {
    this.baseElement = this.el.nativeElement;
    this.documentBodyElement = document.body;
    this.subscription = fromEvent(document, 'click')
      .subscribe(
        (event: Event) => this.clickHandler(event.target as Element, event)
      );
  }

  private clickHandler(currentElement: HTMLElement | Element, event: Event): void {

    if (currentElement === this.documentBodyElement || currentElement == null) {
      this.offClick.emit(event);
      return;
    }
    if (currentElement === this.baseElement) {
      return;
    }
    this.clickHandler(currentElement.parentElement, event);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
