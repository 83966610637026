<div class="rtz-config-frame" [class.side-menu-collapsed]="showSideNav">
    <header class="rtz-cf-header">
        <div class="rtz-cf-logo-port">
            <figure>
                <a href title="Riterz configurator"><img src="../../assets/riterz-logo.svg" alt="Riterz.com"></a>
            </figure>
        </div><!-- /.rtz-cf-logo-port -->
        <div class="rtz-cf-user-settings">
            <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure><img src="../../assets/avatar-placeholder.png" alt="User Name"></figure>
                    <h5>Lara Lancaster</h5>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Profile" class="rtz-ico-profile">Profile</a></li>
                        <li><a href title="Privacy" class="rtz-ico-privacy">Privacy</a></li>
                        <li><a href title="Legal" class="rtz-ico-legal">Legal</a></li>
                        <li><a href title="Sign Out" class="rtz-ico-signout">Sign Out</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
        </div><!-- /.rtz-cf-user-settings -->
    </header><!-- /.rtz-cf-header -->
    <div class="rtz-config-body">
        <aside class="rtz-config-side-panel">
            <span class="rtz-side-panel-toggle" (click)="showSideNav = !showSideNav"></span>
            <div class="rtz-config-side-panel-up">
                <div class="cm-organization">
                    <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                        <div class="cm-organization-logo">
                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                        </div><!-- /.cm-organization-logo -->
                        <div class="cm-organization-label">
                            <h3>ENP</h3>
                            <p>Organisation</p>
                        </div><!-- /.cm-organization-label -->
                    </div><!-- /.cm-current-organisation -->
                    <div class="cm-organisation-list">
                        <div class="cm-organization-search">
                            <input type="text" name="" placeholder="Search for organizations…">
                        </div><!-- /.cm-organization-search -->
                        <nav>
                            <ul>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organisation</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organisation</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organisation</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organisation</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organisation</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organisation</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                            </ul>
                        </nav>
                        <a href="" class="cm-add">
                            Add organization
                        </a><!-- /.cm-add -->
                    </div><!-- /.cm-organisation-list -->
                </div><!-- /.cm-organisation -->

                <h3 class="rtz-ui-menu-label" style="display: none;">Admin panel</h3>

                <nav class="rtz-ui-menu">
                    <ul>
                        <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                        <li><a href title="Settings" class="rtz-ico-settings">Settings</a></li>
                        <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                        <li><a href title="Dashboard" class="rtz-ico-dashboard">Dashboard</a></li>
                        <li><a href title="File Manager" class="rtz-ico-file-manager">File Manager</a></li>
                        <li><a href title="News Manager" class="rtz-ico-news-manager active">News Manager</a></li>
                        <li><a href title="Events" class="rtz-ico-events">Events</a></li>
                        <li><a href title="Advertising" class="rtz-ico-advertising">Advertising</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-config-side-panel-up -->
            <div class="rtz-config-side-panel-down">
                <div class="rtz-ui-global-settings">
                    <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                        <nav class="rtz-ui-menu quasi">
                            <ul>
                                <li><a href title="Settings" class="rtz-ico-config-settings">Settings</a></li>
                            </ul>
                        </nav><!-- /.rtz-ui-menu quasi -->
                        <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                            <ul>
                                <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                                <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                                <li><a href title="Billing" class="rtz-ico-billing">Billing</a></li>
                                <li><a href title="Organisation Settings" class="rtz-ico-organisation">Organisation Settings</a></li>
                            </ul>
                        </nav><!-- /.rtz-ui-menu -->
                    </div><!-- /.rtz-ui-settings-box -->
                </div><!-- /.rtz-ui-global-settings -->
            </div><!-- /.rtz-config-side-panel-down -->
        </aside><!-- /.rtz-config-side-panel -->
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">

                <header class="rtz-config-top-dash">
                    <h1>Subscriptions</h1>
                    <div class="rtz-organizations-dash-tools">
                        <a class="rt-btn normal txt secondary">Go back</a>
                    </div>
                </header><!-- /rtz-config-top-dash -->

                <div class="rtz-card">
                    <h2>Platform Pricing Plans</h2>
                    <div class="rtz-pricing-plans">
                        <div class="rtz-pp-item">
                            <header>
                                <h3>Starter</h3>
                            </header>
                            <p class="rtz-pp-preamble">
                                Regular subscription fee of<br>
                                <span><small>&dollar;</small>99</span><br>
                                per month<sup>*</sup><br><br>
                                or 10% variable fee of external gross media booking, whichever is greater.
                            </p><!-- /.rtz-pp-preamble -->
                            <div class="rtz-pp-body">
                                <ul>
                                    <li>1 External Media Channel</li>
                                    <li>1 Sponsor</li>
                                    <li>Dedicated Customer Success Agent</li>
                                    <li>1 Admin Seat</li>
                                </ul>
                            </div><!-- /.rtz-as-body -->
                            <div class="rtz-pp-btn">
                                <p><a href title="See more info">See more info</a></p>
                                <a class="rt-btn normal primary" href title="Upgrade to Starter">Upgrade to Starter</a>
                                <p class="note"><sup>*</sup>$99/month billed monthly. Cancel anytime + $299 onboarding fee</p>
                            </div><!-- /rtz-pp-btn -->
                        </div><!-- /.rtz-pp-item -->
                        <div class="rtz-pp-item">
                            <header>
                                <h3>Advanced</h3>
                            </header>
                            <p class="rtz-pp-preamble">
                                Regular subscription fee of<br>
                                <span><small>&dollar;</small>250</span><br>
                                per month<sup>*</sup><br><br>
                                or 10% variable fee of external gross media booking, whichever is greater.
                            </p><!-- /.rtz-pp-preamble -->
                            <div class="rtz-pp-body">
                                <ul>
                                    <li>1 External Media Channel</li>
                                    <li>1 Owned Media Channel</li>
                                    <li>5 Sponsors</li>
                                    <li>Dedicated Customer Success Agent</li>
                                    <li>Unlimited Admin seats</li>
                                </ul>
                            </div><!-- /.rtz-as-body -->
                            <div class="rtz-pp-btn">
                                <p><a href title="See more info">See more info</a></p>
                                <a class="rt-btn normal primary" href title="Upgrade to Advanced">Upgrade to Advanced</a>
                                <p class="note"><sup>*</sup>$250/month billed monthly. Cancel anytime + $299 onboarding fee</p>
                            </div><!-- /rtz-pp-btn -->
                        </div><!-- /.rtz-pp-item -->
                        <div class="rtz-pp-item">
                            <header>
                                <h3>Pro</h3>
                            </header>
                            <p class="rtz-pp-preamble">
                                Regular subscription fee of<br>
                                <span><small>&dollar;</small>450</span><br>
                                per month<sup>*</sup><br><br>
                                or 10% variable fee of external gross media booking, whichever is greater.
                            </p><!-- /.rtz-pp-preamble -->
                            <div class="rtz-pp-body">
                                <ul>
                                    <li>3 External Media Channel</li>
                                    <li>2 Owned Media Channel</li>
                                    <li>20 Sponsors</li>
                                    <li>Dedicated Customer Success Agent</li>
                                    <li>Unlimited Admin seats</li>
                                    <li>Free Onboarding</li>
                                </ul>
                            </div><!-- /.rtz-as-body -->
                            <div class="rtz-pp-btn">
                                <p><a href title="See more info">See more info</a></p>
                                <a class="rt-btn normal primary" href title="Upgrade to Pro">Upgrade to Pro</a>
                                <p class="note"><sup>*</sup>$450/month billed monthly. 15% discount for annual payment</p>
                            </div><!-- /rtz-pp-btn -->
                        </div><!-- /.rtz-pp-item -->
                        <div class="rtz-pp-item enterprise">
                            <div class="rtz-pp-single">
                                <header>
                                    <h3>Enterprise</h3>
                                </header>
                                <p class="rtz-pp-preamble">
                                    For large scale solutions contact us for a bespoke package.
                                </p><!-- /.rtz-pp-preamble -->
                                <a class="rt-btn normal primary" href title="Contact us">Contact us</a>
                            </div><!-- /.rtz-pp-single -->
                        </div><!-- /.rtz-pp-item -->
                    </div><!-- /.rtz-pricing-plans -->
                </div><!-- /.rtz-card -->

                <div class="rt-spacer"></div>

                <div class="rtz-card">
                    <h2>Additional Services</h2>
                    <h4>Channel Activation</h4>
                    <p>Activate, prepare and connect your organisation media channels to the Riterz Platform.</p>
                    <div class="rtz-additional-services-cards">
                        <div class="rtz-as-item">
                            <header>
                                <h3>Web Banner</h3>
                                <p>Custom website integration</p>
                                <h5><small>&dollar;</small>1,500</h5>
                            </header>
                            <div class="rtz-as-body">
                                <h5>Integration</h5>
                                <ul>
                                    <li>Google tag manager account setup</li>
                                    <li>4 x website banner placements</li>
                                    <li>Liaise with web developer for integration management</li>
                                    <li>Platform connection</li>
                                    <li>Deployment, testing and quality assurance</li>
                                </ul>
                            </div><!-- /.rtz-as-body -->
                            <div class="rtz-as-btn">
                                <a class="rt-btn normal primary" href title="Web Banner - Custom website integration">Select</a>
                            </div>
                        </div><!-- /.rtz-as-item -->
                        <div class="rtz-as-item">
                            <header>
                                <h3>LinkedIn</h3>
                                <p>Activation + Audience Expansion</p>
                                <h5><small>&dollar;</small>1,500</h5>
                            </header>
                            <div class="rtz-as-body">
                                <h5>Page + Accounts</h5>
                                <ul>
                                    <li>Registration of company LinkedIn page (if required)</li>
                                    <li>Development of campaign manager account</li>
                                    <li>Registration of campaign manager PPC account</li>
                                </ul>
                                <h5>Audience Expansion</h5>
                                <ul>
                                    <li>Syncing offline databases</li>
                                    <li>Lookalike audience buidling</li>
                                    <li>Developing new audiences based on demographics</li>
                                    <li>Website re-targeting integration</li>
                                    <li>Re-targetting audience building</li>
                                </ul>
                            </div><!-- /.rtz-as-body -->
                            <div class="rtz-as-btn">
                                <a class="rt-btn normal primary" href title="LinkedIn - Activation + Audience Expansion">Select</a>
                            </div>
                        </div><!-- /.rtz-as-item -->
                        <div class="rtz-as-item">
                            <header>
                                <h3>Meta</h3>
                                <p>Activation + Audience Expansion</p>
                                <h5><small>&dollar;</small>1,500</h5>
                            </header>
                            <div class="rtz-as-body">
                                <h5>Page + Accounts</h5>
                                <ul>
                                    <li>Registration of company FB page (if required)</li>
                                    <li>Registration of Business Manager</li>
                                    <li>Creation of Ad Account (if required)</li>
                                </ul>
                                <h5>Audience Expansion</h5>
                                <ul>
                                    <li>Syncing offline databases</li>
                                    <li>Lookalike audience buidling</li>
                                    <li>Developing new audiences based on demographics</li>
                                    <li>Website re-targeting integration</li>
                                    <li>Re-targetting audience building</li>
                                </ul>
                            </div><!-- /.rt-as-body -->
                            <div class="rtz-as-btn">
                                <a class="rt-btn normal primary" href title="Meta - Activation + Audience Expansion">Select</a>
                            </div>
                        </div><!-- /.rtz-as-item -->
                        <div class="rtz-as-item">
                            <header>
                                <h3>Ad Development</h3>
                                <p>On demand creative services</p>
                                <h5><small>&dollar;</small>399</h5>
                            </header>
                            <div class="rtz-as-body">
                                <h5>Ad Creatives</h5>
                                <p>Unique and dynamic co-branded ad development to unblock your marketing schedule</p>
                                <ul>
                                    <li>Thoughtful and impactful ad concepts that deliver value to both your audience and your sponsor</li>
                                    <li>3 x distinct ads developed with A/B versions for each (total 6 ads)</li>
                                    <li>Image asset development in coherence with your organisations brand guide</li>
                                    <li>3 feedback rounds, your sponsor included</li>
                                </ul>
                            </div><!-- /.rtz-as-body -->
                            <div class="rtz-as-btn">
                                <a class="rt-btn normal primary" href title="Ad Development - On demand creative services">Select</a>
                            </div>
                        </div><!-- /.rtz-as-item -->
                    </div><!-- /.rtz-additional-services-cards -->
                </div><!-- /.rtz-card -->

                <div class="rt-spacer"></div>

                <!-- Upgrade your plan -->
                <div class="nv-row flow-center gap-24">
                    <div class="nv-column desk-6 tab-12" style="background-color: #fff;">
                        <div class="nv-column-content" style="padding: 20px;">
                            <!-- Modal Content  'Upgrade your plan' -->
                            <div class="rtz-plan-upgrade-face">
                                <div class="rtz-puf">
                                    <h4>
                                        <span>From</span>
                                        Free tier
                                        <em>Current plan</em>
                                    </h4>
                                </div><!-- /.rtz-puf -->
                                <div class="rtz-puf-arrow"></div>
                                <div class="rtz-puf">
                                    <h4>
                                        <span>Upgrade to</span>
                                        Advanced
                                    </h4>
                                </div><!-- /.rtz-puf -->
                            </div><!-- /.rtz-plan-upgrade-face -->
                            <div class="rtz-plan-select">
                                <div class="rtz-ps-item">
                                    <div class="nv-form-item nv-radio-item">
                                        <label>
                                            <span class="nri-radio-port">
                                                <input type="radio" name="name1" checked>
                                                <span class="nri-radio-visual"></span>
                                            </span><!-- /.nri-radio-port -->
                                        </label>
                                    </div><!-- /nv-form-item  -->
                                    <p>Monthly Plan</p>
                                    <span>&euro; 199 billed monthly</span>
                                </div><!-- /.rtz-ps-item -->
                                <div class="rtz-ps-item">
                                    <div class="nv-form-item nv-radio-item">
                                        <label>
                                            <span class="nri-radio-port">
                                                <input type="radio" name="name1">
                                                <span class="nri-radio-visual"></span>
                                            </span><!-- /.nri-radio-port -->
                                        </label>
                                    </div><!-- /nv-form-item -->
                                    <p>Annual Plan</p>
                                    <span>&euro; 2149 billed annually. <strong>Save 10%</strong></span>
                                </div><!-- /.rtz-ps-item -->
                            </div><!-- /.rtz-plan-select -->
                            <p class="spaceless">Select the credit card to charge the € 199 to:</p>
                            <div class="rtz-quasi-select">
                                <div class="rtz-cc-bar">
                                    <div class="rtz-cc-mean">Credit Card</div>
                                    <div class="rtz-cc-meta">
                                        <div class="rtz-cc-type">
                                            <p>Default</p>
                                            <span class="rtz-ccard visa"></span>
                                        </div><!-- /.rtz-cc-type -->
                                        <p class="rtz-cc-num">**** **** **** 1234</p>
                                        <p class="rtz-cc-exp">Expires 02/24</p>
                                    </div><!-- /.rtz-cc-meta -->
                                </div><!-- /.rtz-cc-bar -->
                                <span class="rtz-qs-trigger"></span>
                            </div><!-- /.rtz-quasi-select -->
                            <p><a href>Add payment method</a></p>
                            <div class="rt-btn-box flow-end gap-20">
                                <a href class="rt-btn txt normal">Cancel</a>
                                <a href class="rt-btn primary normal">Proceed</a>
                            </div><!-- /.nv-btn-box flow-end gap-6 -->
                            <div class="nv-divider desk-small"></div>
                            <p class="rtz-small-txt gray align-right">
                                The next monthly recurring charge<br>will be made on <strong>Oct 17, 2023</strong>
                            </p>
                            <!-- Modal Content -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->

                <div class="rt-spacer"></div>

                <!-- Order Summary -->
                <div class="nv-row flow-center gap-24">
                    <div class="nv-column desk-6 tab-12" style="background-color: #fff;">
                        <div class="nv-column-content" style="padding: 20px;">

                            <!-- Modal Content  'Order Summary' -->
                            <div class="rtz-order-summary">
                                <p>
                                    <span>Advanced plan</span>
                                    <span><strong>&euro; 199</strong>/month</span>
                                </p>
                                <p>
                                    <span>Total Due Today</span>
                                    <span><strong>&euro; 199</strong></span>
                                </p>
                            </div>
                            <!-- /.rtz-order-summary -->

                            <div class="rt-btn-box flow-end gap-20">
                                <a href class="rt-btn txt normal">Cancel</a>
                                <a href class="rt-btn primary normal">Complete Purchase</a>
                            </div><!-- /.nv-btn-box flow-end gap-6 -->
                            <br>
                            <p class="rtz-small-txt gray align-right">
                                The next monthly recurring charge<br>will be made on <strong>Oct 17, 2023</strong>
                            </p>
                            <!-- Modal Content -->

                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->

<!--                <header class="rtz-config-top-dash">-->
<!--                    <h1>Organisations</h1>-->
<!--                </header>&lt;!&ndash; /rtz-config-top-dash &ndash;&gt;-->

<!--                &lt;!&ndash; Upgrade your plan &ndash;&gt;-->
<!--                <div class="nv-row flow-center gap-24">-->
<!--                    <div class="nv-column desk-6" style="background-color: #fff;">-->
<!--                        <div class="nv-column-content" style="padding: 20px;">-->

<!--                            &lt;!&ndash; Modal Content  'Add payment method' &ndash;&gt;-->

<!--                            <div class="nv-row gap-20 propagate">-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="Name on the card">-->
<!--                                                <span class="label-title">Name on the card</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="Card number">-->
<!--                                                <span class="label-title">Card number</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-4">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory select-item mandatory">-->
<!--                                            <label>-->
<!--                                                <select>-->
<!--                                                    <option>01</option>-->
<!--                                                    <option>02</option>-->
<!--                                                    <option>03</option>-->
<!--                                                </select>-->
<!--                                                <span class="label-title">Exp. Month</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-4">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory select-item mandatory">-->
<!--                                            <label>-->
<!--                                                <select>-->
<!--                                                    <option>2023</option>-->
<!--                                                    <option>2024</option>-->
<!--                                                    <option>2025</option>-->
<!--                                                </select>-->
<!--                                                <span class="label-title">Exp. Year</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-4">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="CCV">-->
<!--                                                <span class="label-title">CCV</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->

<!--                            <p class="rtz-chckbox-inline">-->
<!--                                <label class="nv-regular-checkbox" title="select item">-->
<!--                                    <span class="rcb">-->
<!--                                        <input type="checkbox" checked>-->
<!--                                        <span class="rc-visual"></span>-->
<!--                                    </span>&lt;!&ndash; /.rcb &ndash;&gt;-->
<!--                                </label>-->
<!--                                <span class="label-txt">Set this as my default credit card</span>-->
<!--                            </p>-->

<!--                            <br>-->
<!--                            <div class="nv-btn-box flow-end gap-24">-->
<!--                                <a href class="nv-btn txt small quaternary">Cancel</a>-->
<!--                                <a href class="nv-btn secondary small">Save</a>-->
<!--                            </div>&lt;!&ndash; /.nv-btn-box flow-end gap-6 &ndash;&gt;-->

<!--                            &lt;!&ndash; /Modal Content  'Add payment method' &ndash;&gt;-->

<!--                        </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->


<!--                    <div class="nv-column desk-6" style="background-color: #fff;">-->
<!--                        <div class="nv-column-content" style="padding: 20px;">-->

<!--                            &lt;!&ndash; Modal Content  'Edit Billing information' &ndash;&gt;-->
<!--                            <p class="rtz-field-default">Address</p>-->
<!--                            <div class="nv-row gap-12 propagate">-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="Street">-->
<!--                                                <span class="label-title">Street</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-6">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="City">-->
<!--                                                <span class="label-title">City</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-6">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="Postal address code">-->
<!--                                                <span class="label-title">Postal address code</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory select-item mandatory">-->
<!--                                            <label>-->
<!--                                                <select>-->
<!--                                                    <option>Country</option>-->
<!--                                                    <option>Brazil</option>-->
<!--                                                    <option>Albania</option>-->
<!--                                                </select>-->
<!--                                                <span class="label-title">Country</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                                <div class="nv-column desk-12">-->
<!--                                    <div class="nv-column-content">-->
<!--                                        <div class="nv-form-item nv-mandatory">-->
<!--                                            <label>-->
<!--                                                <input type="text" placeholder="Telephone number">-->
<!--                                                <span class="label-title">Telephone number</span>-->
<!--                                            </label>-->
<!--                                        </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->
<!--                            <br>-->
<!--                            <p class="rtz-field-default">Billing Contact</p>-->
<!--                            <div class="nv-form-item nv-mandatory">-->
<!--                                <label>-->
<!--                                    <input type="email" placeholder="EMail">-->
<!--                                    <span class="label-title">EMail</span>-->
<!--                                </label>-->
<!--                            </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                            <br>-->
<!--                            <div class="nv-btn-box flow-end gap-24">-->
<!--                                <a href class="nv-btn txt small quaternary">Cancel</a>-->
<!--                                <a href class="nv-btn secondary small">Save Changes</a>-->
<!--                            </div>&lt;!&ndash; /.nv-btn-box flow-end gap-6 &ndash;&gt;-->

<!--                            &lt;!&ndash; Modal Content &ndash;&gt;-->

<!--                        </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                    <div class="nv-column desk-6" style="background-color: #fff;">-->
<!--                        <div class="nv-column-content" style="padding: 20px;">-->

<!--                            &lt;!&ndash; Modal Content  'Order Summary' &ndash;&gt;-->
<!--                                <div class="rtz-order-summary">-->
<!--                                    <p>-->
<!--                                        <span>Advanced plan</span>-->
<!--                                        <span><strong>&euro; 199</strong>/month</span>-->
<!--                                    </p>-->
<!--                                    <p>-->
<!--                                        <span>Total Due Today</span>-->
<!--                                        <span><strong>&euro; 199</strong></span>-->
<!--                                    </p>-->
<!--                                </div>-->
<!--                                &lt;!&ndash; /.rtz-order-summary &ndash;&gt;-->

<!--                                <div class="nv-btn-box flow-end gap-24">-->
<!--                                    <a href class="nv-btn txt small quaternary">Cancel</a>-->
<!--                                    <a href class="nv-btn secondary small">Complete Purchase</a>-->
<!--                                </div>&lt;!&ndash; /.nv-btn-box flow-end gap-6 &ndash;&gt;-->
<!--                                <br>-->
<!--                                <p class="rtz-small-txt gray align-right">-->
<!--                                    The next monthly recurring charge<br>will be made on <strong>Oct 17, 2023</strong>-->
<!--                                </p>-->
<!--                            &lt;!&ndash; Modal Content &ndash;&gt;-->

<!--                        </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                    <div class="nv-column desk-6" style="background-color: #fff;">-->
<!--                        <div class="nv-column-content" style="padding: 20px;">-->

<!--                            &lt;!&ndash; Modal Content  'Upgrade your plan' &ndash;&gt;-->
<!--                            <div class="rtz-plan-upgrade-face">-->
<!--                                <div class="rtz-puf">-->
<!--                                    <h4>-->
<!--                                        <span>From</span>-->
<!--                                        Free-->
<!--                                    </h4>-->
<!--                                </div>&lt;!&ndash; /.rtz-puf &ndash;&gt;-->
<!--                                <div class="rtz-puf-arrow"></div>-->
<!--                                <div class="rtz-puf">-->
<!--                                    <h4>-->
<!--                                        <span>Upgrade to</span>-->
<!--                                        Starter-->
<!--                                    </h4>-->
<!--                                </div>&lt;!&ndash; /.rtz-puf &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.rtz-plan-upgrade-face &ndash;&gt;-->
<!--                            <div class="rtz-plan-select">-->
<!--                                <div class="rtz-ps-item">-->
<!--                                    <div class="nv-form-item nv-radio-item">-->
<!--                                        <label>-->
<!--                                            <span class="nri-radio-port">-->
<!--                                                <input type="radio" name="name1" checked>-->
<!--                                                <span class="nri-radio-visual"></span>-->
<!--                                            </span>&lt;!&ndash; /.nri-radio-port &ndash;&gt;-->
<!--                                        </label>-->
<!--                                    </div>&lt;!&ndash; /nv-form-item  &ndash;&gt;-->
<!--                                    <p>Monthly Plan</p>-->
<!--                                    <span>&euro; 199 billed monthly</span>-->
<!--                                </div>&lt;!&ndash; /.rtz-ps-item &ndash;&gt;-->
<!--                                <div class="rtz-ps-item">-->
<!--                                    <div class="nv-form-item nv-radio-item">-->
<!--                                        <label>-->
<!--                                            <span class="nri-radio-port">-->
<!--                                                <input type="radio" name="name1">-->
<!--                                                <span class="nri-radio-visual"></span>-->
<!--                                            </span>&lt;!&ndash; /.nri-radio-port &ndash;&gt;-->
<!--                                        </label>-->
<!--                                    </div>&lt;!&ndash; /nv-form-item &ndash;&gt;-->
<!--                                    <p>Annual Plan</p>-->
<!--                                    <span>&euro; 2149 billed annually. <strong>Save 10%</strong></span>-->
<!--                                </div>&lt;!&ndash; /.rtz-ps-item &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.rtz-plan-select &ndash;&gt;-->
<!--                            <p class="spaceless">Select the credit card to charge the € 199 to:</p>-->
<!--                            <div class="rtz-quasi-select">-->
<!--                                <div class="rtz-cc-bar">-->
<!--                                    <div class="rtz-cc-mean">Credit Card</div>-->
<!--                                    <div class="rtz-cc-meta">-->
<!--                                        <div class="rtz-cc-type">-->
<!--                                            <p>Default</p>-->
<!--                                            <span class="rtz-ccard visa"></span>-->
<!--                                        </div>&lt;!&ndash; /.rtz-cc-type &ndash;&gt;-->
<!--                                        <p class="rtz-cc-num">**** **** **** 1234</p>-->
<!--                                        <p class="rtz-cc-exp">Expires 02/24</p>-->
<!--                                    </div>&lt;!&ndash; /.rtz-cc-meta &ndash;&gt;-->
<!--                                </div>&lt;!&ndash; /.rtz-cc-bar &ndash;&gt;-->
<!--                                <span class="rtz-qs-trigger"></span>-->
<!--                            </div>&lt;!&ndash; /.rtz-quasi-select &ndash;&gt;-->
<!--                            <p><a href class="rtz-link small dark">Add payment method</a></p>-->
<!--                            <div class="nv-btn-box flow-end gap-30">-->
<!--                                <a href class="nv-btn txt medium quaternary">Cancel</a>-->
<!--                                <a href class="nv-btn secondary medium">Proceed</a>-->
<!--                            </div>&lt;!&ndash; /.nv-btn-box flow-end gap-6 &ndash;&gt;-->
<!--                            <div class="nv-divider desk-small"></div>-->
<!--                            <p class="rtz-small-txt gray align-right">-->
<!--                                The next monthly recurring charge<br>will be made on <strong>Oct 17, 2023</strong>-->
<!--                            </p>-->
<!--                            &lt;!&ndash; Modal Content &ndash;&gt;-->

<!--                        </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                    <div class="nv-column desk-6" style="background-color: #fff;">-->
<!--                        <div class="nv-column-content" style="padding: 20px;">-->

<!--                            &lt;!&ndash; Modal Content  'Plan Details' &ndash;&gt;-->
<!--                            <div class="rtz-plan-current">-->
<!--                                <div class="rtz-pc-type-level">-->
<!--                                    <p>Publishers</p>-->
<!--                                    <h3>Advanced</h3>-->
<!--                                </div>&lt;!&ndash; /.rtz-pc-level &ndash;&gt;-->
<!--                                <div class="rtz-pc-amount-interval">-->
<!--                                    <h3>&euro; 199</h3>-->
<!--                                    <p>Monthly subscription</p>-->
<!--                                </div>&lt;!&ndash; /.rtz-pc-price &ndash;&gt;-->
<!--                            </div>&lt;!&ndash; /.rtz-plan-current &ndash;&gt;-->

<!--                            <div class="rtz-plan-features">-->
<!--                                <h3>Plan Features</h3>-->
<!--                                <ul class="rtz-ul-checkers">-->
<!--                                    <li>Ad Integration</li>-->
<!--                                    <li>Revenue Sharing</li>-->
<!--                                    <li>Ad Formats</li>-->
<!--                                    <li>Customization</li>-->
<!--                                    <li>Lorem ipsum</li>-->
<!--                                    <li>Lorem ipsum</li>-->
<!--                                    <li>In voluptate velit esse</li>-->
<!--                                    <li>Consectetur adipiscing elit</li>-->
<!--                                    <li>Duis aute irure</li>-->
<!--                                </ul>-->
<!--                            </div>&lt;!&ndash; /.rtz-plan-features &ndash;&gt;-->

<!--                            <div class="rtz-billing-payment-status">-->
<!--                                <h3>Billing &amp; Payment</h3>-->
<!--                                <dl>-->
<!--                                    <dt>Renewal date</dt>-->
<!--                                    <dd>Oct 17, 2023</dd>-->
<!--                                </dl>-->
<!--                            </div>&lt;!&ndash; /.rtz-billing-payment-status &ndash;&gt;-->
<!--                            &lt;!&ndash; Modal Content &ndash;&gt;-->


<!--                        </div>&lt;!&ndash; /.nv-column-content &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.nv-column &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.nv-row &ndash;&gt;-->

<!--                <br>-->

<!--                &lt;!&ndash; SUBSCRIPTIONS &ndash;&gt;-->
<!--                <div class="rtz-subscriptions-wrap">-->

<!--                    <div class="rtz-subscription-card type">-->
<!--                        <h3>Event organisers</h3>-->
<!--                        <p>Event subscription plans grant subscribers access to a variety of events or activities. These can include concerts, sports games, theater performances, conferences, workshops, webinars, or streaming content.</p>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Free Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 0<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Up to 300 delegates</li>-->
<!--                            <li>1 Event</li>-->
<!--                            <li>Full access to the Riterz platform</li>-->
<!--                            <li>Key platform features</li>-->
<!--                        </ul>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Advanced Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 199<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Up to 500 delegates</li>-->
<!--                            <li>1 Event</li>-->
<!--                            <li>Everything in Free, plus:</li>-->
<!--                            <li>Add parallel streams</li>-->
<!--                        </ul>-->
<!--                        <p><a href title="more">See more</a></p>-->
<!--                        <a class="nv-btn secondary small">Upgrade plan</a>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Premium Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 499<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Up to 1000 delegates</li>-->
<!--                            <li>1 Event</li>-->
<!--                            <li>Everything in Advanced, plus:</li>-->
<!--                            <li>Unlock all networking features</li>-->
<!--                        </ul>-->
<!--                        <p><a href title="more">See more</a></p>-->
<!--                        <a class="nv-btn secondary small">Upgrade plan</a>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->

<!--                    <div class="rtz-subscription-card type">-->
<!--                        <h3>Publishers</h3>-->
<!--                        <p>These plans enable publishers to earn revenue by displaying advertisements from advertisers on their platforms.</p>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Free Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 0<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Ad Integration</li>-->
<!--                            <li>Revenue Sharing</li>-->
<!--                        </ul>-->
<!--                        <a class="nv-btn quaternary small">Upgrade plan</a>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Advanced Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 199<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Ad Integration</li>-->
<!--                            <li>Revenue Sharing</li>-->
<!--                            <li>Ad Formats</li>-->
<!--                            <li>Customisation</li>-->
<!--                        </ul>-->
<!--                        <p><a href title="more">See more</a></p>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card active">-->
<!--                        <h5>Premium Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 299<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Ad integration</li>-->
<!--                            <li>1 Revenue Sharing</li>-->
<!--                            <li>Everything in Advanced, plus:</li>-->
<!--                            <li>Performance Metrics</li>-->
<!--                        </ul>-->
<!--                        <p><a href title="more">See more</a></p>-->
<!--                        <a class="nv-btn secondary small">Upgrade plan</a>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->

<!--                    <div class="rtz-subscription-card type">-->
<!--                        <h3>Advertisers</h3>-->
<!--                        <p>These plans are designed to help advertisers reach their target audience effectively and efficiently.</p>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Free Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 0<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Ad Placement</li>-->
<!--                        </ul>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Advanced Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 159<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Ad Placement</li>-->
<!--                            <li>Advanced targeting options</li>-->
<!--                            <li>Everything in Free, plus:</li> -->
<!--                            <li>Campaign analytics and reports</li>-->
<!--                        </ul>-->
<!--                        <p><a href title="more">See more</a></p>-->
<!--                        <a class="nv-btn secondary small">Upgrade plan</a>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                    <div class="rtz-subscription-card">-->
<!--                        <h5>Premium Plan</h5>-->
<!--                        <p class="rtz-subs-price">&euro; 599<span>/month</span></p>-->
<!--                        <ul>-->
<!--                            <li>Ad Placement</li>-->
<!--                            <li>Advanced targeting options</li>-->
<!--                            <li>Everything in Premium, plus: Access to different ad formats</li>-->
<!--                        </ul>-->
<!--                        <p><a href title="more">See more</a></p>-->
<!--                        <a class="nv-btn secondary small">Upgrade plan</a>-->
<!--                    </div>&lt;!&ndash; /.rtz-subscription-card &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.rtz-subscriptions-wrap &ndash;&gt;-->

<!--                <br><br><br>-->

<!--                &lt;!&ndash; BILLING &ndash;&gt;-->
<!--                <div class="rtz-config-pane">-->
<!--                    <h3>Payments Method</h3>-->

<!--                    <div class="rtz-table-wrap">-->
<!--                        <table class="rtz-payment-methods">-->
<!--                            <tr>-->
<!--                                <td class="ccard">Credit Card</td>-->
<!--                                <td class="ccard-type">-->
<!--                                    <p class="defo">default</p>-->
<!--                                    <span class="rtz-ccard visa"></span>-->
<!--                                </td>-->
<!--                                <td class="ccard-num">**** **** **** 1234</td>-->
<!--                                <td class="ccard-del"><a href title="Delete Credit Card entry"></a></td>-->
<!--                                <td class="ccard-edit"><button type="button" title="Edit Credit Card" class="nv-btn normal small">Edit Card</button></td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td class="ccard">Credit Card</td>-->
<!--                                <td class="ccard-type">-->
<!--                                    <span class="rtz-ccard master"></span>-->
<!--                                </td>-->
<!--                                <td class="ccard-num">**** **** **** 2865</td>-->
<!--                                <td class="ccard-del"><a href title="Delete Credit Card entry"></a></td>-->
<!--                                <td class="ccard-edit"><button type="button" title="Edit Credit Card" class="nv-btn normal small">Edit Card</button></td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td class="ccard">Credit Card</td>-->
<!--                                <td class="ccard-type">-->
<!--                                    <span class="rtz-ccard visa"></span>-->
<!--                                </td>-->
<!--                                <td class="ccard-num">**** **** **** 2865</td>-->
<!--                                <td class="ccard-del"><a href title="Delete Credit Card entry"></a></td>-->
<!--                                <td class="ccard-edit"><button type="button" title="Edit Credit Card" class="nv-btn normal small">Edit Card</button></td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td class="rtz-add-pm" colspan="5"><a href class="rtz-link small dark">Add payment method</a></td>-->
<!--                            </tr>-->
<!--                        </table>-->
<!--                    </div>&lt;!&ndash; /.rtz-table-wrap &ndash;&gt;-->

<!--                    <h3>Billing Information</h3>-->
<!--                    <div class="rtz-billing-info">-->
<!--                        <a href title="Edit Billing Information" class="nv-btn normal small">Edit</a>-->
<!--                        <p>-->
<!--                            <strong>Address</strong>-->
<!--                            123 Hauptstrasse<br>-->
<!--                            8001 Zurich,<br>-->
<!--                            CH<br>-->
<!--                            +41 22 123 45 67-->
<!--                        </p>-->
<!--                        <p>-->
<!--                            <strong>Billing Contact</strong>-->
<!--                            accounts@aborganisation.com-->
<!--                        </p>-->
<!--                    </div>&lt;!&ndash; /.rtz-billing-info &ndash;&gt;-->

<!--                    <h3>Payment History</h3>-->
<!--                    <div class="rtz-table-wrap">-->
<!--                        <table class="rtz-payment-history">-->
<!--                            <tr>-->
<!--                                <th>Price</th>-->
<!--                                <th>Type</th>-->
<!--                                <th>Status</th>-->
<!--                                <th>Creation Date</th>-->
<!--                                <th></th>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td>$11.11 USD</td>-->
<!--                                <td>Type</td>-->
<!--                                <td>Paid</td>-->
<!--                                <td>2023-08-22 08:14</td>-->
<!--                                <td><a href>Get Invoice</a></td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td>$44.50 USD</td>-->
<!--                                <td>Type</td>-->
<!--                                <td>Paid</td>-->
<!--                                <td>2023-09-11 08:14</td>-->
<!--                                <td><a href>Get Invoice</a></td>-->
<!--                            </tr>-->
<!--                        </table>&lt;!&ndash; /.rtz-payment-history &ndash;&gt;-->
<!--                    </div>&lt;!&ndash; /.rtz-table-wrap &ndash;&gt;-->
<!--                </div>&lt;!&ndash; /.rtz-config-pane &ndash;&gt;-->

<!--                <br><br>-->

            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->
</div><!-- /.rtz-config-frame -->
