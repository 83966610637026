import { Component } from '@angular/core';

@Component({
    selector: 'app-subs-billing-page',
    templateUrl: './config-subs-billing.component.html'
})
export class ConfigSubsBillingComponent {
    showSideNav: boolean = true;
    constructor() { }

}
