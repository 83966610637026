import { Component } from '@angular/core';

@Component({
    selector: 'audience-audit-page',
    templateUrl: './audience-audit.component.html'
})
export class AudienceAuditComponent {

    constructor() { }

}
