<div class="nv-modal" [ngClass]="size">
    <div class="nv-modal-header confirm" *ngIf="showTitle">
        <h4 class="nv-modal-title">{{ title }}</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <p>{{ body }}</p>
    </div>
    <div class="nv-modal-footer">
        <div class="rt-btn-box gap-10 flow-end">
            <button type="button"
                    class="rt-btn filled success small"
                    *ngFor="let button of buttons"
                    (click)="onClick(button)">{{ button.text }}</button>
        </div><!-- /.nv-btn-box -->
    </div>
</div>
