<div class="rtz-config-frame" [class.side-menu-collapsed]="showSideNav">
    <header class="rtz-cf-header">
        <div class="rtz-cf-logo-port">
            <figure>
                <a href title="Riterz configurator"><img src="../../assets/riterz-logo.svg" alt="Riterz.com"></a>
            </figure>
        </div><!-- /.rtz-cf-logo-port -->
        <div class="rtz-cf-user-settings">
            <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure><img src="../../assets/avatar-placeholder.png" alt="User Name"></figure>
                    <h5>Lara Lancaster</h5>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Profile" class="rtz-ico-profile">Profile</a></li>
                        <li><a href title="Privacy" class="rtz-ico-privacy">Privacy</a></li>
                        <li><a href title="Legal" class="rtz-ico-legal">Legal</a></li>
                        <li><a href title="Sign Out" class="rtz-ico-signout">Sign Out</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
        </div><!-- /.rtz-cf-user-settings -->
    </header><!-- /.rtz-cf-header -->
    <div class="rtz-config-body">
        <aside class="rtz-config-side-panel">
            <span class="rtz-side-panel-toggle" (click)="showSideNav = !showSideNav"></span>
            <div class="rtz-config-side-panel-up">
                <div class="cm-organization">
                    <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                        <div class="cm-organization-logo">
                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                        </div><!-- /.cm-organization-logo -->
                        <div class="cm-organization-label">
                            <h3>ENP</h3>
                            <p>Organization</p>
                        </div><!-- /.cm-organization-label -->
                    </div><!-- /.cm-current-organisation -->
                    <div class="cm-organisation-list">
                        <div class="cm-organization-search">
                            <input type="text" name="" placeholder="Search for organizations…">
                        </div><!-- /.cm-organization-search -->
                        <nav>
                            <ul>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                            </ul>
                        </nav>
                        <a href="" class="cm-add">
                            Add organization
                        </a><!-- /.cm-add -->
                    </div><!-- /.cm-organisation-list -->
                </div><!-- /.cm-organisation -->

                <h3 class="rtz-ui-menu-label" style="display: none;">Admin panel</h3>

                <nav class="rtz-ui-menu">
                    <ul>
                        <li><a href title="Dashboard" class="rtz-ico-dashboard">Dashboard</a></li>
                        <li><a href title="File Manager" class="rtz-ico-file-manager">File Manager</a></li>
                        <li><a href title="News Manager" class="rtz-ico-news-manager active">News Manager</a></li>
                        <li><a href title="Events" class="rtz-ico-events">Events</a></li>
                        <li><a href title="Advertising" class="rtz-ico-advertising">Advertising</a></li>
                        <li><a href title="Mobile App" class="rtz-ico-mobile-app">Mobile App</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-config-side-panel-up -->
            <div class="rtz-config-side-panel-down">
                <div class="rtz-ui-global-settings">
                    <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                        <nav class="rtz-ui-menu quasi">
                            <ul>
                                <li><a href title="Settings" class="rtz-ico-config-settings">Settings</a></li>
                            </ul>
                        </nav><!-- /.rtz-ui-menu quasi -->
                        <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                            <ul>
                                <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                                <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                                <li><a href title="Billing" class="rtz-ico-billing">Billing</a></li>
                                <li><a href title="Organisation Settings" class="rtz-ico-organisation">Organisation Settings</a></li>
                            </ul>
                        </nav><!-- /.rtz-ui-menu -->
                    </div><!-- /.rtz-ui-settings-box -->
                </div><!-- /.rtz-ui-global-settings -->
            </div><!-- /.rtz-config-side-panel-down -->
        </aside><!-- /.rtz-config-side-panel -->
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <section class="nv-row config-plates gap-30 clean small-spaced-top spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <header class="rtz-config-top-dash">
                                <h1>Organisations</h1>
                                <div class="rtz-organizations-dash-tools">
                                    <a href class="nv-btn secondary medium" data-icon-pl="&#xe927;">Invite</a>
                                </div><!-- /.rtz-organizations-dash-tools -->
                            </header><!-- /rtz-config-top-dash -->
                            <div class="rtz-organizations-search-n-sort">
                                <div class="rtz-organizations-search-port">
                                    <label class="rtz-organizations-sp-input rtz-mellow">
                                        <input type="search" name placeholder="Search">
                                    </label><!-- /.rtz-organizations-sp-input -->
                                    <div class="rtz-organizations-sp-filter">
                                        <a class="filter" href title="Filters">Filters</a>
                                        <a href title="Clear">Clear</a>
                                    </div><!-- /.rtz-organizations-sp-filter -->
                                </div><!-- /.rtz-organizations-search-port -->
                                <div class="rtz-organizations-search-sort rtz-mellow">
                                    <select name="sortex">
                                        <option>Sort by</option>
                                        <option>Date</option>
                                        <option>Popularity</option>
                                        <option>Alphabet</option>
                                    </select>
                                </div><!-- /.rtz-organizations-search-sort -->
                                <div class="rtz-organizations-search-filters">
                                    <p>
                                        <span>Cat 1</span>
                                        <a href title="delete"></a>
                                    </p>
                                    <p>
                                        <span>Dolor sit</span>
                                        <a href title="delete"></a>
                                    </p>
                                    <p>
                                        <span>Extremely loooong filter set right here</span>
                                        <a href title="delete"></a>
                                    </p>
                                </div><!-- /.rtz-organizations-search-filters -->
                            </div><!-- /.rtz-organizations-search-n-sort -->
                        </div>
                    </div><!-- /nv-column -->
                </section><!-- /nv-row -->
                <aside class="rtz-config-tools-panel on"><!-- < toggle class 'on' -->
                    <div class="rtz-config-tools-panel-sleeve">
                        <header class="rtz-ctp-header">
                            <h2>Filters</h2>
                            <a class="rtz-close-ctp" title="Close filters"></a>
                        </header><!-- /.rtz-ctp-header -->
                        <div class="rtz-ctp-inputs-column">
                            <div class="nv-form-item nv-select-item">
                                <label>
                                    <select>
                                        <option>Status</option>
                                    </select>
                                    <span class="label-title">Status</span>
                                </label>
                            </div><!-- /nv-form-item -->
                            <div class="nv-form-item nv-select-item">
                                <label>
                                    <select>
                                        <option>Categories</option>
                                    </select>
                                    <span class="label-title">Categories</span>
                                </label>
                            </div><!-- /nv-form-item -->
                            <div class="nv-form-item nv-select-item">
                                <label>
                                    <select>
                                        <option>Something</option>
                                    </select>
                                    <span class="label-title">Something</span>
                                </label>
                            </div><!-- /nv-form-item -->
                        </div><!-- /.rtz-ctp-inputs-column -->
                    </div><!-- /.rtz-config-tools-panel-sleeve -->
                    <footer class="rtz-ctp-footer">
                        <div class="rtz-ctp-filter-ctrl">
                            <a class="nv-btn medium quaternary txt" href title="Clear Filters">Clear Filters</a>
                            <a class="nv-btn small quaternary" href title="Apply Filters">Apply Filters</a>
                        </div><!-- /.rtz-ctp-filter-ctrl -->
                    </footer><!-- /.rtz-ctp-footer -->
                </aside><!-- /.rtz-config-tools-panel -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->
</div><!-- /.rtz-config-frame -->
