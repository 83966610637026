<div class="rtz-config-frame">
    <header class="rtz-cf-header">
        <div class="rtz-cf-logo-port">
            <figure>
                <a href title="Riterz configurator"><img src="../../assets/riterz-logo.svg" alt="Riterz.com"></a>
            </figure>
        </div><!-- /.rtz-cf-logo-port -->
        <nav class="rtz-cf-header-menu">
            <ul>
                <li><a href title="Dashboard" class="active">Dashboard</a></li>
                <li><a href title="Files">Files</a></li>
                <li><a href title="News">News</a></li>
                <li><a href title="Advertising">Advertising</a>
                    <ul>
                        <li><a href title="title">Sub item 1</a></li>
                        <li><a href title="title">Sub item 2</a></li>
                        <li><a href title="title">Sub item 3</a></li>
                        <li><a href title="title">Sub item 4</a></li>
                    </ul>
                </li>
                <li><a href title="Sponsors">Sponsors</a></li>
                <li><a href title="Events">Events</a>
                    <ul>
                        <li>
                            <a href title="Paradigm Shifting New Horizonts">
                                <img src="../../assets/avatar-dummy.jpg" alt="Paradigm image">
                                <span>Paradigm Shifting New Horizonts</span>
                            </a>
                        </li>
                        <li>
                            <a href title="AB Clone 2024">
                                <img src="../../assets/logo-event-esicm.png" alt="AB Clone image">
                                <span>AB Clone 2024</span>
                            </a>
                        </li>
                        <li class="alt"><a href title="View all events">View all events</a></li>
                    </ul>
                </li>
                <li><a href title="Websites">Websites</a></li>
            </ul>
        </nav><!-- /.rtz-cf-header-menu -->
        <div class="rtz-cf-user-settings">
            <div class="rtz-ui-settings-box org"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure><img src="../../assets/riterz-textless-logo.svg" alt="Company Name"></figure>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                        <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                        <li><a href title="Billing" class="rtz-ico-billing">Billing</a></li>
                        <li><a href title="Step by step guide" class="rtz-ico-step-by-step">Step by step guide</a></li>
                        <li><a href title="Organisation Settings" class="rtz-ico-organisation">Organisation Settings</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
            <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure><img src="../../assets/avatar-placeholder.png" alt="User Name"></figure>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Profile" class="rtz-ico-profile">Profile</a></li>
                        <li><a href title="Privacy" class="rtz-ico-privacy">Privacy</a></li>
                        <li><a href title="Legal" class="rtz-ico-legal">Legal</a></li>
                        <li><a href title="Sign Out" class="rtz-ico-signout">Sign Out</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
        </div><!-- /.rtz-cf-user-settings -->
    </header><!-- /.rtz-cf-header -->
    <div class="rtz-config-body">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">

                <div class="rtz-dummy-dash">
                    <h2>CAMPAIGN 1 | <strong>LOCAL EDUCATED EXPATS</strong></h2>
                    <div class="rtz-dashboard-data-box">
                        <div class="rtz-db-card purple">
                            <h3>Total Spent (CHF)</h3>
                            <p class="rtz-db-value">483.08</p>
                            <p class="trend"></p>
                        </div><!-- /.rtz-db-card -->
                        <div class="rtz-db-card">
                            <h3>Total Impressions</h3>
                            <p class="rtz-db-value">7,078</p>
                            <p class="trend down">-3%</p>
                            <span class="rtz-db-ico impressions"></span>
                        </div><!-- /.rtz-db-card -->
                        <div class="rtz-db-card">
                            <h3>Click-Through Rate (CTR)</h3>
                            <p class="rtz-db-value">0.89%</p>
                            <p class="trend up">+43%</p>
                            <span class="rtz-db-ico ctr"></span>
                        </div><!-- /.rtz-db-card -->
                        <div class="rtz-db-card">
                            <h3>Total Clicks</h3>
                            <p class="rtz-db-value">63</p>
                            <p class="trend"></p>
                            <span class="rtz-db-ico total-clicks"></span>
                        </div><!-- /.rtz-db-card -->
                        <div class="rtz-db-card"></div>
                        <div class="rtz-db-card">
                            <h3>Cost per thousand (CMP)</h3>
                            <p class="rtz-db-value">68.25</p>
                            <p class="trend up">+8%</p>
                            <span class="rtz-db-ico cost"></span>
                        </div><!-- /.rtz-db-card -->
                        <div class="rtz-db-card">
                            <h3>Conversion Rate</h3>
                            <p class="rtz-db-value">0%</p>
                            <p class="trend up">+13%</p>
                            <span class="rtz-db-ico conv-rate"></span>
                        </div><!-- /.rtz-db-card -->
                        <div class="rtz-db-card">
                            <h3>Total Conversions</h3>
                            <p class="rtz-db-value">0</p>
                            <p class="trend"></p>
                            <span class="rtz-db-ico conv-total"></span>
                        </div><!-- /.rtz-db-card -->
                    </div><!-- /.rtz-dashboard-data-box -->
                </div><!-- /.rtz-dummy-dash -->



                <div class="rtz-dummy-dash">
                    <h2>LOCAL EDUCATED EXPATS | <strong>AD 1-A</strong></h2>
                    <div class="rtz-dashboard-data-w-img">
                        <div class="rtz-dd-cards">
                            <div class="rtz-db-card">
                                <h3>Total Impressions</h3>
                                <p class="rtz-db-value">7,078</p>
                                <p class="trend down">-3%</p>
                                <span class="rtz-db-ico impressions"></span>
                            </div><!-- /.rtz-db-card -->
                            <div class="rtz-db-card">
                                <h3>Click-Through Rate (CTR)</h3>
                                <p class="rtz-db-value">0.89%</p>
                                <p class="trend up">+43%</p>
                                <span class="rtz-db-ico ctr"></span>
                            </div><!-- /.rtz-db-card -->
                            <div class="rtz-db-card">
                                <h3>Total Clicks</h3>
                                <p class="rtz-db-value">63</p>
                                <p class="trend"></p>
                                <span class="rtz-db-ico total-clicks"></span>
                            </div><!-- /.rtz-db-card -->
                            <div class="rtz-db-card">
                                <h3>Total Conversions</h3>
                                <p class="rtz-db-value">0</p>
                                <p class="trend"></p>
                                <span class="rtz-db-ico conv-total"></span>
                            </div><!-- /.rtz-db-card -->
                        </div><!-- /.rtz-dd-cards -->
                        <figure class="rtz-dd-img">
                            <img src="https://service.e-materials.com/storage/resources/navus_ag/das/dashboard_dummy_img_alt.jpg" alt="">
                        </figure><!-- /.rtz-dd-img -->
                        <div class="rtz-dd-footer">
                            <h2>AD COPY</h2>
                            <p>Visit the Lotus stand at ‘A Taste of Belgium’ and experience the Biscoff difference with every sip! Nov 18th, 10-4pm, Embassy of Belgium - Residence of the Ambassador – 29 Boshoff Ave, Newlands.</p>
                            <p><a href title="">Discover How a Small Biscuit Can Taste So Great! See you at 'A Taste of Belgium’!</a></p>
                        </div><!-- /.rtz-dd-footer -->
                    </div><!-- /.rtz-dashboard-data-w-img -->
                </div><!-- /.rtz-dummy-dash -->

            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->
</div><!-- /.rtz-config-frame -->
