import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, of } from 'rxjs';
import { Conference } from '../classes/conference';
import { map } from 'rxjs/operators';

@Injectable()
export class ConferenceService {
  public currentConferenceSubject = new BehaviorSubject<Conference>(null as Conference);
  public currentConference = this.currentConferenceSubject.asObservable();

  constructor(
    private apiService: ApiService,
  ) {
  }

  getConferences(params: any = {}) {
    return this.apiService.get(`conferences`, params);
  }

  getConference(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}`, params);
  }

  updateConference(conferenceId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}`, params);
  }

  createConference(params: any = {}) {
    return this.apiService.post(`conferences`, params);
  }

  deleteConference(conferenceId: number) {
    return this.apiService.delete(`conferences/${conferenceId}`);
  }

  getOrganizationConferences(organizationId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/conferences`, params);
  }

  getOrganizationConference(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/conferences/${conferenceId}`, params);
  }

  updateOrganizationConference(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/conferences/${conferenceId}`, params);
  }

  createOrganizationConference(organizationId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/conferences`, params);
  }

  deleteOrganizationConference(organizationId: number, conferenceId: number) {
    return this.apiService.delete(`organizations/${organizationId}/conferences/${conferenceId}`);
  }

  importOrganizationConferenceDemo(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/conferences/${conferenceId}/demo`, params);
  }

  removeOrganizationConferenceDemo(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/conferences/${conferenceId}/demo`, params);
  }

  getOrganizationConferenceToDo(organizationId: number, conferenceId: number, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/conferences/${conferenceId}/todo`, params);
  }

  updateOrganizationConferenceToDo(organizationId: number, conferenceId: number, toDoId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}/conferences/${conferenceId}/todo/${toDoId}`, params);
  }

  getConferenceDashboard(conferenceId: number) {
    return this.apiService.get(`conferences/${conferenceId}/dashboard`);
  }

  getConferenceTicket(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/ticket`, params);
  }

  getConferenceTicketTypes(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/ticket_types`, params);
  }

  getConferenceTicketType(conferenceId: number, ticketTypeId: string, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/ticket_types/${ticketTypeId}`, params);
  }

  updateConferenceTicketType(conferenceId: number, ticketTypeId: string, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/ticket_types/${ticketTypeId}`, params);
  }

  createConferenceTicketType(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/ticket_types`, params);
  }

  deleteConferenceTicketType(conferenceId: number, ticketTypeId: string) {
    return this.apiService.delete(`conferences/${conferenceId}/ticket_types/${ticketTypeId}`);
  }

  createConferenceTicket(conferenceId: number, ticketTypeId: string, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/ticket_types/${ticketTypeId}/tickets`, params);
  }

  getConferenceTicketTypeCodes(conferenceId: number, ticketTypeId: string) {
    return this.apiService.get(`conferences/${conferenceId}/ticket_types/${ticketTypeId}/ticket_codes`);
  }

  getConferenceTicketTypeCode(conferenceId: number, conferenceTicketTypeId: string, conferenceTicketCodeId: string) {
    return this.apiService.get(`conferences/${conferenceId}/ticket_types/${conferenceTicketTypeId}/ticket_codes/${conferenceTicketCodeId}`);
  }

  updateConferenceTicketTypeCode(conferenceId: number, conferenceTicketTypeId: string, conferenceTicketCodeId: string, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/ticket_types/${conferenceTicketTypeId}/ticket_codes/${conferenceTicketCodeId}`, params);
  }

  createConferenceTicketTypeCode(conferenceId: number, conferenceTicketTypeId: string, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/ticket_types/${conferenceTicketTypeId}/ticket_codes`, params);
  }

  deleteConferenceTicketTypeCode(conferenceId: number, conferenceTicketTypeId: string, conferenceTicketCodeId: string) {
    return this.apiService.delete(`conferences/${conferenceId}/ticket_types/${conferenceTicketTypeId}/ticket_codes/${conferenceTicketCodeId}`);
  }

  checkConferenceTicketTypeCode(conferenceId: number, ticketTypeId: string, code: string) {
    return this.apiService.get(`conferences/${conferenceId}/ticket_types/${ticketTypeId}/check/${code}`);
  }

  requestAccess(conferenceId: number, params: any = {}) {
    return this.apiService.get('conferences/' + conferenceId + '/request_access', params);
  }

  grantAccess(conferenceId: number, params: any = {}) {
    return this.apiService.get('conferences/' + conferenceId + '/grant_access', params);
  }

  grantAccessWithCode(conferenceId: number, code: string, params: any = {}) {
    return this.apiService.get('conferences/' + conferenceId + '/grant_access_with_code/' + code, params);
  }

  getProgram(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/program`, params);
  }

  createProgram(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/program`, params);
  }

  getConferencingToken(conferenceId: number, room: string) {
    return this.apiService.get(`conferences/${conferenceId}/token/${room}`);
  }

  setCurrentConference(conferenceId: number, reload: boolean = false) {
    if (conferenceId) {
      if (!reload) {
        if (this.currentConferenceSubject.value && this.currentConferenceSubject.value.id === +conferenceId) {
          return of(null);
        }
      }

      return this.apiService.get(`conferences/${conferenceId}`)
        .pipe(map(response => {
          this.currentConferenceSubject.next(response.data);
          return response.data;
        }));
    } else {
      this.currentConferenceSubject.next(null);
      return of(null);
    }
  }

  getBannerTotalImpressions(conferenceId: number, dateFrom: string, dateTo: string) {
    const params = { start: dateFrom, end: dateTo };
    return this.apiService.get(`dashboard/banner_total_impressions/conference/${conferenceId}`, params)
      .pipe(
        map((response: any) => {
          // Format Date
          for (let i = 0; i < response.data.xValues.length; i++) {
            const dateComponents = response.data.xValues[i].split('-');
            response.data.xValues[i] = dateComponents[2] + '.' + dateComponents[1] + '.' + dateComponents[0];
          }
          return response;
        })
      );
  }

  getBannerTotalInteractions(conferenceId: number, dateFrom: string, dateTo: string) {
    const params = { start: dateFrom, end: dateTo };
    return this.apiService.get(`dashboard/banner_total_interactions/conference/${conferenceId}`, params)
      .pipe(
        map((response: any) => {
          // Format Date
          for (let i = 0; i < response.data.xValues.length; i++) {
            const dateComponents = response.data.xValues[i].split('-');
            response.data.xValues[i] = dateComponents[2] + '.' + dateComponents[1] + '.' + dateComponents[0];
          }
          return response;
        })
      );
  }

  getAccountRegistrationData(conferenceId: number, dateFrom: string, dateTo: string) {
    const params = { start: dateFrom, end: dateTo };
    return this.apiService.get(`dashboard/registrations/conference/${conferenceId}`, params)
      .pipe(
        map((response: any) => {
          // Format Date
          for (let i = 0; i < response.data.xValues.length; i++) {
            const dateComponents = response.data.xValues[i].split('-');
            response.data.xValues[i] = dateComponents[2] + '.' + dateComponents[1] + '.' + dateComponents[0];
          }
          return response;
        })
      );
  }

  getPresentationData(conferenceId: number, dateFrom: string, dateTo: string) {
    const params = { start: dateFrom, end: dateTo };
    return this.apiService.get(`dashboard/presentations/conference/${conferenceId}`, params)
      .pipe(
        map((response: any) => {
          // Format Date
          for (let i = 0; i < response.data.xValues.length; i++) {
            const dateComponents = response.data.xValues[i].split('-');
            response.data.xValues[i] = dateComponents[2] + '.' + dateComponents[1] + '.' + dateComponents[0];
          }
          return response;
        })
      );
  }

  getMaterialsData(conferenceId: number, dateFrom: string, dateTo: string) {
    const params = { start: dateFrom, end: dateTo };
    return this.apiService.get(`dashboard/materials_neo/conference/${conferenceId}`, params)
      .pipe(
        map((response: any) => {
          // Format Date
          for (let i = 0; i < response.data.xValues.length; i++) {
            const dateComponents = response.data.xValues[i].split('-');
            response.data.xValues[i] = dateComponents[2] + '.' + dateComponents[1] + '.' + dateComponents[0];
          }
          return response;
        })
      );
  }

  getRecommendationsData(conferenceId: number, dateFrom: string, dateTo: string) {
    const params = { start: dateFrom, end: dateTo };
    return this.apiService.get(`dashboard/recommendation_clicks/conference/${conferenceId}`, params)
      .pipe(
        map((response: any) => {
          // Format Date
          for (let i = 0; i < response.data.xValues.length; i++) {
            const dateComponents = response.data.xValues[i].split('-');
            response.data.xValues[i] = dateComponents[2] + '.' + dateComponents[1] + '.' + dateComponents[0];
          }
          return response;
        })
      );
  }

  getGeneral(conferenceId: any, dateFrom: string, dateTo: string) {
    return this.apiService.get(`conferences/${conferenceId}/dashboard`, { start: dateFrom, end: dateTo })
      .pipe(map(data => {

        data.presentationsChart = {
          'data': [],
          'labels': []
        };

        for (const [key, value] of Object.entries(data.presentations)) {
          if (key !== 'total') {
            data.presentationsChart['data'].push(value);
            data.presentationsChart['labels'].push(key);
          }

        }

        data.appChart = {
          'data': [data.ios_app_downloads, data.android_app_downloads],
          'labels': ['ios_app_downloads', 'android_app_downloads']
        };

        return data;
      }));
  }

  getConferenceMessages(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/messages`, params);
  }

  getConferenceMessagesMine(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/messages/mine`, params);
  }

  createConferenceMessage(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/messages`, params);
  }

  updateConferenceMessage(conferenceId: number, messageId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/messages/${messageId}`, params);
  }

  getConferenceDates(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/dates`, params);
  }

  requestCertificate(conferenceId: number) {
    return this.apiService.post(`conferences/${conferenceId}/request_certificate`);
  }

  getConferenceOffAirs(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/offair`, params);
  }

  getConferenceOffAir(conferenceId: number, offAirId: number) {
    return this.apiService.get(`conferences/${conferenceId}/offair/${offAirId}`);
  }

  createConferenceOffAir(conferenceId: number, params: any = {}) {
    return this.apiService.post(`conferences/${conferenceId}/offair`, params);
  }

  updateConferenceOffAir(conferenceId: number, offAirId: number, params: any = {}) {
    return this.apiService.put(`conferences/${conferenceId}/offair/${offAirId}`, params);
  }

  deleteConferenceOffAir(conferenceId: number, offAirId: number, params: any = {}) {
    return this.apiService.delete(`conferences/${conferenceId}/offair/${offAirId}`, params);
  }

  getDelegateTemplate(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = '.xls';
    return this.apiService.download(`conferences/${conferenceId}/get_delegate_template`, `delegate_template_${conferenceId}`, params);
  }

  getConferenceNumbersOverall(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/overall`, params);
  }

  getConferenceNumbersDetailed(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/detailed`, params);
  }

  getConferenceWatchingSessions(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/watching/sessions`);
  }

  getConferenceOnSiteSessions(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/on-site/sessions`);
  }

  getConferenceWatchingLive(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/watching/live`, params);
  }

  getConferenceWatchingOnDemand(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/watching/on-demand`, params);
  }

  getConferenceBrowsingSponsors(conferenceId: number, params: any = {}) {
    return this.apiService.get(`conferences/${conferenceId}/browsing/sponsors`, params);
  }

  downloadTotalVisitsXls(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/browsing/sponsors/export`, `SponsorsExport ${date}`, params);
  }

  downloadDetailedDelegateAccessXls(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/browsing/sponsors/export-raw`, `SponsorsExportRaw ${date}`, params);
  }

  downloadDetailedDelegateAccessForSponsorXls(conferenceId: number, sponsorId: number, sponsorName: string, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/browsing/sponsors/${sponsorId}/export-raw`, `SponsorsExport ${sponsorName} ${date}`, params);
  }

  downloadSessionAttendanceXls(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/on-site/sessions/export`, `OnSite Session Scanning Export ${date}`, params);
  }

  downloadAllAttendanceXls(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/on-site/sessions/export-with-party`, `OnSiteAttendees Scanning Export ${date}`, params);
  }

  downloadWatchingSessions(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/watching/sessions/export`, `Sessions Watching Report ${date}`, params);
  }

  downloadWatchingSessionsDetailed(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/watching/sessions/export-detailed`, `Sessions Watching Report - Detailed ${date}`, params);
  }

  downloadAttendeesWatchingSessionsLive(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    return this.apiService.download(`conferences/${conferenceId}/attendance-live/export`, `Attendees watching sessions live ${date}`, params);
  }

  downloadOnDemandReport(conferenceId: number, params: any = {}) {
    params.mime = 'application/vnd.ms-excel';
    params.extension = 'xlsx';
    let filename = 'On demand Report';
    if (params.from_date && params.to_date) {
      const from_date = (new Date(params.from_date)).toISOString().replace(/[^\d]/g,'');
      const to_date = (new Date(params.to_date)).toISOString().replace(/[^\d]/g,'');
      filename += ' ' + from_date + ' - ' + to_date;
    }
    const date = (new Date()).toISOString().replace(/[^\d]/g,'');
    filename += ', ' + date;
    return this.apiService.download(`conferences/${conferenceId}/on-demand/export-raw`, filename, params);
  }
}
