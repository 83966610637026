<div class="nv-modal" [ngClass]="size">
    <div class="nv-modal-header error">
        <h4 class="nv-modal-title" translate>{{ title }}</h4>
        <button type="button" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="nv-modal-body">
        <div [innerHTML]="errorMessage"></div>
        <ul>
            <li *ngFor="let error of formattedErrors">
               {{error}}
            </li>
        </ul>
    </div>
    <div class="nv-modal-footer">
        <div class="rt-btn-box gap-10 flow-end">
            <button 
                autofocus
                type="button"
                class="rt-btn normal error"
                *ngFor="let button of buttons"
                (click)="onClick(button)"
            >{{ button.text }}</button>
        </div>
    </div>
</div>
