<div class="wp-lading-page">
    <header class="wpl-logo-header">
        <figure><a href="https://riterz.com/" title="Riterz home page"><img src="https://riterz.com/wp-content/uploads/2023/03/Group-3@2x.png" alt="Riterz"></a></figure>
    </header><!-- /.rt-logo-header -->
    <section class="wpl-top-hero">
        <div class="wpl-preamble">
            <h1>Earn additional sponsorship <span>income</span> today</h1>
            <h3>Diversify and grow your revenue by maximising sponsors’ exposure to your audience. Limited time offer expires April 30, 2024.</h3>
            <div class="wpl-entry" id="w-entry1"></div><!-- /.wpl-entry -->
        </div><!-- /.wpl-preamble -->
        <div class="wpl-tablet">
            <figure><img src="https://riterz.com/wp-content/uploads/2024/03/lap-mockup-landing.png" alt="Earn additional sponsorship income, Riterz"></figure>
            <span class="dashed"></span>
        </div><!-- /.wpl-tablet -->
    </section><!-- /.rt-top-hero -->
    <section class="wpl-how-it-works">
        <h2>How it works?</h2>
        <div class="wpl-hiw-items">
            <div class="wpl-feature">
                <span><img src="https://riterz.com/wp-content/uploads/2024/03/wlp-check.svg" alt="Revenue All Year Round"></span>
                <h4>Revenue All Year Round</h4>
                <p>Instead of waiting for an event to activate sponsors, they can advertise on your website or social channels anytime of the year.</p>
            </div><!-- /.wpl-feature -->
            <div class="wpl-feature">
                <span><img src="https://riterz.com/wp-content/uploads/2024/03/wlp-check.svg" alt="Sponsorship Powerhouse"></span>
                <h4>Sponsorship Powerhouse</h4>
                <p>The Riterz platform allows you to manage all your sponsorship packages and campaigns. On one platform. In a few clicks.</p>
            </div><!-- /.wpl-feature -->
        </div><!-- /.wpl-hiw-items -->
    </section><!-- /.wpl-how-it-works -->
    <section class="wpl-limited-offer">
        <h2><span>Limited offer</span>, reserve your spot  now.</h2>
        <div class="wpl-entry" id="w-entry2"></div><!-- /.wpl-entry -->
    </section><!-- /.wpl-limited-offer -->
    <section class="wpl-main-teaser">
        <div class="wpl-main-teaser-wrap">
            <div class="wpl-package">
                <span class="package-price">&dollar;99</span>
                <h2>FOR A COMPLETE SPONSORSHIP CAMPAIGN PACKAGE</h2>
                <h5>One Channel | One Sponsor | One Campaign</h5>
                <div class="wpl-entry" id="w-entry3"></div><!-- /.wpl-entry -->
                <p>Excludes ad spend. Campaign package valid for min. 4 weeks.</p>
            </div><!-- /.wpl-package -->
            <h3>INCLUDED SERVICES...</h3>
            <div class="wpl-features-box">
                <div class="wpl-feature">
                    <span><img src="https://riterz.com/wp-content/uploads/2024/03/ico-channel-installed.svg" alt="Channel Installed"></span>
                    <h4>Channel Installed</h4>
                    <p>We’ll install and integrate 1 x web banner or 1 x social channel (Facebook or LinkedIn) for use on our platform.</p>
                </div><!-- /.wpl-feature -->
                <div class="wpl-feature">
                    <span><img src="https://riterz.com/wp-content/uploads/2024/03/ico-audience-activation.svg" alt="Audience Activation"></span>
                    <h4>Audience Activation</h4>
                    <p>We’ll expand your audience, turning your fans, members, and followers into a rich  community for your  sponsors to reach.</p>
                </div><!-- /.wpl-feature -->
                <div class="wpl-feature">
                    <span><img src="https://riterz.com/wp-content/uploads/2024/03/ico-ad-creation.svg" alt="Ad Creation"></span>
                    <h4>Ad Creation</h4>
                    <p>We’ll create your sponsor's banner ads or social media ads.</p>
                </div><!-- /.wpl-feature -->
                <div class="wpl-feature">
                    <span><img src="https://riterz.com/wp-content/uploads/2024/03/ico-campaing-managment.svg" alt="Campaing Management"></span>
                    <h4>Campaing Management</h4>
                    <p>We provide one  platform to manage ALL your sponsorship campaigns.</p>
                </div><!-- /.wpl-feature -->
                <div class="wpl-feature">
                    <span><img src="https://riterz.com/wp-content/uploads/2024/03/ico-sponsor-onboarding.svg" alt="Sponsor Onboarding"></span>
                    <h4>Sponsor Onboarding</h4>
                    <p>We provide a white-glove service to onboard your sponsors for you.</p>
                </div><!-- /.wpl-feature -->
            </div><!-- /.wpl-features-box -->
        </div><!-- /.wpl-main-teaser-wrap -->
    </section><!-- /.wpl-main-teaser -->
    <footer class="wpl-foo">
        <h3>To Activate your offer, get in touch</h3>
        <div class="wpl-contact-persons">
            <div class="wpl-contact-person">
                <figure><img src="https://riterz.com/wp-content/uploads/2024/03/cp-travis.jpg" alt="Travis Field"></figure>
                <p>Travis Field<span><a href="mailto:travis.field@riterz.com" title="Contact Travis Field">travis.field@riterz.com</a></span></p>
            </div><!-- /.wpl-contact-person -->
            <div class="wpl-contact-person">
                <figure><img src="https://riterz.com/wp-content/uploads/2024/03/cp-prabhu.jpg" alt="Prabhu Manoharan"></figure>
                <p>Prabhu Manoharan<span><a href="mailto:prabu.manohoran@riterz.com" title="Contact Prabhu Manoharan">prabu.manohoran@riterz.com</a></span></p>
            </div><!-- /.wpl-contact-person -->
        </div><!-- /.wpl-contact-persons -->
        <div class="wpl-entry" id="w-entry4"></div><!-- /.wpl-entry -->
        <p class="wpl-privacy"><a href="https://riterz.com/privacy-policy/" title="Riterz privacy policy">Privacy policy</a></p>
        <!-- /.wpl-privacy -->
    </footer>

</div><!-- /.wp-lading-page -->

