export interface OrganizationCampaign {
  id: string;
  publisher_organization_id: string;
  name: string;
  start_date: string;
  end_date: string;
  budget: string;
  budget_currency: string;
  status: string;
}
