import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Organization, OrganizationService } from '@riterz.io/riterz-core';

@Injectable()
export class OrganizationResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private organizationService: OrganizationService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.organizationService
      .getOrganization(route.params.organizationId)
      .pipe(
        map((response: { data: Organization, meta: any }) => {
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          return this.router.navigate(['auth']);
        })
      );
  }
}
