<p>You email is verified.</p>
<div style="display: flex; justify-content: flex-end; gap: 8px; margin-top: 12px;">
  <p-button
    (click)="close()"
    severity="primary"
    [text]="true"
    [outlined]="true"
    [rounded]="true"
    label="Close"
  ></p-button>
</div>

