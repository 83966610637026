import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Organization, OrganizationService } from '@riterz.io/riterz-core';

@Injectable()
export class NavusOrganizationIdResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private organizationService: OrganizationService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const organizationId = route.parent.params.organizationId;
    // TODO Cache
    
    if (false) {
      return of(true);
    } else {
      return this.organizationService
        .getOrganization(organizationId)
        .pipe(
          map((response: { data: Organization, meta: any }) => {
            const organization = response.data;
            return organization.navus_id;
          }),
          catchError((error) => {
            console.log(error);
            return this.router.navigate(['auth']);
          })
        );      
    }
  }
}
