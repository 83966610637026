<div class="nv-config-layout"><!-- toggle class 'sidebar-open' with nv-config-sidebar -->
    <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/riterz-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <div class="nv-config-control-bar">
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Events</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <h1>Events</h1>
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-phab">
                    <ul>
                        <li>
                            <a class="active">Registration</a>
                        </li>
                        <li>
                            <a>Integration</a>
                        </li>
                    </ul>
                </nav><!-- /.nv-tabs-nav -->
            </div><!-- /.nv-line-tabs -->
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port">
            <div class="nv-section">
                <div class="nv-port">
                    <div class="nv-row config-plates gap-24 large-spaced-bottom propagate">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-row gap-24 propagate">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <h4>Give great visibility to your events, create a hub for content and keep your audience engaged!</h4>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-4 tab-12">
                                        <div class="nv-column-content">
                                            <nv-slides class="nv-no-arrows-slider">
                                                <nv-slide>
                                                    <figure><img src="../../assets/web-hub-slide-1.jpg" alt="screen name"></figure>
                                                </nv-slide>
                                                <nv-slide>
                                                    <figure><img src="../../assets/web-hub-slide-2.jpg" alt="screen name"></figure>
                                                </nv-slide>
                                            </nv-slides>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-8 tab-12">
                                        <div class="nv-column-content">
                                            <ul>
                                                <li>Collect and group all content from all your events under one roof.</li>
                                                <li>Publish all your materials in one place – presentations, e-learning modules, e-posters, abstracts, summary articles, publications, news, etc.</li>
                                                <li>Provide Instant and automated e-education 365 days a year through the main Hub.</li>
                                                <li>Smart Search: AI search function with keywords.</li>
                                                <li>Smart Filters.</li>
                                                <li>Personal recommendations for users based on more than 10 algorithms.</li>
                                            </ul>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                                <div class="nv-row gap-24 propagate small-spaced-bottom nv-multi-color-panes v-stretch">
                                    <div class="nv-column desk-6 tab-12">
                                        <div class="nv-column-content">
                                            <p class="medium">Hub Access Control</p>
                                            <p>Assign different access right to different groups:</p>
                                            <ul>
                                                <li>Delegates</li>
                                                <li>Members</li>
                                                <li>Guests</li>
                                                <li>etc.</li>
                                            </ul>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-6 tab-12">
                                        <div class="nv-column-content">
                                            <p class="medium">Sponsorship</p>
                                            <p>Offer sponsorship spaces of the Hub to industry partners.</p>
                                            <p>To get unlimited use of all digital ad/sponsorship spaces, upgrade to Premium for € 4.90 per attendee for any selected event.<br>To see more <a href><strong>click here</strong></a></p>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                                <div class="nv-row gap-24">
                                    <div class="nv-column desk-6 lap-8 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-row gap-10 propagate">
                                                <div class="nv-column desk-auto tab-12">
                                                    <div class="nv-column-content">
                                                        <div class="nv-form-item select-item">
                                                            <label>
                                                                <select>
                                                                    <option>Select options here</option>
                                                                    <option>Options to choose form</option>
                                                                    <option>Like this one</option>
                                                                    <option>Or perhaps 4th from top</option>
                                                                    <option>And so on..</option>
                                                                </select>
                                                                <span class="label-title">Select options here</span>
                                                            </label>
                                                        </div><!-- /nv-form-item -->
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-initial tab-12">
                                                    <div class="nv-column-content">
                                                        <button class="nv-btn medium primary">Submit</button>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                            </div><!-- /nv-row -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                    <div class="nv-row config-plates clean">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <form novalidate="" class="ng-untouched ng-pristine ng-valid">
                                    <div class="nv-config-accordion">
                                        <div class="ca-item active">
                                            <header>
                                                <h3>Set up your event app</h3>
                                                <div class="ca-btns">
                                                    <button class="nv-btn small filled accent1"
                                                            type="button"><span>Next</span></button>
                                                </div><!-- /ca-btns -->
                                            </header>
                                            <section>
                                                <div class="nv-row spaced-top">
                                                    <div class="nv-column desk-12">
                                                        <div class="nv-column-content">
                                                            <div class="nv-ca-padding">
                                                                <h3>Welcome to the App Builder!</h3>
                                                                <p>First, select a few preferences, then select features.<span class="nv-info-spot inline"></span></p>
                                                                <div class="nv-row gap-24 propagate">
                                                                    <div class="nv-column desk-6 lap-12">
                                                                        <div class="nv-column-content">
                                                                            <div class="nv-app-wizard-chunk selected"><!-- < framed with class 'selected' -->
                                                                                <div class="awsc-txt">
                                                                                    <div class="nv-form-item nv-radio-item">
                                                                                        <label>
                                                                                            <span class="nri-radio-port">
                                                                                               <input type="radio" name="type1">
                                                                                               <span class="nri-radio-visual"></span>
                                                                                            </span><!-- /.nri-radio-port -->
                                                                                        </label>
                                                                                    </div><!-- /nv-form-item -->
                                                                                    <h4>Within The Navus App</h4>
                                                                                    <p>Get your event published instantly within Navus App. It is easy, fast and without any extra cost!</p>
                                                                                </div><!-- /.awsc-txt -->
                                                                                <div class="nv-app-wizard-slide-chunk">
                                                                                    <nv-slides>
                                                                                        <nv-slide>
                                                                                            <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                        </nv-slide>
                                                                                        <nv-slide>
                                                                                            <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                        </nv-slide>
                                                                                        <nv-slide>
                                                                                            <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                        </nv-slide>
                                                                                    </nv-slides>
                                                                                </div><!-- /.nv-app-wizard-slide-chunk -->
                                                                            </div><!-- /.nv-app-wizard-chunk -->
                                                                        </div><!-- /.nv-column-content -->
                                                                    </div><!-- /nv-column -->

                                                                    <div class="nv-divider desk-none lap-medium"></div>

                                                                    <div class="nv-column desk-6 lap-12">
                                                                        <div class="nv-column-content">
                                                                            <div class="nv-app-wizard-chunk">
                                                                                <div class="awsc-txt">
                                                                                    <div class="nv-form-item nv-radio-item">
                                                                                        <label>
                                                                                        <span class="nri-radio-port">
                                                                                           <input type="radio" name="type1">
                                                                                           <span class="nri-radio-visual"></span>
                                                                                        </span><!-- /.nri-radio-port -->
                                                                                        </label>
                                                                                    </div><!-- /nv-form-item -->
                                                                                    <h4>Your own stand alone App</h4>
                                                                                    <p>Get a dedicated App for your Event. In just a few days your own Event App will be available for downloading on both App Store and Google Play.</p>
                                                                                </div><!-- /.awsc-txt -->
                                                                                <div class="nv-app-wizard-slide-chunk">
                                                                                    <nv-slides>
                                                                                        <nv-slide>
                                                                                            <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                        </nv-slide>
                                                                                        <nv-slide>
                                                                                            <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                        </nv-slide>
                                                                                        <nv-slide>
                                                                                            <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                        </nv-slide>
                                                                                    </nv-slides>
                                                                                </div><!-- /nv-app-wizard-slide-chunk -->
                                                                            </div><!-- /.nv-app-wizard-chunk -->
                                                                        </div><!-- /.nv-column-content -->
                                                                    </div><!-- /nv-column -->
                                                                </div><!-- /.nv-row -->

                                                                <p>You can switch options before you publish.<br>
                                                                    You further have an option to include this event app in your own group app.<br>
                                                                    To request this option <a href><strong>click here</strong></a>.
                                                                </p>

                                                                <div class="nv-row gap-24 propagate v-stretch">
                                                                    <div class="nv-column desk-6 lap-12">
                                                                        <div class="nv-column-content">
                                                                            <div class="awd-teaser nv-tutorial-teaser">
                                                                                <div class="awd-teaser-box">
                                                                                    <h3>Watch the video on how to build your app!</h3>
                                                                                    <div class="nv-video-port">
                                                                                        <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                                                                                        <video width="320" height="240" controls poster="../../assets/video-tutorial-poster.jpg">
                                                                                            <source src="../../assets/Currentzis.mp4" type="video/mp4">
                                                                                            <!--<source src="movie.ogg" type="video/ogg">-->
                                                                                            Your browser does not support the video tag.
                                                                                        </video>
                                                                                    </div><!-- /.nv-ls-video-port -->
                                                                                </div><!-- /.awd-teaser-box -->
                                                                            </div><!-- /.awd-teaser -->

                                                                            <div class="awd-teaser nv-get-the-app">
                                                                                <div class="awd-teaser-box">
                                                                                    <h3>Get the bigger picture. Check the Navus Event App.</h3>
                                                                                    <p>Available on iOS and Android</p>
                                                                                    <div class="nv-apps-badges">
                                                                                        <a href title="Navus on AppStore"><img src="../../assets/logo-app-store-black.svg" alt="Navus on AppStore"></a>
                                                                                        <a href title="Navus on GooglePlay"><img src="../../assets/logo-google-play-black.svg" alt="Navus on GooglePlay"></a>
                                                                                    </div><!-- /.nv-apps-badges -->
                                                                                </div><!-- /.awd-teaser-box -->
                                                                            </div><!-- /.awd-teaser -->
                                                                        </div><!-- /.nv-column-content -->
                                                                    </div><!-- /.nv-column -->

                                                                    <div class="nv-column desk-6 lap-12">
                                                                        <div class="nv-column-content">
                                                                            <div class="awd-teaser sma">
                                                                                <div class="awd-teaser-box">
                                                                                    <div class="awd-slide">
                                                                                        <nv-slides>
                                                                                            <nv-slide>
                                                                                                <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                            </nv-slide>
                                                                                            <nv-slide>
                                                                                                <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                            </nv-slide>
                                                                                            <nv-slide>
                                                                                                <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                                                            </nv-slide>
                                                                                        </nv-slides>
                                                                                    </div><!-- /.awd-slide -->
                                                                                    <div class="sma-txt">
                                                                                        <h3>Stand-alone Multi-event App</h3>
                                                                                        <p>Group all your events under one Organisation App.<br>Give great visibility to your events, create a hub for content and keep your audience engaged!</p>
                                                                                        <p><a href class="nv-btn secondary small">Apply here</a></p>
                                                                                    </div><!-- /.sm-txt -->
                                                                                </div><!-- /.awd-teaser-box -->
                                                                            </div><!-- /.awd-teaser sma -->
                                                                        </div><!-- /.nv-column-content -->
                                                                    </div><!-- /.nv-column -->
                                                                </div><!-- /.nv-row -->
                                                            </div><!-- /.nv-ca-padding -->
                                                            <div class="ca-footer">
                                                                <div class="nv-tip">
                                                                    <p>Tip: Do you know that your App includes unlimited Voting?</p>
                                                                    <p>Drive Interaction. Save cost and time. No need for another third party solution. <a href title="Set up Navus voting"><strong>Set up Voting here</strong></a>.</p>
                                                                </div><!-- /.nv-tip -->
                                                            </div><!-- /.ca-footer -->
                                                        </div><!-- /nv-column-content -->
                                                    </div>
                                                </div>
                                            </section>
                                        </div><!-- /ca-item -->
                                    </div><!-- /nv-config-accordion -->
                                </form>
                            </div>
                        </div>
                    </div><!-- /nv-row -->
                    <div class="nv-row gap-24 config-plates large-spaced-top clean flow-center">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content" style="text-align: center">
                                <h3 class="medium">You're ready to go!</h3>
                                <p>You've finished setting up your event app. You can continue to customize your app from the app builder.</p>
                                <p><a href class="nv-btn medium primary">Finish</a></p>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                    <div class="nv-row gap-24 config-plates large-spaced-top clean v-stretch">
                        <div class="nv-column desk-6 lap-12">
                            <div class="nv-column-content">
                                <div class="nv-event-app-overview">
                                    <h3 class="medium">Your Event App Overview:</h3>
                                    <div class="eao-content">
                                        <div class="eao-slider">
                                            <nv-slides>
                                                <nv-slide>
                                                    <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                </nv-slide>
                                                <nv-slide>
                                                    <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                </nv-slide>
                                                <nv-slide>
                                                    <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                </nv-slide>
                                            </nv-slides>
                                        </div><!-- /.eao-slider -->

                                        <div class="nv-divider desk-none tab-medium"></div>

                                        <div class="eao-meta">
                                            <a href class="nv-btn primary small">Publish</a>
                                            <div class="eao-chunk">
                                                <label>PUBLISH STATE</label>
                                                <p>Unpublished</p>
                                            </div><!-- /.eao-chunk -->
                                            <div class="eao-chunk">
                                                <label>APP TYPE</label>
                                                <p>Within The Navus App</p>
                                            </div><!-- /.eao-chunk -->
                                            <div class="eao-chunk">
                                                <label>APP NAME</label>
                                                <p>Virtual 2020</p>
                                            </div><!-- /.eao-chunk -->
                                            <div class="eao-chunk">
                                                <label>APP FEATURES:</label>
                                                <ul>
                                                    <li>Logo</li>
                                                    <li>Interactive Section: Program, Speakers, E-materials, Voting, Timeline, E-posters, My notes.</li>
                                                    <li>News</li>
                                                    <li>Recommedantions</li>
                                                </ul>
                                            </div><!-- /.eao-chunk -->
                                            <div class="nv-row gap-24">
                                                <div class="nv-column desk-initial">
                                                    <div class="eao-chunk">
                                                        <label>APP ICON</label>
                                                        <img class="eao-icon" src="https://service.e-materials.com/storage/resources/imedex/sam/sample-app-ico.png" alt="icon App Name">
                                                    </div><!-- /.eao-chunk -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-initial">
                                                    <div class="eao-chunk">
                                                        <label>COLOR SCHEME</label>
                                                        <div class="eao-colors">
                                                            <p>Primary: <span><span style="background-color: #2B3489;"></span>#2B3489;</span></p>
                                                            <p>Secondary: <span><span style="background-color: #318AF1;"></span>#318AF1;</span></p>
                                                        </div><!-- /.eao-colors -->
                                                    </div><!-- /.eao-chunk -->
                                                </div><!-- /.nv-column -->
                                            </div><!-- /.nv-row -->
                                        </div><!-- /.eao-meta -->
                                    </div><!-- /.eao-content -->
                                </div><!-- /.nv-event-app-overview -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-divider desk-none lap-medium"></div>

                        <div class="nv-column desk-6 lap-12">
                            <div class="nv-column-content">
                                <div class="nv-event-app-preview">
                                    <h3>Preview your app on your device</h3>
                                    <p>App Store Buttons are not available when app is not published.</p>
                                    <div class="aep-badges">
                                        <a href title="Navus on AppStore"><img alt="Navus on AppStore" src="../../assets/logo-app-store-black.svg"></a>
                                        <a href title="Navus on GooglePlay"><img alt="Navus on GooglePlay" src="../../assets/logo-google-play-black.svg"></a>
                                    </div><!-- /.aep-badges -->
                                    <p>or<br>visit <a href><strong>url link example</strong></a> on your mobile device.</p>
                                    <div class="nv-qr-box">
                                        <h4>Scan QR code to download</h4>
                                        <div class="nv-row gap-24">
                                            <div class="nv-column desk-6 tab-12">
                                                <div class="nv-column-content">
                                                    <p>Your Event App</p>
                                                    <img src="https://service.e-materials.com/storage/resources/imedex/qr-/qr-code-example.png">
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->

                                            <div class="nv-divider desk-none tab-medium"></div>

                                            <div class="nv-column desk-6 tab-12">
                                                <div class="nv-column-content">
                                                    <p>The Sampe Navus App</p>
                                                    <img src="https://service.e-materials.com/storage/resources/imedex/qr-/qr-code-example.png">
                                                </div><!-- /.nv-column-content -->
                                            </div><!-- /.nv-column -->
                                        </div><!-- /.nv-row -->
                                    </div><!-- /.nv-qr-box -->
                                </div><!-- /.nv-event-app-preview -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->



                    <div class="nv-row gap-24 config-plates large-spaced-top clean v-stretch">
                        <div class="nv-column desk-6 lap-12">
                            <div class="nv-column-content">
                                <div class="nv-event-app-overview">
                                    <h3 class="medium">Your Event App Overview:</h3>
                                    <div class="eao-content">
                                        <div class="eao-slider">
                                            <nv-slides>
                                                <nv-slide>
                                                    <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                </nv-slide>
                                                <nv-slide>
                                                    <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                </nv-slide>
                                                <nv-slide>
                                                    <figure><img src="../../assets/slide-phone-sample.png" alt="screen name"></figure>
                                                </nv-slide>
                                            </nv-slides>
                                        </div><!-- /.eao-slider -->

                                        <div class="nv-divider desk-none tab-medium"></div>

                                        <div class="eao-meta">
                                            <div class="eao-top-right">
                                                <a href class="nv-btn primary small">Publish</a>
                                                <a href>Check app before publishing</a>
                                            </div><!-- /.eao-top-right -->
                                            <div class="eao-chunk">
                                                <label>PUBLISH STATE</label>
                                                <p>Unpublished</p>
                                            </div><!-- /.eao-chunk -->
                                            <div class="eao-chunk">
                                                <label>APP TYPE</label>
                                                <p>Within The Navus App</p>
                                            </div><!-- /.eao-chunk -->
                                            <div class="eao-chunk">
                                                <label>APP NAME</label>
                                                <p>Virtual 2020</p>
                                            </div><!-- /.eao-chunk -->
                                            <div class="eao-chunk">
                                                <label>APP FEATURES:</label>
                                                <ul>
                                                    <li>Logo</li>
                                                    <li>Interactive Section: Program, Speakers, E-materials, Voting, Timeline, E-posters, My notes.</li>
                                                    <li>News</li>
                                                    <li>Recommedantions</li>
                                                </ul>
                                            </div><!-- /.eao-chunk -->
                                            <div class="nv-row gap-24">
                                                <div class="nv-column desk-initial">
                                                    <div class="eao-chunk">
                                                        <label>APP ICON</label>
                                                        <img class="eao-icon" src="https://service.e-materials.com/storage/resources/imedex/sam/sample-app-ico.png" alt="icon App Name">
                                                    </div><!-- /.eao-chunk -->
                                                </div><!-- /.nv-column -->
                                                <div class="nv-column desk-initial">
                                                    <div class="eao-chunk">
                                                        <label>COLOR SCHEME</label>
                                                        <div class="eao-colors">
                                                            <p>Primary: <span><span style="background-color: #2B3489;"></span>#2B3489;</span></p>
                                                            <p>Secondary: <span><span style="background-color: #318AF1;"></span>#318AF1;</span></p>
                                                        </div><!-- /.eao-colors -->
                                                    </div><!-- /.eao-chunk -->
                                                </div><!-- /.nv-column -->
                                            </div><!-- /.nv-row -->
                                        </div><!-- /.eao-meta -->
                                    </div><!-- /.eao-content -->
                                </div><!-- /.nv-event-app-overview -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-divider desk-none lap-medium"></div>

                        <div class="nv-column desk-6 lap-12">
                            <div class="nv-column-content">
                                <div class="awd-teaser nv-apps-logos-qr blue">
                                    <div class="awd-teaser-box">
                                        <div class="awd-heading-logo">
                                            <img src="https://service.e-materials.com/storage/resources/imedex/sam/sample-app-ico.png" alt="app name">
                                            <h3 class="medium">Download Your Event App</h3>
                                        </div><!-- /.awd-heading-logo -->
                                        <div class="awd-badges-n-qr">
                                            <div class="abnq-badges">
                                                <a href title="App name on AppStore"><img alt="App name on AppStore" src="../../assets/badge-app-store-us-black.png"></a>
                                                <a href title="App name  on GooglePlay"><img alt="App name on GooglePlay" src="../../assets/badge-google-play-us.png"></a>
                                            </div><!-- /.abnq-badges -->
                                            <span>or</span>
                                            <div class="abnq-qr">
                                                <img src="//service.e-materials.com/storage/resources/imedex/qr-/qr-code-example.png" alt="App name QR code">
                                            </div><!-- /.abnq-qr -->
                                        </div><!-- /.awd-badges-n-qr -->
                                    </div><!-- /.awd-teaser-box -->
                                </div><!-- /awd-teaser -->
                                <div class="awd-teaser nv-apps-logos-qr yellow">
                                    <div class="awd-teaser-box">
                                        <div class="awd-heading-logo">
                                            <img src="../../assets/navus-logo-app-rounded.png" alt="Navus app">
                                            <h3 class="medium">Download Navus Event App</h3>
                                        </div><!-- /.awd-heading-logo -->
                                        <div class="awd-badges-n-qr">
                                            <div class="abnq-badges">
                                                <a href title="Navus on AppStore"><img alt="Navus on AppStore" src="../../assets/badge-app-store-us-black.png"></a>
                                                <a href title="Navus on GooglePlay"><img alt="Navus on GooglePlay" src="../../assets/badge-google-play-us.png"></a>
                                            </div><!-- /.abnq-badges -->
                                            <span>or</span>
                                            <div class="abnq-qr">
                                                <img src="//service.e-materials.com/storage/resources/imedex/qr-/qr-code-example.png" alt="App name QR code">
                                            </div><!-- /.abnq-qr -->
                                        </div><!-- /.awd-badges-n-qr -->
                                    </div><!-- /.awd-teaser-box -->
                                </div><!-- /awd-teaser -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                    <div class="nv-row gap-24 flow-center large-spaced">
                        <div class="nv-column desk-6">
                            <div class="nv-column-content">

                                <!-- Modal Content -->
                                <div class="awd-badges-n-qr">
                                    <div class="abnq-badges">
                                        <a href title="App name on AppStore"><img alt="App name on AppStore" src="../../assets/badge-app-store-us-black.png"></a>
                                        <a href title="App name  on GooglePlay"><img alt="App name on GooglePlay" src="../../assets/badge-google-play-us.png"></a>
                                    </div><!-- /.abnq-badges -->
                                    <span>or</span>
                                    <div class="abnq-qr">
                                        <img src="//service.e-materials.com/storage/resources/imedex/qr-/qr-code-example.png" alt="App name QR code">
                                    </div><!-- /.abnq-qr -->
                                </div><!-- /.awd-badges-n-qr -->

                                <div class="nv-divider desk-small"></div>

                                <div class="nv-video-port">
                                    <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                                    <video width="320" height="240" controls poster="../../assets/video-tutorial-poster.jpg">
                                        <source src="../../assets/Currentzis.mp4" type="video/mp4">
                                        <!--<source src="movie.ogg" type="video/ogg">-->
                                        Your browser does not support the video tag.
                                    </video>
                                </div><!-- /.nv-ls-video-port -->
                                <!-- /Modal Content -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div><!-- /.nv-row -->
                </div>
            </div>
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
    <!-- SIDEBAR -->
    <div class="nv-config-sidebar"><!-- toggle class 'on' -->
        <div class="nv-learning-pane">
            <header>
                <div class="lp-close"><a href title="Close bar"></a></div>
                <h2>Help &amp; Learning</h2>
                <h5><a href title="Help Center">Go to Help Center</a></h5>
                <div class="sbwf-port">
                    <input formcontrolname="search_term" placeholder="Search..." type="text">
                    <span class="sbwf-outline"></span>
                </div><!-- /sbwf-port -->
                <nav class="nv-small-tabs-controls">
                    <ul>
                        <li><a href>Home</a></li>
                        <li><a href class="active">Tutorials</a></li>
                    </ul>
                </nav><!-- /.nv-small-tabs-controls -->
            </header>
            <div class="lp-body">
                <div class="lp-body-content">
                    <h3>Watch Tutorial Videos</h3>
                    <div class="nv-tutorial-videos">
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Adding Speakers</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Setting up an Event</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Starting a Promotion</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Adding Delegates</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Event App Tutorial</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Uploading the Program</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Event Metrics Tutorial</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Live Voting Tutorial</p>
                        </div><!-- /.tv-item -->
                    </div><!-- /.nv-tutorial-videos -->

                    <nav class="nv-help-list">
                        <ul>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        Getting started
                                        <span>Explore key features and possibilities</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        Watch tutorial videos
                                        <span>Learn how to organize event.</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        App & Web templates
                                        <span>Discover more about templates.</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        What’s new
                                        <span>Find out latest product changes</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        FAQ
                                        <span>Browse through the popular topics.</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        Support
                                        <span>Share your feedback and ask questions.</span>
                                    </a>
                                </h4>
                            </li>
                        </ul>
                    </nav><!-- /.nv-help-list -->
                </div><!-- /.lp-body-content -->
            </div><!-- /.lp-body -->
            <footer>
                <a href class="nv-btn filled primary medium">Contact Us</a>
            </footer>
        </div><!-- /.nv-learning-pane -->
    </div><!-- /.nv-config-sidebar -->
</div><!-- /.nv-config-layout -->
