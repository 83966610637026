import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuditLogService {

  constructor(
    private apiService: ApiService
  ) { }
  
  /**
   * @param  {string} objectType
   * @param  {string} objectId
   * @param  {any={}} params
   * @returns Observable<any>
   */
  getAuditLogs(objectType: string, objectId: string, params: any = {}): Observable<any> {
    return this.apiService.get(`audit-logs/${objectType}/${objectId}`, params);
  }

}
