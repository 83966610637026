import { Component } from '@angular/core';

@Component({
    selector: 'customer-ss-detail-page',
    templateUrl: './customer-ss-detail.component.html'
})
export class CustomerSsDetailComponent {

    constructor() { }

}
