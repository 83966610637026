import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RiterzCoreConfiguration } from '../riterz-core.configuration';

@Injectable()
export class ApiService {
  constructor(
    private readonly configuration: RiterzCoreConfiguration,
    private http: HttpClient
  ) { }

  private formatErrors(error: any) {
    return throwError(error);
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any={}} params Query params
   * @returns Observable<any>
   */
  get(path: string, params: any = {}): Observable<any> {
    const token = localStorage.getItem(this.configuration.accessTokenName);
    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.http.get(`${this.configuration.apiRoot}${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any={}} body Body params
   * @returns Observable<any>
   */
  put(path: string, body: any = {}): Observable<any> {
    const token = localStorage.getItem(this.configuration.accessTokenName);
    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.http.put(`${this.configuration.apiRoot}${path}`, JSON.stringify(body), { headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any={}} body
   * @param  {any={}} headers
   * @returns Observable<any>
   */
  post(path: string, body: any = {}, headers: any = {}): Observable<any> {
    const token = localStorage.getItem(this.configuration.accessTokenName);
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.http.post(`${this.configuration.apiRoot}${path}`, JSON.stringify(body), { headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} params?
   * @returns Observable<any>
   */
  delete(path: string, params?: any): Observable<any> {
    const token = localStorage.getItem(this.configuration.accessTokenName);
    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.http.delete(`${this.configuration.apiRoot}${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }

}
