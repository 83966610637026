<div class="dark-skin">
    <header class="nv-section guttered nv-single-detail-header nv-news-single-header">
        <div class="nv-port">
            <div class="nv-row v-stretch large-spaced-top spaced-bottom">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <h1>Turn your events to Virtual and keep them safe</h1>
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </header><!-- /.nv-section -->
    <main class="nv-section guttered nv-single-detail-body nv-news-single-body">
        <span class="nv-single-stripe"></span>
        <div class="nv-port">
            <div class="nv-row v-stretch">
                <div class="nv-column desk-12">
                    <div class="nv-column-content">
                        <div class="nv-row gap-28">
                            <div class="nv-column desk-8 tab-12 nv-single-news-content">
                                <div class="nv-column-content">
                                    <figure>
                                        <img src="../../assets/event-cover-page.jpg" alt="">
                                    </figure>
                                    <h5 class="medium">Pellentesque commodo magna id interdum consectetur. Morbi nec nibh dapibus, fringilla risus sit amet, imperdiet mi. Praesent id tellus tortor. Curabitur nisi dolor, dapibus sit amet lobortis a, sagittis vel mauris. Aliquam quis erat eu tellus sodales sollicitudin. Praesent sed accumsan turpis. Nam ut elit magna.</h5>
                                    <p>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet orem ipsum dolor sit amet orem ipsum dolor sit amePellentesque commodo magna id interdum consectetur. Morbi nec nibh dapibus, fringilla risus sit amet, imperdiet mi. Praesent id tellus tortor. Curabitur nisi dolor, dapibus sit amet lobortis a, sagittis vel mauris.</p>
                                    <figure>
                                        <a href><img src="../../assets/avatar-dummy-1.jpg" alt=""></a>
                                    </figure>
                                    <p>Pellentesque commodo magna id interdum consectetur. Morbi nec nibh dapibus, fringilla risus sit amet, imperdiet mi. Praesent id tellus tortor. Curabitur nisi dolor, dapibus sit amet lobortis a, sagittis vel mauris.</p>
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                            <div class="nv-column desk-4 tab-12">
                                <div class="nv-column-content">
                                    <div class="nv-excerpts-box color-gray1">
                                        <h4>HOT TOPICS</h4>
                                        <nav>
                                            <ul>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-excerpts-box -->

                                    <div class="nv-excerpts-box color-gray2">
                                        <h4>HOT TOPICS</h4>
                                        <nav>
                                            <ul>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                                <li>
                                                    <figure>
                                                        <a href><img src="https://loremflickr.com/240/160" alt="article name"></a>
                                                    </figure>
                                                    <header>
                                                        <h3><a href>Connect events in hubs</a></h3>
                                                    </header>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div><!-- /.nv-excerpts-box -->
                                </div><!-- /.nv-column-content -->
                            </div><!-- /.nv-column -->
                        </div><!-- /.nv-row -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </main><!-- /.nv-section -->
</div>
