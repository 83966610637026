<div class="nv-config-layout">
    <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/riterz-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <div class="nv-config-control-bar">
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Events</span>
                <span>Lorem ipsum dolor sit</span>
                <span>Web Portal</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <div class="config-title-n-tools">
                <div class="tnt-left">
                    <h1>Setup Web Portal</h1>
                </div><!-- /.tnt-left -->
                <div class="tnt-right">
                    <div class="nv-btn-box flow-end gap-8">
                        <a class="nv-btn small filled accent2" data-icon-pl="&#xe095;">Add File</a>
                        <a class="nv-btn icon small filled white" data-icon-pl="&#xe602;"></a>
                    </div><!-- /.nv-btn-box flow-end gap-6 -->
                </div><!-- /.tnt-right -->
            </div><!-- /.config-title-n-tools -->
            <form autocomplete="off" class="config-search-bar-w-filters">
                <div class="sbwf-port">
                    <input formcontrolname="search_term" placeholder="Search..." type="text">
                    <span class="sbwf-outline"></span>
                </div><!-- /.sbwf-port -->
                <div class="sbwf-filters">
                    <div class="sbwf-trigger">Filters</div> <!-- < toggle class 'on' -->
                    <nav class="sbwf-options">
                        <ul>
                            <li><a>Presentations</a></li>
                            <li><a>Abstracts</a></li>
                            <li><a>Videos</a></li>
                            <li><a>Whatever</a></li>
                        </ul>
                    </nav><!-- /.sbwf-options -->
                </div><!-- /.sbwf-port -->
            </form>
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port">
            <section class="nv-section">
                <div class="nv-port">
                    <div class="nv-row config-plates clean">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-config-accordion">
                                    <div class="ca-item"> <!-- classes: 'active' , 'done' -->
                                        <header>
                                            <h3>Basic info</h3>
                                            <div class="ca-btns">
                                                <a href="" class="nv-btn medium filled anchor">Next</a>
                                            </div><!-- /.ca-btns -->
                                        </header>
                                        <section>
                                            <div class="nv-row large-spaced flow-center">
                                                <div class="nv-column desk-8 tab-12">
                                                    <div class="nv-column-content">
                                                        <h3 class="light">How do you want to proceed?</h3>
                                                        <div class="nv-form-item nv-radio-item">
                                                            <label>
                                                                <span class="nri-radio-port">
                                                                    <input type="radio" name="type1">
                                                                    <span class="nri-radio-visual"></span>
                                                                </span><!-- /.nri-radio-port -->
                                                                <div class="nri-value">
                                                                    Install a brand new event website
                                                                    <div class="nv-input-info-box">
                                                                        <span></span>
                                                                        <div class="nv-input-info-content"><div>Lorem ipsum dolor sit amet, consectetur.</div></div>
                                                                    </div>
                                                                </div><!-- /.nri-value -->
                                                            </label>
                                                        </div><!-- /nv-form-item -->
                                                        <div class="nv-form-item nv-radio-item">
                                                            <label>
                                                                <span class="nri-radio-port">
                                                                    <input type="radio" name="type1">
                                                                    <span class="nri-radio-visual"></span>
                                                                </span><!-- /.nri-radio-port -->
                                                                <div class="nri-value">
                                                                    Connect digital services portal to my event website
                                                                    <div class="nv-input-info-box">
                                                                        <span></span>
                                                                        <div class="nv-input-info-content"><div>Lorem ipsum dolor sit amet, consectetur.</div></div>
                                                                    </div>
                                                                </div><!-- /.nri-value -->
                                                            </label>
                                                        </div><!-- /nv-form-item -->
                                                        <br>
                                                        <p>Digital services platform is like event most important services hub for your event. You can use program planner, presentations hub and create e-playlist - all from one place!</p>
                                                        <p>Tell us about your event:</p>
                                                        <h5>EVENT WEBSITE</h5>
                                                        <div class="nv-form-item nv-with-info nv-mandatory">
                                                            <label>
                                                                <input type="text" placeholder="Event website:">
                                                                <span class="label-title">Event website:</span>
                                                            </label>
                                                            <div class="nv-input-info-box">
                                                                <span></span>
                                                                <div class="nv-input-info-content"><div>Add your domain if you already have event website.</div></div>
                                                            </div>
                                                        </div><!-- /nv-form-item -->
                                                        <br>
                                                        <h5>EVENT LOGO</h5>
                                                        <div class="nv-upload-block">
                                                            <label class="ub-img-port">
                                                                <input type="file" name="">
                                                                <span class="nv-ub-image" style="background-image: url('//service.e-materials.com/storage/resources/eha/nov/novartis.png');"></span><!-- /.nv-ub-image --> <!-- < remove style attribute for initial state -->
                                                            </label>
                                                            <div class="ub-adjument">
                                                                <p class="ub-control">
                                                                    <span><a href>Upload new file</a></span>
                                                                    <span><a href>Remove file</a></span>
                                                                </p><!-- /.ub-control -->
                                                                <div class="info-p small">
                                                                    <p>Upload instructions</p>
                                                                    <div class="nv-input-info-box">
                                                                        <span></span>
                                                                        <div class="nv-input-info-content"><div>Upload at least 400x150 pixels wide logo.<br><br>Accepted File Types: JPG or PNG</div></div>
                                                                    </div>
                                                                </div><!-- /.info-p -->
                                                            </div><!-- /.ub-adjument -->
                                                        </div><!-- /.nv-upload-block -->
                                                        <br>
                                                        <h5>DEFAULT LANGUAGE</h5>
                                                        <div class="nv-row">
                                                            <div class="nv-column desk-6 lap-12">
                                                                <div class="nv-column-content">
                                                                    <div class="nv-form-item nv-select-item nv-with-info nv-mandatory">
                                                                        <label class="nv-control-display">
                                                                            <span class="nv-display-value">Default language</span>
                                                                            <span aria-label="label-title" class="label-title">Default language</span>
                                                                        </label>
                                                                        <div class="nv-input-info-box">
                                                                            <span></span>
                                                                            <div class="nv-input-info-content"><div>Add your domain if you already have event website.</div></div>
                                                                        </div>
                                                                    </div><!-- /nv-form-item -->
                                                                </div><!-- /.nv-column-content -->
                                                            </div><!-- /.nv-column -->
                                                        </div><!-- /.nv-row -->
                                                        <br>
                                                        <h5>GOOGLE ANALYTICS CODE</h5>
                                                        <div class="nv-form-item nv-mandatory">
                                                            <label>
                                                                <textarea rows="5" placeholder=" Google analytics code"></textarea>
                                                                <span class="label-title" translate> Google analytics code</span>
                                                            </label>
                                                        </div><!-- /nv-form-item -->
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                            </div><!-- /.nv-row -->
                                        </section>
                                    </div><!-- /.ca-item -->

                                    <div class="ca-item">
                                        <header>
                                            <h3>Set up website name, subdomain &amp; publish date</h3>
                                            <div class="ca-btns">
                                                <a href="" class="nv-btn medium hollow neutral icon" data-icon-pl="&#xe959;" title="Edit"></a>
                                            </div><!-- /.ca-btns -->
                                        </header>
                                        <section>
                                            <div class="nv-row large-spaced flow-center">
                                                <div class="nv-column desk-8 tab-12">
                                                    <div class="nv-column-content">
                                                        <p>Set up website name, subdomain &amp; publish date</p>
                                                    </div><!-- /.nv-column-content -->
                                                </div><!-- /.nv-column -->
                                            </div><!-- /.nv-row -->
                                        </section>
                                    </div><!-- /.ca-item -->
                                </div><!-- /.nv-config-accordion -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div>
                </div>
            </section>
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
</div><!-- /.nv-config-layout -->
