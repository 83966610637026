import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FileUploader } from 'ng2-file-upload';

import { RiterzCoreConfiguration } from '../riterz-core.configuration';
import { of } from 'rxjs';

@Injectable()
export class OrganizationService {

  constructor(
    private readonly configuration: RiterzCoreConfiguration,
    private apiService: ApiService
  ) { }

  getMyOrganizations(params: any = {}) {
    return this.apiService.get(`users/me/organizations`, params);
  }

  createMyOrganization(params: any = {}) {
    return this.apiService.post(`users/me/organizations`, params);
  }

  getOrganizations(params: any = {}) {
    return this.apiService.get(`organizations`, params);
  }

  getOrganization(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}`, params);
  }

  updateOrganization(organizationId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}`, params);
  }

  createOrganization(params: any = {}) {
    return this.apiService.post(`organizations`, params);
  }

  deleteOrganization(organizationId: string) {
    return this.apiService.delete(`organizations/${organizationId}`);
  }

  getAdminOrganizations(params: any = {}) {
    return this.apiService.get(`admin/organizations`, params);
  }

  getAdminOrganization(organizationId: string, params: any = {}) {
    return this.apiService.get(`admin/organizations/${organizationId}`, params);
  }

  updateAdminOrganization(organizationId: string, params: any = {}) {
    return this.apiService.put(`admin/organizations/${organizationId}`, params);
  }

  createAdminOrganization(params: any = {}) {
    return this.apiService.post(`admin/organizations`, params);
  }

  deleteAdminOrganization(organizationId: string) {
    return this.apiService.delete(`admin/organizations/${organizationId}`);
  }

  createAdvertiser(organizationId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/advertisers`, params);
  }

  deleteDemoData(organizationId: string, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/delete-demo-organization-data`, params);
  }

  createLogoUploader(organizationId: string, params: any = {}) {
    if (!localStorage.getItem(this.configuration.accessTokenName)) { return; }

    return new FileUploader({
      ...params,
      url: `${this.configuration.apiRoot}organizations/${organizationId}/logo`,
      removeAfterUpload: true,
      autoUpload: true,
      itemAlias: 'image',
      authTokenHeader:  'Authorization',
      authToken: 'Bearer ' + localStorage.getItem(this.configuration.accessTokenName),
      headers: [],
    });
  }

  deleteOrganizationLogo(organizationId: string, params: any = {}) {
    if (localStorage.getItem(this.configuration.accessTokenName)) {
      return this.apiService
        .delete(`organizations/${organizationId}/logo`, params);
    } else {
      return of(false);
    }
  }

}
