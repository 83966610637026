import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';


@Component({
  selector: 'nv-button',
  templateUrl: './button.component.html'
})


export class NavusButtonComponent implements OnInit {
  @HostBinding('style.pointer-events') pointerEvents = 'none';
  @HostBinding('attr.disabled') get hostDisable(): any {
    if (this.disabled) {
      return this.disabled;
    }
  }

  @Input() disabled: boolean = false;
  @Input() icon: any;
  @Input() iconPl: any;
  @Input() type: string = 'button';
  @Input() size: 'large' | 'normal' | 'small' = 'normal';
  @Input() color: 'neutral' | 'primary' | 'primaryDark' | 'primaryLight' | 'secondary' | 'secondaryDark' | 'secondaryLight' | 'tertiary' | 'tertiaryDark' | 'quaternary' | 'passive' | 'alert' | 'warning' | 'success' | 'error'  = 'primary';
  @Input() class: string;
  @Input() loading: boolean = false;
  @Input() onlyIcon: boolean = false;
  @Input() radius: boolean = false;

  possibleSizeValues = ['large', 'medium', 'small'];

  static checkPossibleAttributeValues(array, value, attributeName) {
    if (value === '') {
      throw new Error('set attribute value');
    }
    if (array.indexOf(value) < 0) {
      throw new Error(`${value} is not allowed attribute ${attributeName}`);
    }
  }

  constructor() {
  }

  ngOnInit() {
    if (this.disabled !== false) {
      this.disabled = true;
    }
    if (this.loading !== false) {
      this.loading = true;
    }
    if (this.onlyIcon !== false) {
      this.onlyIcon = true;
    }
    if (this.radius !== false) {
      this.radius = true;
    }
    // NavusButtonComponent.checkPossibleAttributeValues(this.possibleSizeValues, this.size, 'size');
  }

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this.loading) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}
