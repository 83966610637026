<div class="nv-config-layout">
<div class="nv-config-layout">
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/riterz-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <div class="nv-config-control-bar">
            <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Data Room</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <div class="config-title-n-tools">
                <div class="tnt-left">
                    <h1>Data Room</h1>
                </div><!-- /.tnt-left -->
                <div class="tnt-right">
                    <div class="nv-btn-box flow-end gap-8">
                        <a class="nv-btn small filled accent2" data-icon-pl="&#xe095;">Add File</a>
                        <a class="nv-btn icon small filled white" data-icon-pl="&#xe602;"></a>
                    </div><!-- /.nv-btn-box flow-end gap-6 -->
                </div><!-- /.tnt-right -->
            </div><!-- /.config-title-n-tools -->
            <form autocomplete="off" class="config-search-bar-w-filters">
                <div class="sbwf-port">
                    <input formcontrolname="search_term" placeholder="Search..." type="text">
                    <span class="sbwf-outline"></span>
                </div><!-- /.sbwf-port -->
                <div class="sbwf-filters">
                    <div class="sbwf-trigger">Filters</div> <!-- < toggle class 'on' -->
                    <nav class="sbwf-options">
                        <ul>
                            <li><a>Presentations</a></li>
                            <li><a>Abstracts</a></li>
                            <li><a>Videos</a></li>
                            <li><a>Whatever</a></li>
                        </ul>
                    </nav><!-- /.sbwf-options -->
                </div><!-- /.sbwf-port -->
            </form>
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port filled">
            <section class="nv-section">
                <div class="nv-port">
                    <div class="nv-row config-plates">
                        <div class="nv-column desk-3">
                            <div class="nv-column-content nv-viewported">
                                <div class="nv-heading-n-tools">
                                    <h3 class="pale">Folders</h3>
                                    <div class="hnt-tool">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav>
                                                <ul>
                                                    <li><a>Create New Folder</a></li>
                                                    <li><a>Show Deleted Files & Folders</a></li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                    </div><!-- /.hnt-tool -->
                                </div><!-- /.nv-heading-n-tools -->
                                <div class="nv-folders-list-port">
                                    <nav class="nv-folders-list">
                                        <ul>
                                            <li>
                                                <a>
                                                    <strong>My Files</strong>
                                                    <span title="22 items">22</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <strong>Someone's else files with a loooooong title</strong>
                                                    <span title="4 items">4</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a>
                                                    <strong>Another folder</strong>
                                                    <span title="565 items">565</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav><!-- /.nv-folders-list -->
                                </div><!-- /.nv-folders-list-port -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->

                        <div class="nv-column desk-9">
                            <div class="nv-column-content nv-viewported">
                                <div class="nv-data-room-dboard">
                                    <h3 class="pale">My Files</h3>
                                    <div class="nv-data-room-controls-bar">
                                        <div class="drcb-left">
                                            <div class="nv-check-n-sort">
                                                <label class="nv-regular-checkbox">
                                                <span class="rcb">
                                                    <input type="checkbox" checked>
                                                    <span class="rc-visual"></span>
                                                </span><!-- /.rcb -->
                                                    <span class="label-txt">Name</span>
                                                </label>
                                            </div><!-- /.nv-check-n-sort -->
                                        </div><!-- /.drcb-left -->
                                        <div class="drcb-right">
                                            <div class="nv-layout-switch">
                                                <span class="small-thumbs current" title="Show small thumbs"></span>
                                                <span class="large-thumbs" title="Show large thumbs"></span>
                                                <span class="list" title="Show as list"></span>
                                            </div><!-- /.nv-layout-switch -->
                                        </div><!-- /.drcb-right -->
                                    </div><!-- /.nv-data-room-controls-bar -->
                                </div><!-- /.nv-data-room-dboard -->
                                <div class="nv-data-room-content-wrap">
                                    <div class="nv-data-room-content-port">
                                        <div class="nv-data-room-content"><!--  toggle class 'by2' -->
                                            <div class="drc-item upload">
                                                <div class="drc-upload">
                                                    <div class="drc-upload-port">
                                                        <h4>Drop files here</h4>
                                                        <span>or</span>
                                                        <a href class="nv-btn filled small anchor">Choose a file</a>
                                                    </div><!-- /.drc-upload-port -->
                                                </div><!-- /.drc-upload -->
                                            </div><!-- /.drc-item -->
                                            <div class="drc-item">
                                                <div class="drc-port checked">
                                                    <div class="drc-tools">
                                                        <label class="nv-regular-checkbox" title="select item">
                                                <span class="rcb">
                                                    <input type="checkbox" checked>
                                                    <span class="rc-visual"></span>
                                                </span><!-- /.rcb -->
                                                        </label>
                                                        <div class="nv-cell-actions">
                                                            <span class="nv-cell-action-trigger"></span>
                                                            <nav>
                                                                <ul>
                                                                    <li><a>Share files</a></li>
                                                                    <li><a>Move to folder</a></li>
                                                                    <li><a>Copy Files</a></li>
                                                                    <li><a>New folder from selection</a></li>
                                                                    <li><a>Download Files</a></li>
                                                                    <li class="delete"><a>Delete Files</a></li>
                                                                </ul>
                                                            </nav>
                                                        </div><!-- /nv-cell-actions -->
                                                    </div><!-- /.drc-tools -->
                                                    <div class="drc-image-port" title="Image name" style="background-image: url('//service.e-materials.com/storage/resources/sehh/baj/baja.jpg');">
                                                        <div class="drc-control">
                                                            <div class="drv-control-trigger"><span>View file</span></div><!-- toggle class 'on' -->
                                                            <ul class="nv-resource-actions">
                                                                <li class="nv-ra-fullsize">Open full size</li>
                                                                <li class="nv-ra-download">Download</li>
                                                                <li class="nv-ra-delete">Delete</li>
                                                                <li class="nv-ra-edit">Edit</li>
                                                                <li class="nv-ra-copy">Copy URL</li>
                                                            </ul>
                                                        </div><!-- /.drc-control -->
                                                    </div><!-- /.drc-image-port -->
                                                    <div class="drc-meta">
                                                        <p>File-Name.jpg</p>
                                                        <span>1.2mb</span>
                                                    </div><!-- /.drc-meta -->
                                                </div><!-- /.drc-port -->
                                            </div><!-- /.drc-item -->

                                            <div class="drc-item">
                                                <div class="drc-port">
                                                    <div class="drc-tools">
                                                        <label class="nv-regular-checkbox" title="select item">
                                                <span class="rcb">
                                                    <input type="checkbox" checked>
                                                    <span class="rc-visual"></span>
                                                </span><!-- /.rcb -->
                                                        </label>
                                                        <div class="nv-cell-actions">
                                                            <span class="nv-cell-action-trigger"></span>
                                                            <nav>
                                                                <ul>
                                                                    <li><a>Share files</a></li>
                                                                    <li><a>Move to folder</a></li>
                                                                    <li><a>Copy Files</a></li>
                                                                    <li><a>New folder from selection</a></li>
                                                                    <li><a>Download Files</a></li>
                                                                    <li class="delete"><a>Delete Files</a></li>
                                                                </ul>
                                                            </nav>
                                                        </div><!-- /nv-cell-actions -->
                                                    </div><!-- /.drc-tools -->
                                                    <div class="drc-image-port" title="Image name" style="background-image: url('//service.e-materials.com/storage/resources/eha/car/cart2020-hero-right-faded-alt.jpg');">
                                                        <div class="drc-control">
                                                            <div class="drv-control-trigger"><span>View file</span></div><!-- toggle class 'on' -->
                                                            <ul class="nv-resource-actions">
                                                                <li class="nv-ra-fullsize">Open full size</li>
                                                                <li class="nv-ra-download">Download</li>
                                                                <li class="nv-ra-delete">Delete</li>
                                                                <li class="nv-ra-edit">Edit</li>
                                                                <li class="nv-ra-copy">Copy URL</li>
                                                            </ul>
                                                        </div><!-- /.drc-control -->
                                                    </div><!-- /.drc-image-port -->
                                                    <div class="drc-meta">
                                                        <p>File-Name.jpg</p>
                                                        <span>1.2mb</span>
                                                    </div><!-- /.drc-meta -->
                                                </div><!-- /.drc-port -->
                                            </div><!-- /.drc-item -->
                                        </div><!-- /.nv-data-room-content -->
                                    </div><!-- /.nv-data-room-content-port -->
                                </div><!-- /.nv-data-room-content-wrap -->
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                    </div>
                </div>
            </section>
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
</div><!-- /.nv-config-layout -->
