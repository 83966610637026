<div class="onboardig-page-wrap">
    <header class="onboarding-header"><!-- additional class 'start' for initial step -->
        <div class="oh-logo">
            <a href><img src="../../assets/logo.svg" alt="NAVUS on-boarding"></a>
        </div><!-- /.oh-logo -->
        <div class="oh-step-label">
            <h2>SETUP YOUR NAVUS organizer Panel</h2>
        </div><!-- /.oh-step-label -->
        <div class="oh-step">
            <p>1 of 2</p>
        </div><!-- /.oh-step -->
        <span class="oh-progress-bar"><em style="width: 42%;"></em></span>
    </header><!-- /.onboarding-header -->

    <!-- UPDATE 1 / START -->
    <section class="nv-section onboarding-body guttered" style="display: none;"> <!-- < UPDATE 1 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-9 lap-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-dual-pane">
                            <div class="odp-pane-left">
                                <div class="nv-selected-pckg-meta">
                                    <p class="status">Free Trial</p>
                                    <h3>Basic</h3>
                                    <h5>Selected Plan</h5>
                                    <ul class="nv-banal">
                                        <li>500 delegates</li>
                                        <li>3 events</li>
                                    </ul>
                                    <h4>TOP FEATURES</h4>
                                    <ul class="nv-bulleted secondary">
                                        <li>Access to All Modules</li>
                                        <li>1 hour Consultancy</li>
                                        <li>Unlimited Events</li>
                                        <li>Live Previews</li>
                                        <li>Invite Colleagues</li>
                                    </ul>
                                </div><!-- /.nv-selected-pckg-meta -->
                                <figure>
                                    <img src="../../assets/gfx-selected-pckg.png" alt="Navus Selected Pacasad se kge">
                                </figure>
                            </div><!-- /.odp-pane-left -->
                            <div class="odp-pane-right">
                                <h3>Sign up for a Free 30-Day Trial<br>without a credit card</h3>
                                <div class="nv-row spaced-top gap-24 propagate">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Email">
                                                    <span class="label-title">Email</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-select-item">
                                                <label>
                                                    <select>
                                                        <option>Country</option>
                                                    </select>
                                                    <span class="label-title">Country</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-6 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="First name">
                                                    <span class="label-title">First name</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-6 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Last Name">
                                                    <span class="label-title">Last Name</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Institution / Company / Society (optional)">
                                                    <span class="label-title">Institution / Company / Society (optional)</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-password-item">
                                                <label>
                                                    <input type="password" placeholder="Password">
                                                    <span class="label-title">Password</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-checkbox-item">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                    </span><!-- /.nci-check-port -->
                                                    <span class="nci-value">I have read and accepted <a href>terms and conditions</a></span>
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <a href class="nv-btn filled block secondary big radius-high">
                                                Sign up
                                            </a><!-- /.nv-btn -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                                <p style="text-align: center;">or <a href title="sign in to Navus account">sign in</a> with your existing Navus account</p>
                            </div><!-- /.odp-pane-right -->
                        </div><!-- /.nv-onboarding-dual-pane -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- UPDATE 2 / CREATE ORGANIZATION -->
    <section class="nv-section onboarding-body guttered" style="display: none;"> <!-- < UPDATE 2 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <div class="nv-onb-icon">
                                <img src="../../assets/gfx-onboarding-icon-create-organization.svg" alt="Create Organization">
                            </div><!-- /.nv-onb-icon -->
                            <h3 class="medium">Let’s get started</h3>
                            <p>Enter the name of your Organization</p>
                            <form class="nv-row gap-24 propagate">
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <div class="nv-form-item">
                                            <label>
                                                <input type="text" placeholder="Organization Name">
                                                <span class="label-title">Organization Name</span>
                                            </label>
                                        </div><!-- /nv-form-item -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-12">
                                    <div class="nv-column-content">
                                        <button class="nv-btn medium filled fw secondary">
                                            Continue
                                        </button><!-- /.nv-btn -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </form><!-- /nv-row -->
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- UPDATE 3 / SELECT INDUSTRY TYPE -->
    <section class="nv-section onboarding-body guttered" style="display: none;"> <!-- < UPDATE 3 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <h3 class="medium">Which of these best describes your organization?</h3>
                            <p>SELECT INDUSTRY TYPE</p>
                            <div class="nv-cats-cloud">
                                <a href>Technology</a>
                                <a href class="active">Medical Industry</a>
                                <a href>Pharmaceutical Industry</a>
                                <a href class="active">Environment</a>
                                <a href>Health & Fitness</a>
                                <a href>Culture</a>
                                <a href>Science</a>
                                <a href class="active">Investing</a>
                                <a href class="active">Hotel Industry</a>
                                <a href>Education</a>
                                <a href>Travel</a>
                                <a href class="active">Business Strategy</a>
                                <a href>Human Rights</a>
                                <a href>Industry Type</a>
                            </div><!-- /.nv-cats-cloud -->
                            <div class="nv-row gap-24 spaced-top space-between">
                                <div class="nv-column desk-initial v-middle">
                                    <div class="nv-column-content">
                                        <div class="bb-side-label"><a href>Skip for now</a></div>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column desk-auto -->
                                <div class="nv-column desk-initial">
                                    <div class="nv-column-content">
                                        <div class="nv-btn-box flow-end gap-20">
                                            <a class="nv-btn small hollow neutral extruded">Back</a>
                                            <a class="nv-btn small filled secondary extruded">Next</a>
                                        </div><!-- /.nv-btn-box -->
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- UPDATE 4 / CREATE ORGANIZATION -->
    <section class="nv-section onboarding-body guttered" style="display: none;"> <!-- < UPDATE 4 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <div class="nv-onb-icon">
                                <img src="../../assets/gfx-onboarding-icon-create-event.svg" alt="Create Event">
                            </div><!-- /.nv-onb-icon -->
                            <h3 class="medium">Create your first event</h3>
                            <form>
                                <p>ENTER EVENT NAME / TITLE</p>
                                <div class="nv-row gap-24 propagate">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Event Name">
                                                    <span class="label-title">Event Name</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item">
                                                <label>
                                                    <input type="text" placeholder="Event website (optional)">
                                                    <span class="label-title">Event website (optional)</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /nv-row -->
                                <br>
                                <p>EVENT DATE &amp; LOCATION</p>
                                <div class="nv-row gap-24 flow-right semi-spaced-bottom">
                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-checkbox-item">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                    </span><!-- /.nci-check-port -->
                                                    <span class="nci-value">Event Date TBD</span>
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /nv-row -->
                                <div class="nv-row gap-24 propagate">
                                    <div class="nv-column desk-4 phab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-select-item">
                                                <label>
                                                    <select>
                                                        <option>Event start</option>
                                                    </select>
                                                    <span class="label-title">Event start</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-4 phab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-select-item">
                                                <label>
                                                    <select>
                                                        <option>Event end</option>
                                                    </select>
                                                    <span class="label-title">Event end</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-4 phab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-select-item">
                                                <label>
                                                    <select>
                                                        <option>Select timezone</option>
                                                    </select>
                                                    <span class="label-title">Select timezone</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /nv-row -->
                                <div class="nv-row gap-24 flow-right semi-spaced-bottom">
                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-checkbox-item">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                    </span><!-- /.nci-check-port -->
                                                    <span class="nci-value">Event Country TBD</span>
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /nv-row -->
                                <div class="nv-row gap-24 propagate">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-select-item">
                                                <label>
                                                    <select>
                                                        <option>Event Country</option>
                                                    </select>
                                                    <span class="label-title">Event Country</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /nv-row -->
                                <br>
                                <p>EVENT SIZE</p>
                                <div class="nv-row gap-24 propagate">
                                    <div class="nv-column desk-4 phab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-checkbox-item event-size">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                        <span class="nci-check-bg"></span>
                                                    </span><!-- /.nci-check-port -->
                                                    <span class="nci-value">1-50</span>
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-4 phab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-checkbox-item event-size">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                        <span class="nci-check-bg"></span>
                                                    </span><!-- /.nci-check-port -->
                                                    <span class="nci-value">50-200</span>
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-4 phab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-checkbox-item event-size">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                        <span class="nci-check-bg"></span>
                                                    </span><!-- /.nci-check-port -->
                                                    <span class="nci-value">&gt;200</span>
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /nv-row -->
                                <div class="nv-row gap-24 spaced-top space-between">
                                    <div class="nv-column desk-initial v-middle">
                                        <div class="nv-column-content">
                                            <div class="bb-side-label"><a href>Skip for now</a></div>
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column desk-auto -->
                                    <div class="nv-column desk-initial">
                                        <div class="nv-column-content">
                                            <div class="nv-btn-box flow-end gap-20">
                                                <a class="nv-btn small hollow neutral extruded">Back</a>
                                                <a class="nv-btn small filled secondary extruded">Continue</a>
                                            </div><!-- /.nv-btn-box -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                            </form>
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->

    <!-- UPDATE 5 / VIDEO TUTORIAL -->
    <section class="nv-section onboarding-body guttered" style="display: flex;"> <!-- < UPDATE 5 -->
        <div class="nv-port">
            <div class="nv-row large-spaced v-middle flow-center">
                <div class="nv-column desk-6 lap-8 tab-12">
                    <div class="nv-column-content">
                        <div class="nv-onboarding-step-chunk">
                            <h3 class="medium">Almost done. Take a minute to watch about what you get and how it works.</h3>
                            <div class="nv-video-port">
                                <img src="../../assets/aspect-16-9.png" alt="" class="nv-aspect" aria-hidden="true">
                                <video width="320" height="240" controls poster="../../assets/poster.png">
                                    <source src="../../assets/Currentzis.mp4" type="video/mp4">
                                    <!--<source src="movie.ogg" type="video/ogg">-->
                                    Your browser does not support the video tag.
                                </video>
                            </div><!-- /.nv-video-port -->
                            <div class="nv-row gap-24 spaced-top">
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <a href class="nv-btn hollow neutral fw medium">Back</a>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                                <div class="nv-column desk-6">
                                    <div class="nv-column-content">
                                        <a href class="nv-btn filled secondary fw medium">Finish setup</a>
                                    </div><!-- /.nv-column-content -->
                                </div><!-- /.nv-column -->
                            </div><!-- /.nv-row -->
                        </div><!-- /.nv-onboarding-step-chunk -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </div><!-- /.nv-port -->
    </section><!-- /.onboarding-body -->
</div><!-- /.onboardig-page-wrap -->
