import {Component} from '@angular/core';

@Component({
    selector: 'app-template-page',
    templateUrl: './template.component.html'
})
export class TemplateComponent {

    constructor() { }

}
