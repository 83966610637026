import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MainComponent } from './main.component';
import { RiterzAuthGuard } from './guards/riterz-auth.guard';
import { AuthInterceptor, RiterzCoreModule } from '@riterz.io/riterz-core';
import { environment } from '../../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserResolver } from './resolvers/user.resolver';
import { OrganizationResolver } from './resolvers/organization.resolver';
import { NavusUiModule } from '@navus/ui/navus-ui.module';
import { NavusOrganizationIdResolver } from './resolvers/navus-organization-id.resolver';
import { AuditLogModalComponent } from './modals/audit-log/audit-log-modal.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/u',
    pathMatch: 'full'
  },
  {
    path: 'u',
    component: MainComponent,
    resolve: {
      user: UserResolver
    },
    canActivate: [RiterzAuthGuard],
    children: [
      {
        path: 'a',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'o',
        loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule)
      },
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'invitation/:token',
    redirectTo: 'auth/registration/:token',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    RiterzCoreModule.forRoot({
      apiRoot: environment.riterz_api_url,
      accessTokenName: environment.riterz_access_token_name,
      refreshTokenName: environment.riterz_refresh_token_name,
      clientId: environment.riterz_client_id,
    }),
    NavusUiModule,
    CommonModule,
  ],
  exports: [],
  declarations: [
    AuditLogModalComponent,
    
    MainComponent,
  ],
  providers: [
    UserResolver,
    OrganizationResolver,
    NavusOrganizationIdResolver,

    RiterzAuthGuard,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class MainModule { }
