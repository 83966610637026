<button
    class="rt-btn {{ class }} {{ size }} {{ color }}"
    [attr.data-icon-pl]="iconPl"
    [attr.data-icon]="icon"
    [disabled]="disabled"
    [type]="type"
    [ngClass]="{
    'rt-loading-state': loading,
    'rt-disabled-state': disabled,
    'icon': onlyIcon
    }"
>
    <span><ng-content></ng-content></span>
</button>
