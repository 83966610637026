export interface Notification {
  id: string;
  title: string;
  content: string;
  type: string;
  read: boolean;
  read_by: string;
  removable: boolean;
  created_at: string;
  updated_at: string;

  organization_id: string;
}