<div class="rtz-ui-demo-wrap">
    <aside>
        <ul>
            <li><a href="ui-docs/docs-colors">Color Scheme</a></li>
            <li><a href="ui-docs/docs-typography">Typography</a></li>
            <li><a class="active" href="ui-docs/docs-icons">Icons</a></li>
            <li><a href>Headers (Content)</a></li>
            <li><a href="ui-docs/docs-buttons">Buttons</a></li>
            <li><a href>Toasts</a></li>
        </ul>
    </aside>
    <main>
        <div class="nv-section">
            <div class="nv-port">
                <div class="nv-row gap-24">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <h2>Icons</h2>
                            <p>Use <a href="https://icomoon.io/app/#/projects">IcoMoon App</a> with provided json files to see encoding & entities</p>
                            <div class="rtz-ui-demo-icons-link">
                                <p>Riterz <span><a href="https://dev.riterz.io/src/fonts/collection-riterz/selection.zip">collection</a></span></p>
                                <p>Riterz (Navus legacy) <span><a href="https://dev.riterz.io/src/fonts/collection-riterz-navus-legacy/selection.json">collection</a></span></p>
                                <p>Riterz Social Icons <span><a href="https://dev.riterz.io/src/fonts/collection-riterz-social/selection.json">collection</a></span></p>
                                <p class="spaceless">Navus Hub (deprecated) <span><a href="https://dev.riterz.io/src/fonts/collection-navus-hub/selection.json">collection</a></span></p>
                            </div><!-- /.rtz-ui-demo-icons-link -->
                        </div><!-- /.nv-column-content -->
                    </div><!-- /.nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-port -->
        </div><!-- /.nv-section -->
    </main>
</div><!-- /.rtz-ui-demo-wrap -->
