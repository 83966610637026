import { Component } from '@angular/core';

@Component({
    selector: 'app-config-ui-page',
    templateUrl: './config-ui-23.component.html'
})
export class ConfigUi23Component {
    showSideNav: boolean = true;

    constructor() { }

}
