<div class="rtz-config-frame" [class.side-menu-collapsed]="showSideNav">
    <header class="rtz-cf-header">
        <div class="rtz-cf-logo-port">
            <figure>
                <a href title="Riterz configurator"><img src="../../assets/riterz-logo.svg" alt="Riterz.com"></a>
            </figure>
        </div><!-- /.rtz-cf-logo-port -->
        <nav class="rtz-cf-header-menu">
            <ul>
                <li><a href title="Dashboard" class="active">Dashboard</a></li>
                <li><a href title="Files">Files</a></li>
                <li><a href title="News">News</a></li>
                <li><a href title="Advertising">Advertising</a>
                    <ul>
                        <li><a href title="title">Sub item 1</a></li>
                        <li><a href title="title">Sub item 2</a></li>
                        <li><a href title="title">Sub item 3</a></li>
                        <li><a href title="title">Sub item 4</a></li>
                    </ul>
                </li>
                <li><a href title="Sponsors">Sponsors</a></li>
                <li><a href title="Events">Events</a>
                    <ul>
                        <li>
                            <a href title="Paradigm Shifting New Horizonts">
                                <img src="../../assets/avatar-dummy.jpg" alt="Paradigm image">
                                <span>Paradigm Shifting New Horizonts</span>
                            </a>
                        </li>
                        <li>
                            <a href title="AB Clone 2024">
                                <img src="../../assets/logo-event-esicm.png" alt="AB Clone image">
                                <span>AB Clone 2024</span>
                            </a>
                        </li>
                        <li class="alt"><a href title="View all events">View all events</a></li>
                    </ul>
                </li>
                <li><a href title="Websites">Websites</a></li>
            </ul>
        </nav><!-- /.rtz-cf-header-menu -->
        <div class="rtz-cf-user-settings">
            <div class="rtz-ui-settings-box org"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure><img src="../../assets/riterz-textless-logo.svg" alt="Company Name"></figure>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                        <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                        <li><a href title="Billing" class="rtz-ico-billing">Billing</a></li>
                        <li><a href title="Step by step guide" class="rtz-ico-step-by-step">Step by step guide</a></li>
                        <li><a href title="Organisation Settings" class="rtz-ico-organisation">Organisation Settings</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
            <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                <div class="rtz-usb-face">
<!--                    <figure><img src="../../assets/avatar-placeholder.png" alt="User Name"></figure>-->
                    <span class="rtz-initials">WJ</span>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Profile" class="rtz-ico-profile">Profile</a></li>
                        <li><a href title="Privacy" class="rtz-ico-privacy">Privacy</a></li>
                        <li><a href title="Legal" class="rtz-ico-legal">Legal</a></li>
                        <li><a href title="Sign Out" class="rtz-ico-signout">Sign Out</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
        </div><!-- /.rtz-cf-user-settings -->
    </header><!-- /.rtz-cf-header -->


    <!-- DASHBAORD / Event Flow 03 Events list -->
    <div class="rtz-config-body">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <header class="rtz-config-top-dash">
                    <h1>Profile</h1>
                    <div class="rtz-organizations-dash-tools">
                        <div class="rt-btn-box flow-end gap-20">
                            <a href class="rt-btn quaternary txt medium" title="Close">Close</a>
                            <a href class="rt-btn primary normal" title="Save">Save</a>
                        </div><!-- /.nv-btn-box -->
                    </div><!-- /.rtz-organizations-dash-tools -->
                </header><!-- /rtz-config-top-dash -->

                <div class="rt-card">
                    <div class="rtz-profile-edit-box nv-row gap-24">
                        <div class="nv-column desk-2 lap-4 phab-12">
                            <div class="nv-column-content">
                                <div class="rtz-profile-img">
                                    <figure></figure>
                                    <div class="rt-split">
                                        <button type="button" class="rt-btn quaternary txt normal" title="Edit image">Edit image</button>
                                        <button type="button" class="rt-btn quaternary txt normal" title="Delete">Delete</button>
                                    </div><!-- /.rt-split -->
                                </div><!-- /.rtz-profile-img -->
                                <p class="spaceless">We recommend using an image up to 500KB in size and with dimensions of 300x300 pixels.</p>
                                <div class="nv-divider phab-medium"></div>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.rtz-pe-box -->
                        <div class="nv-column desk-4 phab-12">
                            <div class="nv-column-content">
                                <h2>Basic information</h2>
                                <div class="nv-row gap-24 propagate">
                                    <div class="nv-column desk-6 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-mandatory">
                                                <label>
                                                    <input type="text" placeholder="First name">
                                                    <span class="label-title">First name</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-6 tab-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-mandatory">
                                                <label>
                                                    <input type="text" placeholder="Last name">
                                                    <span class="label-title">Last name</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-mandatory">
                                                <label>
                                                    <input type="email" placeholder="Email">
                                                    <span class="label-title">Email</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item select-item nv-mandatory">
                                                <label>
                                                    <select>
                                                        <option>Albania</option>
                                                        <option>Angola</option>
                                                        <option>Bosnia</option>
                                                        <option>Belgium</option>
                                                        <option>Bangladesh</option>
                                                    </select>
                                                    <span class="label-title">Country</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div> <!-- /.nv-row -->
                                <div class="nv-row">
                                    <div class="nv-column desk-12">
                                        <div class="nv-column-content">
                                            <div class="nv-form-item nv-mandatory">
                                                <label>
                                                    <input type="text" placeholder="Company">
                                                    <span class="label-title">Company</span>
                                                </label>
                                            </div><!-- /nv-form-item -->
                                        </div><!-- /.nv-column-content -->
                                    </div><!-- /.nv-column -->
                                </div><!-- /.nv-row -->
                                <div class="nv-divider phab-medium"></div>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.nv-column -->
                        <div class="nv-column desk-4 lap-none"></div><!-- /.nv-column -->
                        <div class="nv-column desk-2 lap-4 phab-12">
                            <div class="nv-column-content">
                                <h2>Security</h2>
                                <p><a href class="rt-btn quaternary txt medium" title="Change password">Change password</a></p>
                                <h2>Account</h2>
                                <p><a href class="rt-btn quaternary txt medium" title="Delete account">Delete account</a></p>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /.rtz-pe-box -->
                    </div><!-- /.rtz-profile-edit-box -->
                </div><!-- /.rt-card -->

                <div class="rt-spacer"></div>

                <header class="rtz-config-top-dash">
                    <h1>Thompson's Tech Emporium</h1>
                    <div class="rtz-organizations-dash-tools">
                        <div class="nv-btn-box gap-20">
                            <a href class="nv-btn alert txt medium" title="Delete">Delete</a>
                            <a href class="nv-btn quaternary txt medium" title="Close">Close</a>
                            <a href class="nv-btn primary medium" title="Save">Save</a>
                        </div><!-- /.nv-btn-box -->
                        <div class="rtz-dots-action">
                            <div class="rtz-da-toggler" (click)="toggleSubNavigation('sub1')" [class.on]="showSubNavigation === 'sub1'"><span></span></div> <!-- < toggle class 'on' -->
                            <ul class="rt-tt-options">
                                <li><a href title="Audit Log">Audit Log</a></li>
                                <li><a href title="Something else">Something else</a></li>
                            </ul><!-- /.rt-tt-options -->
                        </div><!-- /.rtz-dots-action -->
                    </div><!-- /.rtz-organizations-dash-tools -->
                </header><!-- /rtz-config-top-dash -->

                <div class="rtz-resp-table-wrap">
                    <table class="rtz-table-mp">
                        <tr>
                            <th>Event timestamp</th>
                            <th>Event</th>
                            <th>Message</th>
                            <th>Object type</th>
                            <th>Object ID</th>
                            <th>user id</th>
                            <th>source</th>
                            <th>Ip address</th>
                        </tr>
                        <tr>
                            <td>02-Jan-2024 03:13:13</td>
                            <td><span title="Organisation creation">Organisation creation</span></td>
                            <td><span title="Organisation Riterz has been created.">Organisation Riterz has been created.</span></td>
                            <td><span title="Organisation">Organisation</span></td>
                            <td><span title="55b64a2a-0e6f-44dd-9db7-141e5f815307">55b64a2a-0e6f-44dd-9db7-141e5f815307</span></td>
                            <td><span title="1220efdb-2cd5-4a18-84ce-19607693f7ac">1220efdb-2cd5-4a18-84ce-19607693f7ac</span></td>
                            <td><span title="Windows 11">Windows 11</span></td>
                            <td><span title="98.120.129.99">98.120.129.99</span></td>
                        </tr>
                        <tr>
                            <td>02-Jan-2024 03:13:13</td>
                            <td><span title="Organisation creation">Organisation creation</span></td>
                            <td><span title="Organisation Riterz has been created.">Organisation Riterz has been created.</span></td>
                            <td><span title="Organisation">Organisation</span></td>
                            <td><span title="55b64a2a-0e6f-44dd-9db7-141e5f815307">55b64a2a-0e6f-44dd-9db7-141e5f815307</span></td>
                            <td><span title="1220efdb-2cd5-4a18-84ce-19607693f7ac">1220efdb-2cd5-4a18-84ce-19607693f7ac</span></td>
                            <td><span title="Windows 11">Windows 11</span></td>
                            <td><span title="98.120.129.99">98.120.129.99</span></td>
                        </tr>
                        <tr>
                            <td>02-Jan-2024 03:13:13</td>
                            <td><span title="Organisation creation">Organisation creation</span></td>
                            <td><span title="Organisation Riterz has been created.">Organisation Riterz has been created.</span></td>
                            <td><span title="Organisation">Organisation</span></td>
                            <td><span title="55b64a2a-0e6f-44dd-9db7-141e5f815307">55b64a2a-0e6f-44dd-9db7-141e5f815307</span></td>
                            <td><span title="1220efdb-2cd5-4a18-84ce-19607693f7ac">1220efdb-2cd5-4a18-84ce-19607693f7ac</span></td>
                            <td><span title="Windows 11">Windows 11</span></td>
                            <td><span title="98.120.129.99">98.120.129.99</span></td>
                        </tr>
                    </table><!-- /.rtz-table-mp -->
                </div><!-- /rtz-resp-table-wrap -->

                <div class="nv-divider desk-medium"></div>

                <div class="rtz-card">
                    <div class="rtz-resp-table-wrap">
                        <table class="rtz-table-lp">
                            <tr>
                                <td>
                                    <label>name</label>
                                    <p><strong>Evalute</strong></p>
                                </td>
                                <td>
                                    <label>Number of campaigns</label>
                                    <p><strong>3</strong></p>
                                </td>
                                <td>
                                    <button class="rt-btn small primary">Manage</button>
                                </td>
                                <td class="status">
                                    <label>Status</label>
                                    <p><strong>Active</strong></p>
                                </td>
                                <td class="action">
                                    <div class="rtz-dots-action">
                                        <div class="rtz-da-toggler" (click)="toggleSubNavigation('sub3')" [class.on]="showSubNavigation === 'sub3'"><span></span></div> <!-- < toggle class 'on' -->
                                        <ul class="rt-tt-options">
                                            <li><a href title="Edit">Edit</a></li>
                                            <li><a href title="Delete" class="del">Delete</a></li>
                                        </ul><!-- /.rt-tt-options -->
                                    </div><!-- /.rtz-dots-action -->
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>name</label>
                                    <p><strong>Evalute</strong></p>
                                </td>
                                <td>
                                    <label>Number of campaigns</label>
                                    <p><strong>3</strong></p>
                                </td>
                                <td>
                                    <button class="rt-btn small primary">Manage</button>
                                </td>
                                <td class="status">
                                    <label>Status</label>
                                    <p><strong>Active</strong></p>
                                </td>
                                <td class="action">
                                    <div class="rtz-dots-action">
                                        <div class="rtz-da-toggler" (click)="toggleSubNavigation('sub3')" [class.on]="showSubNavigation === 'sub3'"><span></span></div> <!-- < toggle class 'on' -->
                                        <ul class="rt-tt-options">
                                            <li><a href title="Edit">Edit</a></li>
                                            <li><a href title="Delete" class="del">Delete</a></li>
                                        </ul><!-- /.rt-tt-options -->
                                    </div><!-- /.rtz-dots-action -->
                                </td>
                            </tr>
                        </table><!-- /.rtz-table-mp -->
                    </div><!-- /rtz-resp-table-wrap -->
                </div><!-- /.rtz-card -->

                <div class="nv-divider desk-medium"></div>

                <div class="rtz-resp-table-wrap">
                    <table class="rtz-table-mp">
                        <tr>
                            <th>name</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                        <tr>
                            <td>
                                <div class="rtz-button-cell">
                                    <p>Thompson's Tech Emporium</p>
                                    <button type="button" class="nv-btn small secondary">Select</button>
                                </div><!-- /.rtz-button-cell -->
                            </td>
                            <td class="status">Status</td>
                            <td class="actions">
                                <div class="rtz-dots-action">
                                    <div class="rtz-da-toggler" (click)="toggleSubNavigation('sub2')" [class.on]="showSubNavigation === 'sub2'"><span></span></div> <!-- < toggle class 'on' -->
                                    <ul class="rt-tt-options">
                                        <li><a href title="Edit">Edit</a></li>
                                        <li><a href title="Delete" class="del">Delete</a></li>
                                    </ul><!-- /.rt-tt-options -->
                                </div><!-- /.rtz-dots-action -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="rtz-button-cell">
                                    <p>Thompson's Tech Emporium</p>
                                    <button type="button" class="nv-btn small secondary">Select</button>
                                </div><!-- /.rtz-button-cell -->
                            </td>
                            <td class="status">Status</td>
                            <td class="actions">
                                <div class="rtz-dots-action">
                                    <div class="rtz-da-toggler" (click)="toggleSubNavigation('sub3')" [class.on]="showSubNavigation === 'sub3'"><span></span></div> <!-- < toggle class 'on' -->
                                    <ul class="rt-tt-options">
                                        <li><a href title="Edit">Edit</a></li>
                                        <li><a href title="Delete" class="del">Delete</a></li>
                                    </ul><!-- /.rt-tt-options -->
                                </div><!-- /.rtz-dots-action -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="rtz-button-cell">
                                    <p>Thompson's Tech Emporium</p>
                                    <button type="button" class="nv-btn small secondary">Select</button>
                                </div><!-- /.rtz-button-cell -->
                            </td>
                            <td class="status">Status</td>
                            <td class="actions">
                                <div class="rtz-dots-action">
                                    <div class="rtz-da-toggler" (click)="toggleSubNavigation('sub4')" [class.on]="showSubNavigation === 'sub4'"><span></span></div> <!-- < toggle class 'on' -->
                                    <ul class="rt-tt-options">
                                        <li><a href title="Edit">Edit</a></li>
                                        <li><a href title="Delete" class="del">Delete</a></li>
                                    </ul><!-- /.rt-tt-options -->
                                </div><!-- /.rtz-dots-action -->
                            </td>
                        </tr>
                    </table><!-- /.rtz-table-mp -->
                </div><!-- /rtz-resp-table-wrap -->

                <div class="rtz-events-list-table rtz-card" style="display: none">
                    <div class="rtz-resp-table-wrap">
                        <table class="rtz-el-table">
                            <tr>
                                <th>Event</th>
                                <th>Category</th>
                                <th>Start</th>
                                <th>End</th>
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <figure><img src="../../assets/navus-logo-app-rounded.png" alt="Event name"></figure>
                                        AB Clone 2024
                                    </h4>
                                </td>
                                <td>On site</td>
                                <td>04 Apr 2024</td>
                                <td>06 Apr 2024</td>
                                <a href title="Event name details"></a> <!-- < not needed perhaps -->
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <figure><img src="../../assets/logo-event-esicm.png" alt="Event name"></figure>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit
                                    </h4>
                                </td>
                                <td>On site</td>
                                <td>04 Apr 2024</td>
                                <td>06 Apr 2024</td>
                                <a href title="Event name details"></a> <!-- < not needed perhaps -->
                            </tr>
                        </table>
                    </div><!-- /.rtz-resp-table-wrap -->
                </div><!-- /.rtz-events-list-table -->

                <div class="rtz-events-list-table rtz-card" style="display: none">
                    <div class="rtz-resp-table-wrap">
                        <table class="rtz-el-table">
                            <tr>
                                <th>Sponsor</th>
                                <th>Exhibitor</th>
                                <th>Email Address</th>
                                <th>Contact No.</th>
                                <th>Level</th>
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <figure><img src="../../assets/navus-logo-app-rounded.png" alt="Event name"></figure>
                                        AB Clone 2024
                                    </h4>
                                </td>
                                <td>Yes</td>
                                <td><a href="mailto:nickcave@ttemporium.com" title="nickcave@ttemporium.com">nickcave@ttemporium.com</a></td>
                                <td>012 3456 789</td>
                                <td><span class="platinum">Platinum</span></td>
                                <a href title="Event name details"></a> <!-- < not needed perhaps -->
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <figure><img src="../../assets/navus-logo-app-rounded.png" alt="Event name"></figure>
                                        AB Clone 2024
                                    </h4>
                                </td>
                                <td>No</td>
                                <td><a href="mailto:nickcave@ttemporium.com" title="nickcave@ttemporium.com">nickcave@ttemporium.com</a></td>
                                <td>012 3456 789</td>
                                <td><span class="gold">Gold</span></td>
                                <a href title="Event name details"></a> <!-- < not needed perhaps -->
                            </tr>
                        </table>
                    </div><!-- /.rtz-resp-table-wrap -->
                </div><!-- /.rtz-events-list-table -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->

    <!-- DASHBAORD / Event Flow 00 User without organisation -->
    <div class="rtz-config-body" style="display: none;">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <div class="rtz-dashboard-welcome">
                    <figure><img src="../../assets/logo-textless.png" alt="Riterz.com"></figure>
                    <h1>Welcome, Lara!</h1>
                    <p>Riterz is transforming how organisations monetise their media channels and manage their sponsorships. Let’s get started, create your organisation.</p>
                    <p><a href class="nv-btn medium secondary">Create Organisation</a></p>
                    <p>Your first Riterz Organisation? See a <a href title="Step by step guide Riterz">step-by-step guide</a>.</p>
                </div><!-- /.rtz-dashboard-welcome -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->


    <!-- DASHBAORD / Powering Your Event -->
    <div class="rtz-config-body" style="display:none;">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <header class="rtz-config-top-dash">
                    <h1>Dashboard</h1>
                </header><!-- /rtz-config-top-dash -->
                <p class="gray60">Powering Your Event Management</p>
                <div class="rtz-dashboard-cards-plain">
                    <div class="rtz-dcp-card">
                        <h3>User registration</h3>
                        <h4>512</h4>
                        <p>Lorem ipsom dolor</p>
                    </div><!-- /.rtz-dcp-card -->

                    <div class="rtz-dcp-card">
                        <h3>Tickets sold</h3>
                        <h4>705</h4>
                        <p>Lorem ipsom dolor</p>
                    </div><!-- /.rtz-dcp-card -->

                    <div class="rtz-dcp-card">
                        <h3>Gross Sales</h3>
                        <h4>1214332.00</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi?</p>
                    </div><!-- /.rtz-dcp-card -->

                    <div class="rtz-dcp-card">
                        <h3>User registration</h3>
                        <h4>512</h4>
                        <p>Lorem ipsom dolor</p>
                    </div><!-- /.rtz-dcp-card -->

                    <div class="rtz-dcp-card">
                        <h3>Sales Overview</h3>
                        <h4>2100</h4>
                        <p>Lorem ipsom dolor</p>
                    </div><!-- /.rtz-dcp-card -->
                </div><!-- /.rtz-dashboard-cards-plain -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->


    <!-- DASHBAORD / Event 01 Organisation level -->
    <div class="rtz-config-body" style="display: none;">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <header class="rtz-config-top-dash">
                    <h1>Events</h1>
                    <div class="rtz-organizations-dash-tools">
                        <a href class="nv-btn secondary medium" data-icon-pl="&#xe927;">Create New Event</a>
                    </div><!-- /.rtz-organizations-dash-tools -->
                </header><!-- /rtz-config-top-dash -->
                <div class="rtz-placeholder-box">
                    <figure><img src="../../assets/empty-states/empty-state-events-rtz.svg" alt="No Events"></figure>
                    <p><strong>No events!</strong></p>
                    <p class="grey60">Once you add event, you will see it here.</p>
                    <p><a href class="nv-btn medium secondary">Create event</a></p>
                </div><!-- /.rtz-placeholder-box -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->

    <!-- DASHBAORD / Events List -->
    <div class="rtz-config-body" style="display: none;">
        <aside class="rtz-config-side-panel">
            <span class="rtz-side-panel-toggle" (click)="showSideNav = !showSideNav"></span>
            <div class="rtz-config-side-panel-up">
                <div class="cm-organization">
                    <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                        <div class="cm-organization-logo">
                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                        </div><!-- /.cm-organization-logo -->
                        <div class="cm-organization-label">
                            <h3>ENP</h3>
                            <p>Organization</p>
                        </div><!-- /.cm-organization-label -->
                    </div><!-- /.cm-current-organisation -->
                    <div class="cm-organisation-list">
                        <div class="cm-organization-search">
                            <input type="text" name="" placeholder="Search for organizations…">
                        </div><!-- /.cm-organization-search -->
                        <nav>
                            <ul>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                                <li>
                                    <div class="cm-list-organization">
                                        <div class="cm-organization-logo">
                                            <span style="background-image: url('../../assets/ic-organization-placeholder.svg');"></span>
                                        </div><!-- /.cm-organization-logo -->
                                        <div class="cm-organization-label">
                                            <h3>ENP</h3>
                                            <p>Organization</p>
                                        </div><!-- /.cm-organization-label -->
                                    </div><!-- /cm-list-organization -->
                                </li>
                            </ul>
                        </nav>
                        <a href="" class="cm-add">
                            Add organization
                        </a><!-- /.cm-add -->
                    </div><!-- /.cm-organisation-list -->
                </div><!-- /.cm-organisation -->

                <h3 class="rtz-ui-menu-label" style="display: none;">Admin panel</h3>

                <nav class="rtz-ui-menu">
                    <ul>
                        <li><a href title="Dashboard" class="rtz-ico-dashboard">Dashboard</a></li>
                        <li><a href title="File Manager" class="rtz-ico-file-manager">File Manager</a></li>
                        <li><a href title="News Manager" class="rtz-ico-news-manager active">News Manager</a></li>
                        <li><a href title="Events" class="rtz-ico-events">Events</a></li>
                        <li><a href title="Advertising" class="rtz-ico-advertising">Advertising</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-config-side-panel-up -->
            <div class="rtz-config-side-panel-down">
                <div class="rtz-ui-global-settings">
                    <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                        <nav class="rtz-ui-menu quasi">
                            <ul>
                                <li><a href title="Settings" class="rtz-ico-config-settings">Settings</a></li>
                            </ul>
                        </nav><!-- /.rtz-ui-menu quasi -->
                        <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                            <ul>
                                <li><a href title="Users" class="rtz-ico-users">Users</a></li>
                                <li><a href title="Subscriptions" class="rtz-ico-subscriptions">Subscriptions</a></li>
                                <li><a href title="Billing" class="rtz-ico-billing">Billing</a></li>
                                <li><a href title="Organisation Settings" class="rtz-ico-organisation">Organisation Settings</a></li>
                            </ul>
                        </nav><!-- /.rtz-ui-menu -->
                    </div><!-- /.rtz-ui-settings-box -->
                </div><!-- /.rtz-ui-global-settings -->
            </div><!-- /.rtz-config-side-panel-down -->
        </aside><!-- /.rtz-config-side-panel -->
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <section class="nv-row config-plates gap-30 clean small-spaced-top spaced-bottom">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <header class="rtz-config-top-dash">
                                <h1>Organisations</h1>
                                <div class="rtz-organizations-dash-tools">
                                    <a href class="nv-btn secondary medium" data-icon-pl="&#xe927;">Invite</a>
                                </div><!-- /.rtz-organizations-dash-tools -->
                            </header><!-- /rtz-config-top-dash -->
                            <div class="rtz-organizations-search-n-sort">
                                <div class="rtz-organizations-search-port">
                                    <label class="rtz-organizations-sp-input rtz-mellow">
                                        <input type="search" name placeholder="Search">
                                    </label><!-- /.rtz-organizations-sp-input -->
                                    <div class="rtz-organizations-sp-filter">
                                        <a class="filter" href title="Filters">Filters</a>
                                        <a href title="Clear">Clear</a>
                                    </div><!-- /.rtz-organizations-sp-filter -->
                                </div><!-- /.rtz-organizations-search-port -->
                                <div class="rtz-organizations-search-sort rtz-mellow">
                                    <select name="sortex">
                                        <option>Sort by</option>
                                        <option>Date</option>
                                        <option>Popularity</option>
                                        <option>Alphabet</option>
                                    </select>
                                </div><!-- /.rtz-organizations-search-sort -->
                                <div class="rtz-organizations-search-filters">
                                    <p>
                                        <span>Cat 1</span>
                                        <a href title="delete"></a>
                                    </p>
                                    <p>
                                        <span>Dolor sit</span>
                                        <a href title="delete"></a>
                                    </p>
                                    <p>
                                        <span>Extremely loooong filter set right here</span>
                                        <a href title="delete"></a>
                                    </p>
                                </div><!-- /.rtz-organizations-search-filters -->
                            </div><!-- /.rtz-organizations-search-n-sort -->
                        </div>
                    </div><!-- /nv-column -->
                </section><!-- /nv-row -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->
</div><!-- /.rtz-config-frame -->
