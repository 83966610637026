import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class OrganizationAdvertiserService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationAdvertisers(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/advertisers`, params);
  }

  // getOrganizationAdvertiser(organizationId: string, advertiserId: string, params: any = {}) {
  //   return this.apiService.get(`organizations/${organizationId}/advertisers/${advertiserId}`, params);
  // }

  // updateOrganizationAdvertiser(organizationId: string, advertiserId: string, params: any = {}) {
  //   return this.apiService.put(`organizations/${organizationId}/advertisers/${advertiserId}`, params);
  // }

  createOrganizationAdvertiser(organizationId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/advertisers`, params);
  }

  // deleteOrganizationAdvertiser(organizationId: string, advertiserId: string) {
  //   return this.apiService.delete(`organizations/${organizationId}/advertisers/${advertiserId}`);
  // }

}
