import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class OrganizationPublisherService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizationPublishers(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}/publishers`, params);
  }

  // getOrganizationPublisher(organizationId: string, publisherId: string, params: any = {}) {
  //   return this.apiService.get(`organizations/${organizationId}/publishers/${publisherId}`, params);
  // }

  // updateOrganizationPublisher(organizationId: string, publisherId: string, params: any = {}) {
  //   return this.apiService.put(`organizations/${organizationId}/publishers/${publisherId}`, params);
  // }

  createOrganizationPublisher(organizationId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/publishers`, params);
  }

  // deleteOrganizationPublisher(organizationId: string, publisherId: string) {
  //   return this.apiService.delete(`organizations/${organizationId}/publishers/${publisherId}`);
  // }

}
