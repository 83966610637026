import { Component } from '@angular/core';

@Component({
    selector: 'app-ematerials-page',
    templateUrl: './ematerials.component.html'
})
export class EmaterialsComponent {

    constructor() { }

}
