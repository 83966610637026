import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, RouterModule } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { RiterzCoreConfiguration, RiterzCoreModule } from 'riterz-core';
import { environment } from '../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes),     
    provideClientHydration(withHttpTransferCacheOptions({
      includePostRequests: true
    })),
    provideHttpClient(withFetch()),
    provideAnimations(),
    importProvidersFrom(RiterzCoreModule.forRoot({
      apiRoot: environment.riterz_api_url,
      accessTokenName: environment.riterz_access_token_name,
      refreshTokenName: environment.riterz_refresh_token_name,
      clientId: environment.riterz_client_id,
      googleAnalyticsId: environment.google_analytics_id,
    } as RiterzCoreConfiguration)),
    importProvidersFrom(RouterModule.forRoot(routes)),
  ]
};
