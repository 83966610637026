<div class="nv-config-layout">
    <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/riterz-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <div class="nv-config-control-bar">
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Events</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <h1>Events</h1>
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-phab">
                    <ul>
                        <li>
                            <a class="active">Registration</a>
                        </li>
                        <li>
                            <a>Integration</a>
                        </li>
                    </ul>
                </nav><!-- /.nv-tabs-nav -->
            </div><!-- /.nv-line-tabs -->
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port">
            <section class="nv-section nv-coming-soon-section">
                <div class="nv-port">
                    <div class="nv-row config-plates">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <div class="nv-coming-soon-pane">
                                    <div class="csp-image">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="129" viewBox="0 0 200 129">
                                            <g fill="none">
                                                <g>
                                                    <path fill="#EAEAEA" d="M54.485 80.399l-.407-.004c-12.331-.2-22.456-9.48-23.98-21.445-.628-1.825-2.36-3.136-4.398-3.136H11.96C5.325 55.634 0 50.199 0 43.522c0-6.678 5.325-12.112 11.96-12.288v-.005h39.867c1.835 0 3.323-1.487 3.323-3.322 0-1.825-1.472-3.307-3.294-3.322h-9.331c-.11-.002-.221 0-.332 0-6.79 0-12.293-5.504-12.293-12.293S35.404 0 42.193 0c.11 0 .222.001.332.004V0h102.99c12.514 0 22.845 9.35 24.386 21.446.627 1.826 2.36 3.139 4.4 3.139h13.739c6.635.18 11.96 5.614 11.96 12.292s-5.325 12.112-11.96 12.288v.004h-30.212c-1.835 0-3.322 1.488-3.322 3.323 0 1.825 1.472 3.307 3.294 3.322h.007c6.79 0 12.293 5.503 12.293 12.292 0 6.79-5.504 12.293-12.293 12.293-.11 0-.221-.002-.332-.005v.005H54.485z" transform="translate(0 -2) translate(0 3.638)"/>
                                                    <g fill="#373737" opacity=".5">
                                                        <path d="M2.88.82v5.004c0 .244.198.443.442.443.245 0 .443-.199.443-.443V.82c0-.245-.198-.443-.443-.443-.244 0-.443.198-.443.443z" transform="translate(0 -2) translate(0 3.638) translate(11.96 13.953)"/>
                                                        <path d="M.82 3.765h5.004c.244 0 .443-.198.443-.443 0-.244-.199-.443-.443-.443H.82c-.245 0-.443.199-.443.443 0 .245.198.443.443.443z" transform="translate(0 -2) translate(0 3.638) translate(11.96 13.953)"/>
                                                    </g>
                                                    <g fill="#373730" opacity=".5">
                                                        <path d="M2.88.82v5.004c0 .244.198.443.442.443.245 0 .443-.199.443-.443V.82c0-.245-.198-.443-.443-.443-.244 0-.443.198-.443.443z" transform="translate(0 -2) translate(0 3.638) translate(165.449 89.037)"/>
                                                        <path d="M.82 3.765h5.004c.244 0 .443-.198.443-.443 0-.244-.199-.443-.443-.443H.82c-.245 0-.443.199-.443.443 0 .245.198.443.443.443z" transform="translate(0 -2) translate(0 3.638) translate(165.449 89.037)"/>
                                                    </g>
                                                    <g fill="#373737" opacity=".5">
                                                        <path d="M2.88.82v5.004c0 .244.198.443.442.443.245 0 .443-.199.443-.443V.82c0-.245-.198-.443-.443-.443-.244 0-.443.198-.443.443z" transform="translate(0 -2) translate(0 3.638) translate(180.066 .664)"/>
                                                        <path d="M.82 3.765h5.004c.244 0 .443-.198.443-.443 0-.244-.199-.443-.443-.443H.82c-.245 0-.443.199-.443.443 0 .245.198.443.443.443z" transform="translate(0 -2) translate(0 3.638) translate(180.066 .664)"/>
                                                    </g>
                                                </g>
                                                <g transform="translate(0 -2) rotate(-13 112.014 -167.115)">
                                                    <g transform="rotate(-16 174.035 9.392)">
                                                        <rect width="14.618" height="42.525" x="1.317" y="1.017" fill="#FFF" rx="6"/>
                                                        <path fill="#373737" d="M5.304.353h6.644c2.569 0 4.651 2.082 4.651 4.651v34.552c0 2.568-2.082 4.65-4.65 4.65H5.303c-2.57 0-4.651-2.082-4.651-4.65V5.004c0-2.569 2.082-4.651 4.65-4.651zm0 1.329c-1.835 0-3.323 1.487-3.323 3.322v34.552c0 1.834 1.488 3.322 3.323 3.322h6.644c1.835 0 3.322-1.488 3.322-3.322V5.004c0-1.835-1.487-3.322-3.322-3.322H5.304z"/>
                                                        <path fill="#373737" d="M8.25 8.87v27.152c0 .367.297.665.664.665.367 0 .664-.298.664-.665V8.87c0-.367-.297-.665-.664-.665-.367 0-.665.298-.665.665z" opacity=".6"/>
                                                    </g>
                                                    <rect width="6.645" height="42.525" x=".664" y="17.276" fill="#FFF" rx="3.322"/>
                                                    <path fill="#FFF" d="M7.309 21.262L77.076 4.651 77.076 72.425 7.309 55.814z"/>
                                                    <path fill="#373737" d="M77.74 3.81v69.456l-15.09-3.592c-.356-.085-.577-.444-.492-.8.08-.332.394-.546.724-.507l.077.014 13.453 3.203V5.492L36.947 14.89c-.357.085-.716-.136-.8-.493-.08-.331.105-.664.418-.778l.074-.022L77.74 3.81zM20.444 18.135c.08.332-.105.664-.418.778l-.074.023-11.978 2.851V55.29l49.744 11.844c.357.085.577.443.492.8-.079.332-.393.545-.724.506l-.076-.013L6.645 56.339V20.738l12.998-3.095c.357-.085.715.135.8.492zm11.288-3.37c.357-.085.716.135.8.492.086.357-.135.715-.492.8l-7.71 1.836c-.357.085-.715-.135-.8-.492-.085-.357.135-.716.492-.8l7.71-1.836z"/>
                                                    <path class="csp-megaphone" d="M11.296 27.725v21.627c0 1.852 1.276 3.46 3.08 3.882l55.452 12.947c1.429.334 2.858-.554 3.192-1.984.046-.198.07-.4.07-.604v-50.11c0-1.468-1.19-2.658-2.658-2.658-.204 0-.406.024-.604.07L14.376 23.842c-1.804.422-3.08 2.03-3.08 3.883z"/>
                                                    <rect width="10.631" height="75.748" x="77.076" y=".664" fill="#FFF" rx="5.316"/>
                                                    <path fill="#373737" d="M81.063 0h2.658c2.569 0 4.651 2.082 4.651 4.651v67.774c0 2.569-2.082 4.651-4.651 4.651h-2.658c-2.569 0-4.651-2.082-4.651-4.65V4.65C76.412 2.082 78.494 0 81.063 0zm0 1.329c-1.835 0-3.322 1.487-3.322 3.322v67.774c0 1.835 1.487 3.323 3.322 3.323h2.658c1.835 0 3.322-1.488 3.322-3.323V4.651c0-1.835-1.487-3.322-3.322-3.322h-2.658zM1.329 25.198v-5.33c0-1.023 1.159-1.928 2.658-1.928 1.499 0 2.658.905 2.658 1.929v37.339c0 1.024-1.16 1.928-2.658 1.928-1.5 0-2.658-.904-2.658-1.928V29.07c0-.367-.298-.665-.665-.665-.367 0-.664.298-.664.665v28.138c0 1.84 1.816 3.257 3.987 3.257 2.17 0 3.986-1.417 3.986-3.257v-37.34c0-1.84-1.816-3.257-3.986-3.257C1.816 16.611 0 18.03 0 19.87v5.329c0 .366.297.664.664.664.367 0 .665-.298.665-.664z"/>
                                                </g>
                                                <path fill="#373737" d="M153.488 128.887H47.176c-.734 0-1.329.269-1.329.6 0 .331.595.6 1.33.6h106.311c.734 0 1.33-.269 1.33-.6 0-.331-.596-.6-1.33-.6zm-119.601 0H20.598c-.734 0-1.329.269-1.329.6 0 .331.595.6 1.329.6h13.289c.734 0 1.329-.269 1.329-.6 0-.331-.595-.6-1.329-.6zm146.18 0h-13.29c-.734 0-1.328.269-1.328.6 0 .331.594.6 1.328.6h13.29c.733 0 1.328-.269 1.328-.6 0-.331-.595-.6-1.329-.6z" transform="translate(0 -2)"/>
                                                <g fill="#373737">
                                                    <path d="M2.512 24.35l15.704-2.769c.723-.127 1.25-.561 1.179-.97-.072-.407-.717-.634-1.44-.507l-15.704 2.77c-.723.127-1.25.56-1.178.969.072.408.716.635 1.439.507zM6.12 2.59L1.994 17.993c-.19.71-.668 1.197-1.068 1.09-.4-.108-.57-.77-.38-1.478L4.672 2.2c.19-.708.668-1.196 1.068-1.09.4.108.57.77.38 1.479zM13.94 41.697L2.664 30.421c-.519-.52-.702-1.177-.41-1.47.294-.293.952-.11 1.47.41l11.277 11.275c.519.52.702 1.177.41 1.47-.294.293-.952.11-1.47-.409z" transform="translate(0 -2) translate(139.02 12.325)"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div><!-- /.csp-image -->
                                    <header>
                                        <h2 class="medium">Coming soon!</h2>
                                        <p>We will notify you once this feature is ready.</p>
                                    </header>
                                </div><!-- /.nv-coming-soon-pane -->
                            </div><!-- /nv-column-content -->
                        </div>
                    </div><!-- /.nv-row -->
                </div><!-- /.nv-port -->
            </section><!-- /.nv-section -->
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->
        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
</div><!-- /.nv-config-layout -->
