import { Component } from '@angular/core';

@Component({
    selector: 'app-setup-wizard-page',
    templateUrl: './setup-wizard.component.html'
})
export class SetupWizardComponent {

    constructor() { }

}
