<div class="nv-template-wrap dark-skin">
    <header class="nv-temp-header nv-section">
        <div class="nv-row space-between">
            <div class="nv-column desk-initial v-middle">
                <div class="nv-column-content">
                    <div class="nv-template-logo">
                        <a href title="Portal name"><img src="../../assets/visa-logo-web.png" alt="Portal name"></a>
                    </div><!-- /.nv-template-logo -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
            <div class="nv-column desk-initial v-middle">
                <div class="nv-column-content">
                    <div class="nv-template-menu-n-profile">
                        <nav class="nv-template-menu">
                            <ul>
                                <li><a href>Item 1</a></li>
                                <li>
                                    <a href>Item 2</a>
                                    <ul>
                                        <li><a href>Submenu Item 1</a></li>
                                        <li><a href>Submenu Item 2</a></li>
                                        <li><a href>Submenu Item 3</a></li>
                                    </ul>
                                </li>
                                <li><a href>Item 3</a></li>
                            </ul>
                        </nav><!-- /.nv-template-menu -->
                        <div class="nv-template-profile">
                            <!--                        <a class="nv-btn small accent2 radius-high" routerlink="/login" routerlinkactive="active" translate="" href="/login">Login / Register</a>-->
                            <div class="nv-btn small accent2 radius-100 icon nv-bar-profile-menu">
                                JS
                                <nav>
                                    <ul>
                                        <li><a href>Sign Out</a></li>
                                        <li><a href>Edit Profile</a></li>
                                    </ul>
                                </nav><!-- /nv-bar-profile-menu -->
                            </div> <!-- LOGGED IN VER. -->
                        </div><!-- /.nv-template-profile -->
                    </div><!-- /.nv-template-menu-n-profile -->
                </div><!-- /.nv-column-content -->
            </div><!-- /.nv-column -->
        </div><!-- /.nv-row -->
    </header><!-- /.nv-temp-header -->
    <div class="nv-template-body">
        <section class="nv-section nv-hero guttered" style="background-image: url(../../assets/config-branding-hero.jpg)">
            <div class="nv-row flow-center giant-spaced">
                <div class="nv-column desk-6 lap-8 tab-10 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-hero-pane">
                            <h1><span>Headline to make big announcement</span></h1>
                            <h3><span>#EventHashteg &middot; 20-21 May '20 &middot; San Francisco, United States</span></h3>
                            <a href class="nv-btn giant radius-high accent2">Lorem ipsum dolor sit amet, consectetur</a>
                        </div><!-- /.nv-hero-pane -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </section><!-- /.nv-section template-hero -->

        <div class="nv-divider desk-large"></div>

        <section class="nv-section nv-hero guttered" style="background-image: url(../../assets/config-branding-hero.jpg)">
            <div class="nv-row flow-center large-spaced">
                <div class="nv-column desk-6 lap-8 tab-10 phab-12">
                    <div class="nv-column-content">
                        <div class="nv-hero-pane">
                            <h1><span>Headline to make big announcement</span></h1>
                            <h3><span>#EventHashteg</span></h3>
                            <h3><span>20-21 May '20</span></h3>
                            <h3><span>San Francisco, United States</span></h3>
                            <a href class="nv-btn giant radius-high accent2">Lorem ipsum dolor sit amet, consectetur</a>
                        </div><!-- /.nv-hero-pane -->
                    </div><!-- /.nv-column-content -->
                </div><!-- /.nv-column -->
            </div><!-- /.nv-row -->
        </section><!-- /.nv-section template-hero -->
    </div><!-- /.nv-template-body -->
</div><!-- /.nv-template-wrap -->


