import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const fullLocation = window.location.host.replace('www.', '');
        const parts = fullLocation.split('.');
        const slug: string = parts[0];

        // console.log(fullLocation);

        const headersConfig = {};

        if (!req.headers['Accept']) {
            headersConfig['Accept'] = 'application/json';
        }
        if (!req.headers['Content-Type']) {
            headersConfig['Content-Type'] = 'application/json';
        }

        if (req.headers['Slug']) {
            headersConfig['Slug'] = req.headers['Slug'];
        } else if (slug) {
            headersConfig['Slug'] = slug;
        }

        if (req.url.includes('e-materials.com')) {
            const token = localStorage.getItem(environment.navus_access_token_name);
            if (token) {
                headersConfig['Authorization'] = `Bearer ${token}`;
            }            
        }

        const request = req.clone({ setHeaders: headersConfig });

        return next.handle(request);
    }
}
