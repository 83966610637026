<div class="nv-modal extra-large">
  <div class="nv-modal-header">
    <h4 class="nv-modal-title">Audit log</h4>
    <button type="button" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="nv-modal-body"
    nvInfiniteScroll
    (scrolled)="getAuditLog(true)"
  >
    <div class="rtz-resp-table-wrap">
      <table class="rtz-table-mp">
          <tr>
              <th>Event Timestamp</th>
              <th>Event</th>
              <th>Old Values</th>
              <th>New Values</th>
              <th>User</th>
              <th>Source</th>
              <th>IP Address</th>
          </tr>
          <tr *ngFor="let entry of auditLog">
              <td>{{ entry.created_at | date:'dd.MM.yyyy. HH:mm' }}</td>
              <td><span title="{{ entry.event | titlecase }}">{{ entry.event | titlecase }}</span></td>
              <td><span title="{{ entry.old_values | json }}">{{ entry.old_values | json }}</span></td>
              <td><span title="{{ entry.new_values | json }}">{{ entry.new_values | json }}</span></td>
              <td><span title="{{ entry.user?.first_name }} {{ entry.user?.last_name }}">{{ entry.user?.first_name }} {{ entry.user?.last_name }}</span></td>
              <td><span title="{{ entry.user_agent }}">{{ entry.user_agent }}</span></td>
              <td><span title="{{ entry.ip_address }}">{{ entry.ip_address }}</span></td>
          </tr>
      </table>
    </div>
  </div>
</div>
