<div class="rtz-config-frame">
    <header class="rtz-cf-header">
        <div class="rtz-cf-logo-port">
            <figure>
                <a href title="Riterz configurator"><img src="../../assets/riterz-logo.svg" alt="Riterz.com"></a>
            </figure>
        </div><!-- /.rtz-cf-logo-port -->
        <div class="rtz-cf-user-settings">
            <div class="rtz-ui-settings-box"><!-- < toggle class on -->
                <div class="rtz-usb-face">
                    <figure><img src="../../assets/avatar-placeholder.png" alt="User Name"></figure>
                </div><!-- /.rtz-usb-face -->
                <nav class="rtz-ui-menu last-child-alt" aria-hidden="true">
                    <ul>
                        <li><a href title="Profile" class="rtz-ico-profile">Profile</a></li>
                        <li><a href title="Privacy" class="rtz-ico-privacy">Privacy</a></li>
                        <li><a href title="Legal" class="rtz-ico-legal">Legal</a></li>
                        <li><a href title="Sign Out" class="rtz-ico-signout">Sign Out</a></li>
                    </ul>
                </nav><!-- /.rtz-ui-menu -->
            </div><!-- /.rtz-ui-settings-box -->
        </div><!-- /.rtz-cf-user-settings -->
    </header><!-- /.rtz-cf-header -->

    <!-- ONBORDING / C3 01a -->
    <div class="rtz-config-body">
        <main class="rtz-config-stage">
            <div class="rtz-config-stage-sleeve">
                <div class="rtz-choose-organization-start">
                    <div class="rtz-choose-organization-card rtz-card">
                        <figure><img src="../../assets/riterz-textless-logo.svg" alt="Riterz"></figure>
                        <h1>Welcome back, Lara!</h1>
                        <p>Which organization are you working on today?</p>
                        <!-- Conditional search field if there are MANY orgs -->
                        <div class="rtz-choose-org-search">
                            <input type="text" name="yeah" placeholder="Search">
                        </div><!-- /.rtz-choose-org-search -->
                        <div class="rtz-org-select-window">
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/ic-organization-placeholder.svg" alt="ORGs Name"></figure>
                                <h3>AB Organisation</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/navus-textless-logo.svg" alt="ORGs Name"></figure>
                                <h3>Louis Vuitton</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/logo-event-esicm.png" alt="ORGs Name"></figure>
                                <h3>AB Organisation</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/logo-event-escp.png" alt="ORGs Name"></figure>
                                <h3>Louis Vuitton</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/ic-organization-placeholder.svg" alt="ORGs Name"></figure>
                                <h3>AB Organisation</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/navus-textless-logo.svg" alt="ORGs Name"></figure>
                                <h3>Louis Vuitton</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/logo-event-esicm.png" alt="ORGs Name"></figure>
                                <h3>AB Organisation</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                            <div class="rtz-org-select-item">
                                <figure><img src="../../assets/logo-event-escp.png" alt="ORGs Name"></figure>
                                <h3>Louis Vuitton</h3>
                                <a href title="ORGs Name"></a>
                            </div><!-- /.rtz-org-select-item -->
                        </div><!-- /.rtz-org-select-window -->
                    </div><!-- /.rtz-choose-organization-card -->
                </div><!-- /.rtz-choose-organization-start -->
            </div><!-- /.rtz-config-stage-sleeve -->
        </main>
    </div><!-- /.rtz-config-body -->
</div><!-- /.rtz-config-frame -->
