import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User, UserService } from '@riterz.io/riterz-core';

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.userService
      .getCurrentUser()
      .pipe(
        map((response: { data: User, meta: any }) => {
          const user = response.data;
          const isRootPath = route.children.length === 0;

          if (isRootPath) {
            // Should redirect somewhere

            if (user.roles.length === 0) { 
              // Redirect to organizations to create an organization
              this.router.navigate(['/u', 'o']);
            } else {
              const isSuperAdmin = !!user.roles.map(r => r.name).find(n => n === 'super-admin');

              this.router.navigate(['/u', 'o']);
              if (isSuperAdmin) {
                // Redirect to admin
                this.router.navigate(['/u', 'a']);
              } else {
                // Redirect to organizations
                this.router.navigate(['/u', 'o']);
              }        
            }
          }

          return user;
        }),
        catchError((error) => {
          console.log(error);
          return this.router.navigate(['auth']);
        })
      );
  }
}
