<div class="nv-config-layout"><!-- toggle class 'sidebar-open' with nv-config-sidebar -->
    <div class="nv-layout-collapse-toggler"></div> <!-- toggle class 'on' -->
    <aside class="nv-config-aside"><!-- < toggle class 'hidden' -->
        <div class="nv-config-vertical-bar-left">
            <div class="nv-config-logo"><img src="../../assets/riterz-textless-logo.svg" alt="Navus CH"></div>
            <div class="nv-vertical-tools">
                <div class="nv-config-side-tools">
                    <div class="cst-item settings">
                        <a></a>
                        <span>Configurator Settings</span>
                    </div><!-- /.cst-item -->
                </div><!-- /.nv-config-side-tools -->
                <div class="nv-vt-profile">
                    <div class="vtp-avatar"><span style="background-image: url('../../assets/avatar-dummy.jpg');"></span></div> <!-- < toggle class 'on' -->
                    <div class="vtp-options-box">
                        <div class="vtp-option-chunk">
                            <h4>YOUR ORGANIZATION</h4>
                            <nav>
                                <ul>
                                    <li><a href="">Settings</a></li>
                                    <li><a href="">Billing & Subscription</a></li>
                                    <li><a href="">Manage Users</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <h4>YOUR PROFILE</h4>
                            <nav>
                                <ul>
                                    <li><a href="">User Profile</a></li>
                                    <li><a href="">Privacy</a></li>
                                    <li><a href="">Feedback</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                        <div class="vtp-option-chunk">
                            <nav>
                                <ul>
                                    <li><a href="">Sign Out</a></li>
                                </ul>
                            </nav>
                        </div><!-- /.vtp-option-chunk -->
                    </div><!-- /.vtp-options-box -->
                </div><!-- /.nv-vt-profile -->
            </div><!-- /.nv-vertical-tools -->
        </div><!-- /.nv-config-vertical-bar-left -->
        <div class="nv-config-menu">
            <div class="cm-instance active">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
            <div class="cm-instance">
                <div class="nv-cm-static">
                    <div class="cm-organization">
                        <div class="cm-current-organization on"> <!-- < toggle class 'on' -->
                            <div class="cm-organization-logo">
                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                            </div><!-- /.cm-organization-logo -->
                            <div class="cm-organization-label">
                                <h3>ENP</h3>
                                <p>Organization</p>
                            </div><!-- /.cm-organization-label -->
                        </div><!-- /.cm-current-organisation -->
                        <div class="cm-organisation-list">
                            <div class="cm-organization-search">
                                <input type="text" name="" placeholder="Search for organizations…">
                            </div><!-- /.cm-organization-search -->
                            <nav>
                                <ul>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                    <li>
                                        <div class="cm-list-organization">
                                            <div class="cm-organization-logo">
                                                <span style="background-image: url('//service.e-materials.com/storage/resources/era_edta/log/logo_enp.jpg');"></span>
                                            </div><!-- /.cm-organization-logo -->
                                            <div class="cm-organization-label">
                                                <h3>ENP</h3>
                                                <p>Organization</p>
                                            </div><!-- /.cm-organization-label -->
                                        </div><!-- /cm-list-organization -->
                                    </li>
                                </ul>
                            </nav>
                            <a href="" class="cm-add">
                                Add organization
                            </a><!-- /.cm-add -->
                        </div><!-- /.cm-organisation-list -->
                    </div><!-- /.cm-organisation -->
                    <div class="cm-bck-link">
                        <a href>Back to events</a>
                    </div><!-- /.cm-bck-link -->
                </div><!-- /.nv-cm-static -->
                <div class="nv-cm-scrollable">
                    <div class="nv-config-navs">
                        <nav>
                            <ul>
                                <li>
                                    <a href>Landing</a>
                                </li>
                                <li>
                                    <a href>Web Portal</a>
                                </li>
                                <li>
                                    <a href>Mobile Applications</a>
                                </li>

                                <li>
                                    <a href class="active">Program</a>
                                </li>
                                <li>
                                    <a href>Speakers</a>
                                </li>
                                <li>
                                    <a href>Locations / Rooms / Tracks</a>
                                </li>
                                <li>
                                    <a href>Sponsors & Exhibitors</a>
                                </li>
                                <li>
                                    <a href>E-materials</a>
                                </li>
                                <li>
                                    <a href>Voting</a>
                                </li>
                                <li>
                                    <a href>Push Notifications</a>
                                </li>
                                <li>
                                    <a href>Ad Platform<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>AI<span class="spec" title="Check it out!"></span></a>
                                </li>
                                <li>
                                    <a href>Dashboard</a>
                                </li>
                            </ul>
                        </nav>
                        <h4>Special features</h4>
                        <nav>
                            <ul>
                                <li>
                                    <a href>Lead Capture</a>
                                </li>
                                <li>
                                    <a href>Consent Application</a>
                                </li>
                                <li>
                                    <a href>E-posters</a>
                                </li>
                            </ul>
                        </nav>
                    </div><!-- /.nv-config-navs -->
                </div><!-- /.nv-cm-scrollable -->
            </div><!-- /.cm-instance -->
        </div><!-- /.nv-config-menu -->
    </aside><!-- /.nv-config-aside -->
    <main class="nv-config-content">
        <div class="nv-config-control-bar">
            <p class="nv-config-bread-crumbs">
                <span><a>SEHH</a></span>
                <span>Events</span>
            </p><!-- /.nv-config-bread-crumbs -->
            <h1>Events</h1>
            <div class="nv-line-tabs guttered">
                <nav class="nv-tabs-nav vertical-break-phab">
                    <ul>
                        <li>
                            <a class="active">Registration</a>
                        </li>
                        <li>
                            <a>Integration</a>
                        </li>
                    </ul>
                </nav><!-- /.nv-tabs-nav -->
            </div><!-- /.nv-line-tabs -->
        </div><!-- /.nv-config-control-bar -->
        <div class="nv-config-content-port">
            <div class="nv-section">
                <div class="nv-port">

                    <div class="nv-row config-plates spaced">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <table class="nv-data-table break-lap">
                                    <tr>
                                        <td class="di-avatar nv-clickable">
                                            <span><img alt="" src="https://service.e-materials.com/storage/resources/demo/xza/xza2mtyymzcuanbn.jpg"></span>
                                        </td>
                                        <td class="dt-cell nv-clickable" data-label="Total Earned">
                                            <label>Total Earned</label>
                                            <strong>2</strong>
                                        </td>
                                        <td class="dt-cell">
                                            <div class="dt-badges">
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                            </div><!-- /.dt-badges -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="di-avatar nv-clickable">
                                            <span><img alt="" src="https://service.e-materials.com/storage/resources/demo/xza/xza2mtyymzcuanbn.jpg"></span>
                                        </td>
                                        <td class="dt-cell nv-clickable" data-label="Total Earned">
                                            <label>Total Earned</label>
                                            <strong>2</strong>
                                        </td>
                                        <td class="dt-cell">
                                            <div class="dt-badges"> <!-- < reversed order of items, first item is far right -->
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-silver.svg" alt="Badge Silver"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-gold.svg" alt="Badge Gold"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-silver.svg" alt="Badge Silver"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-gold.svg" alt="Badge Gold"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                                <div class="dt-badge">
                                                    <figure><img src="../../assets/badge-attendance-bronze.svg" alt="Badge Bronze"></figure>
                                                    <p>
                                                        Live sessions attended (Level 1)
                                                    </p>
                                                </div>
                                            </div><!-- /.dt-badges -->
                                        </td>
                                    </tr>
                                </table>
                            </div><!-- /.nv-column-content -->
                        </div><!-- /nv-column -->
                    </div><!-- /.nv-row -->


                    <div class="nv-row config-plates spaced-bottom">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <ul class="nv-materials-list break-lap break-column-tab"><!-- < custom, two levels breakpoints -->
                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <div class="ml-content">
                                            <div class="ml-file">
                                                <div class="ml-iconavatar">
<!--                                                    <a href title="file name"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>-->
<!--                                                    <a href title="file name"><img src="../../assets/ico-material-ppt.svg" alt="PPT file"></a>-->
<!--                                                    <a href title="file name"><img src="../../assets/ico-material-mp-4.svg" alt="MP4 file"></a>-->
<!--                                                    <a href title="file name"><img src="../../assets/ico-material-file.svg" alt="file"></a>-->
                                                    <a href title="file name"><img src="../../assets/ico-material-pdf.svg" alt="PDF file"></a>
                                                </div><!-- /.ml-iconavatar -->
                                                <div class="ml-name">
                                                    <label>MATERIAL NAME</label>
                                                    <strong>File Name</strong>
                                                </div><!-- /.ml-name -->
                                                <div class="ml-filesize">
                                                    <label>SIZE</label>
                                                    <strong>1.8 MB</strong>
                                                </div><!-- /.ml-filesize -->
                                            </div><!-- /.ml-file -->
                                            <div class="ml-meta">
                                                <div class="ml-presentation">
                                                    <label>ATTACHED TO PRESENTATION</label>
                                                    <strong>None</strong>
                                                </div><!-- /.ml-presentation -->
                                                <div class="ml-published">
                                                    <label>PUBLISHED</label>
                                                    <strong>No</strong>
                                                </div><!-- /.ml-published -->
                                                <div class="ml-status">
                                                    <label>STATUS</label>
                                                    <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                                </div><!-- /.ml-status -->
                                            </div><!-- /.ml-meta -->
                                        </div><!-- /.ml-content -->
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav>
                                                <ul>
                                                    <li><a>Option 1</a></li>
                                                    <li><a>Option 2</a></li>
                                                    <li class="delete"><a>Option 3</a></li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                    </li>

                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <div class="ml-content">
                                            <div class="ml-file">
                                                <div class="ml-iconavatar">
                                                    <a href title="file name"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                                </div><!-- /.ml-iconavatar -->
                                                <div class="ml-name">
                                                    <label>MATERIAL NAME</label>
                                                    <strong>File Name</strong>
                                                </div><!-- /.ml-name -->
                                                <div class="ml-filesize">
                                                    <label>SIZE</label>
                                                    <strong>1.8 MB</strong>
                                                </div><!-- /.ml-filesize -->
                                            </div><!-- /.ml-file -->
                                            <div class="ml-meta">
                                                <div class="ml-presentation">
                                                    <label>ATTACHED TO PRESENTATION</label>
                                                    <strong>None</strong>
                                                </div><!-- /.ml-presentation -->
                                                <div class="ml-published">
                                                    <label>PUBLISHED</label>
                                                    <strong>No</strong>
                                                </div><!-- /.ml-published -->
                                                <div class="ml-status">
                                                    <label>STATUS</label>
                                                    <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                                </div><!-- /.ml-status -->
                                            </div><!-- /.ml-meta -->
                                        </div><!-- /.ml-content -->
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav>
                                                <ul>
                                                    <li><a>Option 1</a></li>
                                                    <li><a>Option 2</a></li>
                                                    <li class="delete"><a>Option 3</a></li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                    </li>

                                    <li>
                                        <div class="nv-checkbox-item">
                                            <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                            </label>
                                        </div><!-- /nv-checkbox-item -->
                                        <div class="ml-content">
                                            <div class="ml-file">
                                                <div class="ml-iconavatar">
                                                    <a href title="file name"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                                </div><!-- /.ml-iconavatar -->
                                                <div class="ml-name">
                                                    <label>MATERIAL NAME</label>
                                                    <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>
                                                </div><!-- /.ml-name -->
                                                <div class="ml-filesize">
                                                    <label>SIZE</label>
                                                    <strong>1.8 MB</strong>
                                                </div><!-- /.ml-filesize -->
                                            </div><!-- /.ml-file -->
                                            <div class="ml-meta">
                                                <div class="ml-presentation">
                                                    <label>ATTACHED TO PRESENTATION</label>
                                                    <strong>None</strong>
                                                </div><!-- /.ml-presentation -->
                                                <div class="ml-published">
                                                    <label>PUBLISHED</label>
                                                    <strong>No</strong>
                                                </div><!-- /.ml-published -->
                                                <div class="ml-status">
                                                    <label>STATUS</label>
                                                    <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                                </div><!-- /.ml-status -->
                                            </div><!-- /.ml-meta -->
                                        </div><!-- /.ml-content -->
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav>
                                                <ul>
                                                    <li><a>Option 1</a></li>
                                                    <li><a>Option 2</a></li>
                                                    <li class="delete"><a>Option 3</a></li>
                                                </ul>
                                            </nav>
                                        </div><!-- /nv-cell-actions -->
                                    </li>
                                </ul>
                            </div><!-- /nv-column-content -->
                        </div>
                    </div><!-- /nv-row -->
                    <div class="nv-row config-plates spaced">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <h2>New data item</h2>

                                <!-- New data item -->
                                <div class="nv-data-item" style="display: none">
                                    <div class="nv-checkbox-item">
                                        <label>
                                                <span class="nci-check-port">
                                                    <input type="checkbox">
                                                    <span class="nci-check-visual"></span>
                                                </span><!-- /.nci-check-port -->
                                        </label>
                                    </div><!-- /nv-checkbox-item -->
                                    <div class="nv-data-chunk">
                                        <div class="di-iconavatar">
                                            <a href title="file name"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                        </div><!-- /.di-iconavatar -->
                                    </div><!-- /.nv-data-chunk -->
                                    <div class="nv-data-chunk wide">
                                        <label>MATERIAL NAME</label>
                                        <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>
                                    </div><!-- /.nv-data-chunk wide -->
                                    <div class="nv-data-chunk">
                                        <label>SIZE</label>
                                        <strong>1.8 MB</strong>
                                    </div><!-- /.nv-data-chunk -->
                                    <div class="nv-data-chunk wide">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong>None</strong>
                                    </div><!-- /.nv-data-chunk wide -->
                                    <div class="nv-data-chunk">
                                        <label>PUBLISHED</label>
                                        <strong>No</strong>
                                    </div><!-- /.nv-data-chunk -->
                                    <div class="nv-data-chunk">
                                        <label>STATUS</label>
                                        <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                    </div><!-- /.nv-data-chunk -->
                                    <div class="nv-cell-actions">
                                        <span class="nv-cell-action-trigger"></span>
                                    </div><!-- /.nv-cell-actions -->
                                </div><!-- /.nv-data-item -->
                            </div><!-- /nv-column-content -->
                        </div>
                    </div><!-- /nv-row -->
                    <div class="nv-row config-plates spaced">
                        <div class="nv-column desk-12">
                            <div class="nv-column-content">
                                <h2>New data item in table</h2>
                                <table class="nv-data-table break-lap">
                                    <tr>
                                        <td class="di-checkbox">
                                            <div class="nv-checkbox-item">
                                                <label>
                                                    <span class="nci-check-port">
                                                        <input type="checkbox">
                                                        <span class="nci-check-visual"></span>
                                                    </span><!-- /.nci-check-port -->
                                                </label>
                                            </div><!-- /nv-checkbox-item -->
                                        </td>
                                        <td class="di-icon">
                                            <a href title="file name"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                        </td>
                                        <td class="di-avatar">
                                             <a href title="Author Name"><span><img src="//service.e-materials.com/storage/resources/navus/per/persona21.jpg" alt="Author Name"></span></a><!-- < no IMG tag for initial state -->
                                        </td>
                                        <td class="dt-cell">
                                            <div class="dt-name-w-file">
                                                <a href title="file name" class="dt-file"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                                <div class="dt-name">
                                                    <label>MATERIAL NAME</label>
                                                    <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>
                                                </div>
                                            </div><!-- /.dt-name-w-file -->
                                        </td><!-- /.dt-cell -->
                                        <td class="dt-cell">
                                            <label>SIZE</label>
                                            <strong>1.8&nbsp;MB</strong>
                                        </td><!-- /.dt-cell -->
                                        <td class="dt-cell">
                                            <label>ATTACHED TO PRESENTATION</label>
                                            <strong><a class="nv-external-link" href title="Presentation name">How we learned from two decades amet, consectetur adipisicing.</a></strong>
                                        </td><!-- /.dt-cell -->
                                        <td class="dt-cell">
                                            <label>PUBLISHED</label>
                                            <strong>No</strong>
                                        </td><!-- /.dt-cell -->
                                        <td class="dt-cell">
                                            <label>STATUS</label>
                                            <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                        </td><!-- /.dt-cell -->
                                        <td class="dt-actions">
                                            <div class="nv-cell-actions">
                                                <span class="nv-cell-action-trigger"></span>
                                            </div><!-- /.nv-cell-actions -->
                                        </td>
                                    </tr>
                                </table>
                            </div><!-- /nv-column-content -->
                        </div><!-- /nv-column -->
                    </div>
                </div><!-- /nv-row -->
                <div class="nv-row config-plates spaced">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">

                            <h2>New cells</h2>
                            <!-- element update -->
                            <table class="nv-data-table break-lap">
                                <tr>
                                    <td class="dt-cell">
                                        <div class="dt-name-w-file">
                                            <a href title="file name" class="dt-file"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                            <div class="dt-name">
                                                <label>MATERIAL NAME</label>
                                                <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>
                                            </div>
                                        </div><!-- /.dt-name-w-file -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>SIZE</label>
                                        <strong>1.8&nbsp;MB</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong><a class="nv-external-link" href title="Presentation name">How we learned from two decades amet, consectetur adipisicing.</a></strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>PUBLISHED</label>
                                        <strong>No</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>STATUS</label>
                                        <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                        </div><!-- /.nv-cell-actions -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="dt-cell">
                                        <div class="dt-name-w-file">
                                            <a href title="file name" class="dt-file"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                            <div class="dt-name">
                                                <label>MATERIAL NAME</label>
                                                <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>
                                            </div>
                                        </div><!-- /.dt-name-w-file -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>SIZE</label>
                                        <strong>1.8&nbsp;MB</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong><a class="nv-external-link" href title="Presentation name">How we learned from two decades amet, consectetur adipisicing.</a></strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>PUBLISHED</label>
                                        <strong>No</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>STATUS</label>
                                        <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                        </div><!-- /.nv-cell-actions -->
                                    </td>
                                </tr>
                                <tr>
                                    <td class="dt-cell">
                                        <div class="dt-name-w-file">
                                            <a href title="file name" class="dt-file"><img src="../../assets/ico-material-audio.svg" alt="audio file"></a>
                                            <div class="dt-name">
                                                <label>MATERIAL NAME</label>
                                                <strong>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum iusto quibusdam tempora unde, velit voluptatem.</strong>
                                            </div>
                                        </div><!-- /.dt-name-w-file -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>SIZE</label>
                                        <strong>1.8&nbsp;MB</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>ATTACHED TO PRESENTATION</label>
                                        <strong><a class="nv-external-link" href title="Presentation name">How we learned from two decades amet, consectetur adipisicing.</a></strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>PUBLISHED</label>
                                        <strong>No</strong>
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-cell">
                                        <label>STATUS</label>
                                        <strong class="status unprocessed">unprocessed</strong><!-- /processed -->
                                    </td><!-- /.dt-cell -->
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                        </div><!-- /.nv-cell-actions -->
                                    </td>
                                </tr>
                            </table>
                        </div><!-- /nv-column-content -->
                    </div>
                </div><!-- /nv-row -->

                <div class="nv-row config-plates spaced">
                    <div class="nv-column desk-12">
                        <div class="nv-column-content">
                            <table class="nv-data-table break-lap">
                                <tr>
                                    <td class="di-avatar nv-clickable">
                                        <span><img alt="" src="https://service.e-materials.com/storage/resources/demo/xza/xza2mtyymzcuanbn.jpg"></span>
                                    </td>
                                    <td class="dt-cell nv-clickable">
                                        <label>NAME</label>
                                        <strong>dr Modesto Haley</strong>
                                    </td>
                                    <td class="dt-cell nv-clickable">
                                        <label>INSTITUTION</label>
                                        <strong>Lang, Kuhn and Erdman</strong>
                                    </td>
                                    <td class="dt-cell nv-clickable">
                                        <label>EMAIL</label>
                                        <strong>ochristiansen@example.com</strong>
                                    </td>
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav nvoffclick=""><ul><li><a>Edit</a></li><li class="delete"><a>Delete</a></li></ul></nav>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="di-cat nv-clickable">
                                        <span style="background-color: red"></span>
                                    </td>
                                    <td class="dt-cell nv-clickable">
                                        <label>NAME</label>
                                        <strong>dr Modesto Haley</strong>
                                    </td>
                                    <td class="dt-cell nv-clickable">
                                        <label>INSTITUTION</label>
                                        <strong>Lang, Kuhn and Erdman</strong>
                                    </td>
                                    <td class="dt-cell nv-clickable">
                                        <label>EMAIL</label>
                                        <strong>ochristiansen@example.com</strong>
                                    </td>
                                    <td class="dt-actions">
                                        <div class="nv-cell-actions">
                                            <span class="nv-cell-action-trigger"></span>
                                            <nav nvoffclick=""><ul><li><a>Edit</a></li><li class="delete"><a>Delete</a></li></ul></nav>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div><!-- /.nv-column-content -->
                    </div><!-- /nv-column -->
                </div><!-- /.nv-row -->
            </div><!-- /.nv-section -->
        </div><!-- /.nv-config-content-port -->
    </main><!-- /.nv-config-content -->
    <aside class="nv-config-vertical-bar-right"><!-- < toggle class 'hidden' -->

        <div class="nv-config-side-tools">
            <div class="cst-item help">
                <a></a>
                <span>Help & Learning</span>
            </div><!-- /.cst-item -->
            <div class="cst-item keycuts">
                <a></a>
                <span>Keyboard Shortcuts</span>
            </div><!-- /.cst-item -->
            <div class="cst-item addlink">
                <a></a>
                <span>Add Quick Link</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->

        <div class="nv-config-side-tools">
            <div class="cst-item hide-panel">
                <a></a>
                <span>Hide Side Panel</span>
            </div><!-- /.cst-item -->
        </div><!-- /.nv-config-side-tools -->
    </aside><!-- /.nv-config-vertical-bar-right -->
    <!-- SIDEBAR -->
    <div class="nv-config-sidebar"><!-- toggle class 'on' -->
        <div class="nv-learning-pane">
            <header>
                <div class="lp-close"><a href title="Close bar"></a></div>
                <h2>Help &amp; Learning</h2>
                <h5><a href title="Help Center">Go to Help Center</a></h5>
                <div class="sbwf-port">
                    <input formcontrolname="search_term" placeholder="Search..." type="text">
                    <span class="sbwf-outline"></span>
                </div><!-- /sbwf-port -->
                <nav class="nv-small-tabs-controls">
                    <ul>
                        <li><a href>Home</a></li>
                        <li><a href class="active">Tutorials</a></li>
                    </ul>
                </nav><!-- /.nv-small-tabs-controls -->
            </header>
            <div class="lp-body">
                <div class="lp-body-content">
                    <h3>Watch Tutorial Videos</h3>
                    <div class="nv-tutorial-videos">
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Adding Speakers</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Setting up an Event</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Starting a Promotion</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Adding Delegates</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Event App Tutorial</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Uploading the Program</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Event Metrics Tutorial</p>
                        </div><!-- /.tv-item -->
                        <div class="tv-item">
                            <span>
                                <a href title="video title"></a>
                                <img src="//service.e-materials.com/storage/resources/imedex/add/adding-speakers.png" alt="video title">
                            </span>
                            <p>Live Voting Tutorial</p>
                        </div><!-- /.tv-item -->
                    </div><!-- /.nv-tutorial-videos -->

                    <nav class="nv-help-list">
                        <ul>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        Getting started
                                        <span>Explore key features and possibilities</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        Watch tutorial videos
                                        <span>Learn how to organize event.</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        App & Web templates
                                        <span>Discover more about templates.</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        What’s new
                                        <span>Find out latest product changes</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        FAQ
                                        <span>Browse through the popular topics.</span>
                                    </a>
                                </h4>
                            </li>
                            <li>
                                <figure><a title="tuttorial name"><img src="//service.e-materials.com/storage/resources/imedex/bit/bitmap@3x.png" alt="tutorial name"></a></figure>
                                <h4>
                                    <a>
                                        Support
                                        <span>Share your feedback and ask questions.</span>
                                    </a>
                                </h4>
                            </li>
                        </ul>
                    </nav><!-- /.nv-help-list -->
                </div><!-- /.lp-body-content -->
            </div><!-- /.lp-body -->
            <footer>
                <a href class="nv-btn filled primary medium">Contact Us</a>
            </footer>
        </div><!-- /.nv-learning-pane -->
    </div><!-- /.nv-config-sidebar -->
</div><!-- /.nv-config-layout -->
